import { useDispatch } from "react-redux";
import styled from "styled-components";
import { showProduct } from "../../../shop/actions";
import useSearch from "./useSearch";
import {
  Icon,
  IconButton,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from "@material-ui/core";
import { placeholderImg } from "../../../../components/ImageWithPlaceholder/ImageWithPlaceholder";
import SearchInputMobile, { MIN_SEARCH_LENGTH } from "./SearchInputMobile";
import { FiltersMobile } from "../../../filters";
import SearchMessage from "./SearchMessage";
import ShopLoader from "../../../shop/components/Shop/ShopLoader";

const StyledListItem = styled(ListItem)`
  && {
    border-bottom: 2px solid #e7e7e7;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 1px;
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
`;

const PAGE_SIZE = 999999999;

const SearchMobile = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("query") || "";
  const tags = searchParams.getAll("tags");
  const page = searchParams.get("page");
  const queryPage = Number(page);
  const dispatch = useDispatch();
  const from = (queryPage - 1) * PAGE_SIZE;
  const to = (queryPage - 1) * PAGE_SIZE + PAGE_SIZE;
  const { products, queryProducts, filteredProducts, loading } = useSearch(
    query,
    from,
    to,
    tags,
    "tags"
  );

  let productsList = products.map((product, index) => (
    <StyledListItem
      onClick={() => dispatch(showProduct("/product/" + product.groupCode))}
      key={index}
    >
      <ListItemAvatar>
        {product.image ? (
          <Avatar
            style={{ borderRadius: "0" }}
            imgProps={{ style: { objectFit: "scale-down" } }}
            src={product.image}
          >
            <Avatar src={placeholderImg} />
          </Avatar>
        ) : (
          <Avatar src={placeholderImg} />
        )}
      </ListItemAvatar>
      <ListItemText primary={product.name} secondary={product.lead} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          onClick={() => dispatch(showProduct("/product/" + product.groupCode))}
        >
          <Icon>chevron_right</Icon>
        </IconButton>
      </ListItemSecondaryAction>
    </StyledListItem>
  ));

  return (
    <div>
      <HeaderContainer>
        <SearchInputMobile />
        <FiltersMobile
          products={queryProducts}
          filterPer={"tags"}
          filteredProducts={query ? filteredProducts : []}
        />
      </HeaderContainer>
      {loading && <ShopLoader />}
      {!loading && query && query.length < MIN_SEARCH_LENGTH ? (
        <SearchMessage
          id="eo.search-too-short-query"
          defaultMessage="Search query should contain at least {minSearchLength} characters."
          values={{ minSearchLength: MIN_SEARCH_LENGTH }}
        />
      ) : !loading && query && productsList.length === 0 ? (
        <SearchMessage id="eo.search-no-results" defaultMessage="No results" />
      ) : (
        !loading && <List>{productsList}</List>
      )}
    </div>
  );
};

export default SearchMobile;
