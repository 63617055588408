import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { MenuItem, Select } from "@material-ui/core";
import styled from "styled-components";

const Title = styled.h4`
  text-transform: capitalize;
  padding: 10px 60px 10px 10px;
  margin: 0;
  font-size: 13px;
  font-weight: 700;
  white-space: nowrap;
`;

const Container = styled.div`
  width: 100%;
  text-align: left;
  padding: 0 0 5px;

  span {
    padding: 0 16px;
  }
`;

const StyledSelect = styled(Select)`
  && {
    font-size: 13px;
    position: relative;

    img {
      vertical-align: middle;
    }
  }
`;
const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: 13px;
    vertical-align: middle;

    img {
      vertical-align: middle;
    }
  }
`;

const OpenChangeLanguage = ({
  countries,
  country,
  selected,
  changeLanguageCallback,
}) => {
  const availableLanguages = () => {
    let found = false;

    countries.forEach(function (item) {
      if (item.landCode === country.toUpperCase()) {
        found = item.availableLanguages;
        return false;
      }
    });

    return found || ["EN"];
  };

  const renderOptions = (languages) => {
    const items = languages.map((value, index) => {
      return (
        <StyledMenuItem key={index} value={value.value}>
          <span>{value.label}</span>
        </StyledMenuItem>
      );
    });

    return (
      <div>
        <Title>
          <FormattedMessage
            id="eo.change-language"
            defaultMessage="Change language"
          />
        </Title>
        <Container>
          <StyledSelect
            fullWidth={true}
            value={selected ? selected.toUpperCase() : "EN"}
            onChange={(event) => {
              changeLanguageCallback(country, event.target.value);
            }}
          >
            {items}
          </StyledSelect>
        </Container>
      </div>
    );
  };

  const renderOne = (languages) => {
    const language = languages[0];

    return (
      <div>
        <Title>
          <FormattedMessage id="eo.language" defaultMessage="Language" />:{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: language.hasOwnProperty("label")
                ? language.label
                : language,
            }}
          />
        </Title>
      </div>
    );
  };

  const languages = availableLanguages();

  return languages.length === 1
    ? renderOne(languages)
    : renderOptions(languages);
};

OpenChangeLanguage.propTypes = {
  countries: PropTypes.array.isRequired,
  country: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  changeLanguageCallback: PropTypes.func.isRequired,
};

export default OpenChangeLanguage;
