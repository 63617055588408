import { NAME } from "./constants";
import { find } from "lodash";

export const getCartContent = (state) => state[NAME].items;

export const getCartItem = (state, code) => {
  return find(state[NAME].items, { code: code });
};

export const getErrors = (state) => {
  return state[NAME].errors;
};

export const showCartLoader = (state) => {
  return state[NAME].cartLoader > 0 && state[NAME].items.length > 0;
};

export const getCartState = (state) => {
  return state[NAME].changeCartRequest;
};

export const getCartPopoverStatus = (state) => {
  return state[NAME].cartPopoverIsOpen;
};

export const isFirstTimeOrdered = (state) => {
  return state[NAME].firstTimeOrdered;
};
