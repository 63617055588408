import HelpLayout from "./HelpLayout";
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import ForgotPasswordForm from "./ForgotPasswordForm";
import styled from "styled-components";

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

const HelpForgotPassword = () => {
  const dispatch = useDispatch();
  const [requestSent, setRequestSent] = useState(null);

  const { isSignOnError } = useSelector((state) => ({
    isSignOnError: state.contact.message,
  }));

  const handleSubmit = () => {
    setRequestSent(true);
  };

  const handleError = () => {
    setRequestSent(false);
  };

  const handleBtnClick = () => {
    dispatch(push("/"));
  };

  return (
    <HelpLayout>
      <Title>
        <FormattedMessage
          id="eo.forgot-password-title"
          defaultMessage="Forgot password?"
        />
      </Title>
      {requestSent === null && (
        <ForgotPasswordForm onSuccess={handleSubmit} onError={handleError} />
      )}
      {requestSent && (
        <div>
          <h3>
            <FormattedMessage id="eo.next-step" defaultMessage="Next step" />
          </h3>
          <p>
            <FormattedMessage
              id="eo.forgot-password-info"
              defaultMessage="If there is an account associated with your email address, you will receive a reset link in your mailbox"
            />
          </p>
          <Button color="primary" variant="contained" onClick={handleBtnClick}>
            <FormattedMessage id="eo.forgot-password-ok" defaultMessage="OK" />
          </Button>
        </div>
      )}
      {requestSent === false && (
        <div>
          <h3>
            <FormattedMessage id="eo.sorry" defaultMessage="We're sorry" />
          </h3>
          <p>
            <FormattedMessage
              id={isSignOnError}
              defaultMessage="There was a technical problem."
            />
          </p>
          <Button color="primary" variant="contained" onClick={handleBtnClick}>
            <FormattedMessage id="eo.log-in" defaultMessage="Log in" />
          </Button>
        </div>
      )}
    </HelpLayout>
  );
};

export default HelpForgotPassword;
