import React from "react";
import PropTypes from "prop-types";
import { IconButton, Collapse } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

import {
  Container,
  StyledCard,
  StyledCardHeader,
  StyledCardContent,
} from "./ExpandableCard.styles";

const ExpandableCard = ({ title, body, handleExpandChange, cardExpanded }) => (
  <Container>
    {handleExpandChange && cardExpanded !== undefined ? (
      <StyledCard>
        <StyledCardHeader
          title={title}
          onClick={handleExpandChange}
          action={
            <IconButton onClick={handleExpandChange}>
              {cardExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
        />
        {body && (
          <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
            <StyledCardContent>{body}</StyledCardContent>
          </Collapse>
        )}
      </StyledCard>
    ) : (
      <StyledCard>
        <StyledCardHeader title={title} />
        {body && <StyledCardContent>{body}</StyledCardContent>}
      </StyledCard>
    )}
  </Container>
);

ExpandableCard.propTypes = {
  title: PropTypes.object.isRequired,
  body: PropTypes.object,
  handleExpandChange: PropTypes.func,
  cardExpanded: PropTypes.bool,
};

export default ExpandableCard;
