import React from "react";
import PropTypes from "prop-types";

import { ImageWithPlaceholder } from "../../../../components/ImageWithPlaceholder/ImageWithPlaceholder";

import {
  ShopList,
  ShopItem,
  Photo,
  Details,
} from "./RecommendationsDesktop.styles";

const RecommendationsDesktop = ({ products, detailsCallback, fullWidth }) => {
  return (
    <ShopList>
      {products.map((product, index) => (
        <ShopItem
          key={index}
          onClick={() => detailsCallback("/product/" + product.groupCode)}
          isFullWidth={fullWidth}
        >
          <Photo>
            <ImageWithPlaceholder
              src={product.image?.replace("tiny", "small")}
              alt={product.name}
            />
          </Photo>
          <Details>
            <h2>{product.name}</h2>
            <p>{product.lead}</p>
          </Details>
        </ShopItem>
      ))}
    </ShopList>
  );
};

RecommendationsDesktop.propTypes = {
  products: PropTypes.array.isRequired,
  detailsCallback: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

export default RecommendationsDesktop;
