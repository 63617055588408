import * as t from "./actionTypes";
import axios from "axios";
import config from "../../config";
import Auth from "../core/Auth";

const headers = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const handleSubmit = (values) => {
  const formData = new FormData();

  formData.append("login", values.login);
  formData.append("password", values.password);

  return axios
    .post(`${config.apiUrl}eo/login/token`, formData, headers)
    .then((response) => {
      Auth.logIn(response.data.token);
      return {
        type: t.AUTH_SUCCESS,
        data: response.data.token,
      };
    });
};

export const fetchCountriesRequest = () => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getUnloggedToken(),
    },
  };

  return (dispatch) => {
    dispatch({ type: t.FETCH_COUNTRIES_REQUEST });

    axios
      .get(`${config.apiUrl}eo/open/country`, token)
      .then((response) =>
        dispatch({
          type: t.FETCH_COUNTRIES_SUCCESS,
          data: response.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: t.FETCH_COUNTRIES_FAILURE,
          error: error.response.statusText,
        })
      );
  };
};

export const fetchViewerCountryCode = () => {
  const { hostname } = window.location;
  const host =
    hostname !== "localhost" ? hostname : "easyorder.dev.interflon.cloud";
  return axios.get(`https://${host}/geodata`);
};

export const validateToken = (hash) => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getUnloggedToken(),
    },
  };

  return axios.get(
    `${config.apiUrl}eo/open/user/activation/token/${hash}`,
    token
  );
};

export const handleNewPasswordSubmit = (values) => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getUnloggedToken(),
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("password", values.password);
  formData.append("hash", values.hash);

  return axios.post(
    `${config.apiUrl}eo/open/user/newpassword`,
    formData,
    token
  );
};

export const handleForgotPasswordSubmit = (values) => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getUnloggedToken(),
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("email", values.email);

  return axios.post(`${config.apiUrl}eo/open/login/reset`, formData, token);
};

export const handleMoreInfoSubmit = (values) => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getUnloggedToken(),
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("email", values.email);
  formData.append("firstName", values.firstName);
  formData.append("lastName", values.lastName);
  formData.append("country", values.country);
  formData.append("phone", values.phone);
  formData.append("company", values.company);
  formData.append("city", values.city);
  formData.append("function", values.function);
  formData.append("department", values.department);
  if (values.vatNumber) formData.append("vatNumber", values.vatNumber);

  return axios.post(
    `${config.apiUrl}eo/open/contact/more-info`,
    formData,
    token
  );
};
