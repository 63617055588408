import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { withTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Captcha from "./Captcha";
import PrivacyStatement from "../PrivacyStatement/PrivacyStatement";

const StyledForm = styled.form`
  padding: 56px;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.h2`
  margin-bottom: 0;
  padding-bottom: 0;
  font-weight: bold;
  color: #333;
  font-size: 16px;
  text-transform: uppercase;
`;

const SubTitle = styled.div`
  margin-bottom: 32px;
  padding-bottom: 0;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
`;

const FormError = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.error.main};
`);

const ButtonWrapper = styled.div`
  text-align: center;
`;

const FormNew = ({
  title,
  subTitle,
  btnLabel,
  btnVariant,
  onSubmitForm,
  children,
  defaultValues,
  schema,
  isCaptcha,
  isPrivacy,
  watchValue,
  watchCallback,
}) => {
  const [captchaValid, setCaptchaValid] = useState(!isCaptcha);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (data) => {
    if (captchaValid) {
      try {
        await onSubmitForm(data);
      } catch (error) {
        const formError = {
          type: "server",
          message: error.response.data.message,
        };
        setError("form", formError);
      }
    }
  };

  const watchedValue = watch(watchValue);
  React.useEffect(() => {
    if (watchCallback) {
      watchCallback(watchedValue);
    }
  }, [watchedValue]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      {title && <Title>{title}</Title>}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      {React.Children.map(children, (child) => {
        return child?.props?.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: register,
                control: control,
                key: child.props.name,
                errors: errors[child.props.name],
              },
            })
          : child;
      })}
      {isCaptcha && (
        <Captcha onChange={setCaptchaValid} isSubmitting={isSubmitting} />
      )}
      {isPrivacy && <PrivacyStatement />}
      {errors?.form && <FormError>{errors?.form.message}</FormError>}
      <ButtonWrapper>
        <Button
          color="primary"
          variant={btnVariant ? btnVariant : "contained"}
          disabled={isSubmitting}
          type="submit"
          startIcon={
            isSubmitting && <CircularProgress color="inherit" size={12} />
          }
        >
          {btnLabel ? (
            btnLabel
          ) : (
            <FormattedMessage id="eo.enter" defaultMessage="Enter" />
          )}
        </Button>
      </ButtonWrapper>
    </StyledForm>
  );
};

export default FormNew;
