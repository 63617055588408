import React from 'react';
import uuid from 'uuid';

const idGradient = uuid.v1();
const idClip = uuid.v1();

const ProductDetailsLoaderDesktop = () => (
    <svg viewBox="0 0 1130 350" style={{margin: '0',height:'280px'}}>
        <rect style={{fill: `url(#${idGradient})`}} clipPath={`url("#${idClip}")`} x="0" y="0" width="1130" height="350"/>

        <defs>
            <clipPath id={idClip}>
                <rect x="5" y="5" rx="5" ry="5" width="330" height="35" />
                <rect x="5" y="50" rx="5" ry="5" width="180" height="10" />
                <rect x="5" y="80" rx="5" ry="5" width="269" height="269" />

                <rect x="300" y="80" rx="5" ry="5" width="150" height="10" />

                <rect x="300" y="100" rx="5" ry="5" width="295" height="10" />
                <rect x="300" y="120" rx="5" ry="5" width="200" height="10" />
                <rect x="300" y="145" rx="5" ry="5" width="280" height="10" />
            </clipPath>

            <linearGradient id={idGradient}>
                <stop offset="0%" stopColor="#f0f0f0">
                    <animate attributeName="offset" values="-2; 1" dur="2s" repeatCount="indefinite"/>
                </stop>
                <stop offset="50%" stopColor="#e0e0e0">
                    <animate attributeName="offset" values="-1.5; 1.5" dur="2s" repeatCount="indefinite"/>
                </stop>
                <stop offset="100%" stopColor="#f0f0f0">
                    <animate attributeName="offset" values="-1; 2" dur="2s" repeatCount="indefinite"/>
                </stop>
            </linearGradient>
        </defs>
    </svg>
);

export default ProductDetailsLoaderDesktop;