import { withTheme } from "@material-ui/core/styles";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = withTheme(createGlobalStyle`
  html {
    position: relative;
    min-height: 100%;
  }
  
  body {
    margin: 0;
    font-family: 'Lato', sans-serif;
  
  }
  
  .clearfix {
    clear: both;
    display: block;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .divider {
    margin: 10px 0;
  }
  
  .intRed-icon {
    color: ${({ theme }) => theme.palette.primary.main};
    fill: ${({ theme }) => theme.palette.primary.main};
  }
  
  .MuiButtonBase-root.dialog-CloseButton {
    position: absolute;
    top: 4px;
    right: 4px;
  }
  
  .order-card {
    border-radius: 4px;
    border: 2px solid #d1d1d1;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  }
  
  .reorder-button {
    float: right;
    margin-top: 10px;
    background-color: $interflonRedColor;
    overflow: hidden;
    border: 2px solid $interflonRedColor;
    border-radius: 4px;
    font-weight: 800;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
  }
  
  /* Hide number input arrows  */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`);

export default GlobalStyle;
