import React from 'react';
import uuid from 'uuid';

const idGradient = uuid.v1();
const idClip = uuid.v1();

const CartLoader = () => (
    <svg viewBox="0 0 310 360" style={{margin: '0 9px'}}>
        <rect style={{fill: `url(#${idGradient})`}} clipPath={`url("#${idClip}")`} x="0" y="0" width="310" height="360"/>

        <defs>
            <clipPath id={idClip}>
                <rect x="5" y="10" rx="5" ry="5" width="300" height="48" />
                <rect x="5" y="60" rx="5" ry="5" width="300" height="88" />
            </clipPath>

            <linearGradient id={idGradient}>
                <stop offset="0%" stopColor="#f0f0f0">
                    <animate attributeName="offset" values="-2; 1" dur="2s" repeatCount="indefinite"/>
                </stop>
                <stop offset="50%" stopColor="#e0e0e0">
                    <animate attributeName="offset" values="-1.5; 1.5" dur="2s" repeatCount="indefinite"/>
                </stop>
                <stop offset="100%" stopColor="#f0f0f0">
                    <animate attributeName="offset" values="-1; 2" dur="2s" repeatCount="indefinite"/>
                </stop>
            </linearGradient>
        </defs>
    </svg>
);

export default CartLoader;