import styled from "styled-components";
import Icon from "@material-ui/core/Icon";
import Title from "../Title";
import Button from "@material-ui/core/Button";

export const UserBar = styled.div`
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  min-width: 100px;
  justify-content: flex-end;

  @media screen and (min-width: 1170px) {
    min-width: 260px;
  }

  h1 {
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 0;
  }

  h2 {
    color: #999;
    font-size: 11px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const UserIcon = styled(Icon)`
  && {
    margin-right: 0;
    color: #999;

    @media screen and (min-width: 1170px) {
      margin-right: 10px;
    }
  }
`;

export const StyledTitle = styled(Title)`
  && {
    display: none;

    @media screen and (min-width: 1170px) {
      display: block;
      max-width: 150px;
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    right: 0;
    bottom: 10px;
    min-width: 100px;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
