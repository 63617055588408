import React from "react";
import styled from "styled-components";
import { Tabs, Tab } from "@material-ui/core";

import { getCategoryColor } from "../../../helpers/categories";

const StyledTabs = styled(Tabs)`
  && {
    margin: 0 auto;
    text-align: center;
    margin-top: -38px;
    margin-left: 270px;
  }
`;

const StyledTab = styled(Tab)`
  && {
    min-height: auto;
    background: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    min-width: auto;
    width: auto;
    border: none;
    background: ${({ $isActive }) =>
      $isActive ? "#fff" : "transparent"} !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 38px;
    font-size: 12px;
    font-weight: 800;
    color: ${({ $isActive }) => ($isActive ? "#000" : "#d1d1d1")};
    padding: 0 15px;
  }
`;

const Dot = styled.span`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background-color: ${({ kind }) => `${getCategoryColor(kind)}`};
`;

const CategoriesDesktop = ({ currentCategory, categories, changeCallback }) => {
  const handleTabChange = (event, value) => {
    changeCallback(value);
  };

  return (
    <StyledTabs
      value={categories.length ? currentCategory : 0}
      onChange={handleTabChange}
      TabIndicatorProps={{ style: { display: "none" } }}
    >
      <Tab value={0} style={{ display: "none" }} label="&nbsp;" />
      {categories.map((category, index) => (
        <StyledTab
          key={index}
          value={category.categoryId}
          $isActive={category.categoryId === currentCategory}
          label={
            <span
              data-ect={
                category.categoryId === currentCategory
                  ? "item-category"
                  : undefined
              }
            >
              <Dot kind={category.token.toLowerCase()} /> {category.name}
            </span>
          }
        />
      ))}
    </StyledTabs>
  );
};

export default CategoriesDesktop;
