import { Icon } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { replace } from "connected-react-router";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const Container = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 30px 0;
`);

export const ProfileBack = () => {
  const dispatch = useDispatch();
  const handleGoBack = () => {
    dispatch(replace("/profile"));
  };
  return (
    <Container onClick={handleGoBack}>
      <Icon color="primary">chevron_left</Icon>
      <FormattedMessage id="eo.back" defaultMessage="Back" />
    </Container>
  );
};
