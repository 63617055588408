import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import LogoIcon from "../../../../components/Icons/Logo/LogoSmall";
import { Toolbar } from "@material-ui/core";

export const StyledAppBar = styled(AppBar)`
  && {
    top: 0;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.35);
    background: #fff;
    display: flex;
    color: rgba(0, 0, 0, 0.87);

    & h1 {
      margin: 12px 0 0 0;
      font-size: 16px;
      line-height: 22px;
      font-weight: 800;
    }

    & h2 {
      margin: 0;
      font-size: 13px;
      line-height: 16px;
      color: #666;
      font-weight: 400;
    }
  }
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled(LogoIcon)`
  margin-right: 18px;
`;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  height: ${(props) => props.height};
  padding-left: 8px;
  padding-right: 8px;

  svg {
    height: 64px;
    flex-basis: calc(100% - 100px);

    &:first-child {
      flex-basis: 57px;
    }
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
`;
