import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import CartLoader from "./CartLoader";
import EmptyCart from "./EmptyCart/EmptyCart";
import ItemsList from "./ItemsList";
import OrderButtons from "./OrderButtons";
import WhatNext from "./WhatNext/WhatNext";
import Addresses from "./Addresses/Addresses";
import { clearBasket, placeOrder } from "../actions";
import CustomMessage from "../../../components/CustomMessage/CustomMessage";

const AppWrapper = styled.div`
  padding: 15px 10px;
`;

const Container = styled.div`
  padding-bottom: 30px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Items = styled.div`
  padding-top: 8px;

  @media screen and (min-width: 768px) {
    flex: 2;
    padding-right: 30px;
  }
`;

const AddressesContainer = styled.div`
  flex: 1;
`;

const MessageContainerDesktop = styled.div`
  padding-top: 10px;
`;

const MessageContainerMobile = styled.div`
  padding: 10px;
`;

const CartView = ({
  showCartLoader,
  isFull,
  list,
  orderDetails,
  currency,
  handleEditClick,
  addresses,
  changeDeliveryAddressCallback,
  changeInvoiceAddressCallback,
  containsRiskyProducts,
}) => {
  const dispatch = useDispatch();

  const { deviceType } = useSelector((state) => state.translations);
  const { termsUrl } = useSelector((state) => state.header);
  const cart_middle = useSelector(
    (state) => state.header?.countryOptions?.cart_middle
  );
  const [orderNumber, setOrderNumber] = useState(false);

  return (
    <Container>
      {showCartLoader ? (
        <CartLoader />
      ) : isFull ? (
        <Details>
          <Items>
            {currency && (
              <ItemsList
                items={list}
                orderDetails={orderDetails}
                currency={currency}
                handleEditClick={handleEditClick}
              />
            )}
            {deviceType !== "mobile" && (
              <>
                {cart_middle && (
                  <MessageContainerDesktop>
                    <CustomMessage htmlMessage={cart_middle} />
                  </MessageContainerDesktop>
                )}
                <OrderButtons
                  cancelCallback={() => dispatch(clearBasket())}
                  submitCallback={() => dispatch(placeOrder(orderNumber))}
                  orderNumberCallback={setOrderNumber}
                  termsUrl={termsUrl}
                />
              </>
            )}
          </Items>
          <AddressesContainer>
            <Addresses
              addresses={addresses}
              changeInvoiceAddressCallback={changeInvoiceAddressCallback}
              changeDeliveryAddressCallback={changeDeliveryAddressCallback}
            />
            {deviceType === "mobile" && (
              <>
                {cart_middle && (
                  <MessageContainerMobile>
                    <CustomMessage htmlMessage={cart_middle} />
                  </MessageContainerMobile>
                )}
                <OrderButtons
                  cancelCallback={() => dispatch(clearBasket())}
                  submitCallback={() => dispatch(placeOrder(orderNumber))}
                  orderNumberCallback={setOrderNumber}
                  termsUrl={termsUrl}
                />
              </>
            )}
            <WhatNext containsRiskyProducts={containsRiskyProducts} />
          </AddressesContainer>
        </Details>
      ) : (
        <AppWrapper>
          <EmptyCart />
        </AppWrapper>
      )}
    </Container>
  );
};

CartView.propTypes = {
  addresses: PropTypes.object.isRequired,
  currency: PropTypes.string,
  isFull: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  orderDetails: PropTypes.object.isRequired,
  showCartLoader: PropTypes.bool.isRequired,
  changeInvoiceAddressCallback: PropTypes.func.isRequired,
  changeDeliveryAddressCallback: PropTypes.func.isRequired,
  containsRiskyProducts: PropTypes.bool.isRequired,
};

export default CartView;
