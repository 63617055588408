import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { push } from "connected-react-router";
import { Button } from "@material-ui/core";
import styled from "styled-components";

import { useCountryDispatch } from "../../../hooks/useCountry";
import { validateToken, handleNewPasswordSubmit } from "../actions";

import NewPasswordForm from "./NewPasswordForm";
import ActivationLoader from "./ActivationLoader";
import ActivateTokenInvalid from "./ActivateTokenInvalid";

const Container = styled.div`
  margin: 34px 0;
`;

const StyledSentContainer = styled.div`
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  padding-bottom: 0;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
`;

const StyledActivationLoader = styled(ActivationLoader)`
  max-width: 320px;
`;

const StyledNewPasswordFormContainer = styled.div`
  max-width: 550px;
  margin: 0 auto;
  padding: 0 10px 0 10px;
`;

const Activate = (props) => {
  const dispatch = useDispatch();
  const countryDispatch = useCountryDispatch();
  const [requestSent, setRequestSent] = useState(null);
  const [tokenIsValid, setTokenIsValid] = useState(null);

  const handleSubmit = () => {
    setRequestSent(true);
  };

  useEffect(() => {
    countryDispatch({
      type: "set",
      country: props.match.params.country,
      language: props.match.params.language,
    });

    validateToken(props.match.params.token)
      .then((response) => {
        if (response.data.valid) {
          setTokenIsValid(true);
        } else {
          setTokenIsValid(false);
        }
      })
      .catch(() => setTokenIsValid(false));
  }, []);

  const newPasswordSubmit = (values) => {
    return handleNewPasswordSubmit(
      Object.assign({}, values, {
        hash: props.match.params.token,
      })
    );
  };

  const handleBtnClick = (destination) => {
    dispatch(push(destination));
  };

  return (
    <Container>
      {requestSent === null && (
        <>
          <Title>
            <FormattedMessage
              id="eo.activate-account-title"
              defaultMessage="Account activation"
            />
          </Title>

          {tokenIsValid && (
            <StyledNewPasswordFormContainer>
              <NewPasswordForm
                handleSubmitForm={newPasswordSubmit}
                onSuccess={handleSubmit}
                withCurrentPassword={false}
              />
            </StyledNewPasswordFormContainer>
          )}

          {tokenIsValid === false && <ActivateTokenInvalid />}

          {tokenIsValid === null && <StyledActivationLoader />}
        </>
      )}

      {requestSent && (
        <StyledSentContainer>
          <p>
            <FormattedMessage
              id="eo.password-set"
              defaultMessage="Your password is set. Now you can log in."
            />
          </p>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleBtnClick("/login")}
          >
            <FormattedMessage id="eo.log-in" defaultMessage="Log in" />
          </Button>
        </StyledSentContainer>
      )}
    </Container>
  );
};

export default Activate;
