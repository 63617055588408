import { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmpty } from "lodash";
import styled from "styled-components";

import AddressItem from "./AddressItem";
import AddressForm from "./AddressForm";
import ExpandableCard from "../../../../components/ExpandableCard/ExpandableCard";
import { withTheme } from "@material-ui/core/styles";
import { useCountry } from "../../../../hooks/useCountry";

const Title = withTheme(styled.h2`
  color: ${({ theme }) => theme.palette.gray.main};
  font-size: 14px;
  font-weight: 900;
  line-height: 1.5em;
  text-align: left;
  text-transform: uppercase;
`);

const Addresses = ({
  addresses,
  changeDeliveryAddressCallback,
  changeInvoiceAddressCallback,
}) => {
  const intl = useIntl();
  const { translationCode: countryTranslationCode, country } = useCountry();
  const [invoiceAddressesExpanded, setInvoiceAddressesExpanded] =
    useState(false);
  const [deliveryAddressesExpanded, setDeliveryAddressesExpanded] =
    useState(false);

  const handleExpandChangeInvoiceAddresses = (expand) => {
    expand && handleReduceDeliveryAddresses();
    setInvoiceAddressesExpanded(!invoiceAddressesExpanded);
  };

  const handleReduceInvoiceAddresses = () => {
    setInvoiceAddressesExpanded(false);
  };

  const handleExpandChangeDeliveryAddresses = (expand) => {
    expand && handleReduceInvoiceAddresses();
    setDeliveryAddressesExpanded(!deliveryAddressesExpanded);
  };

  const handleReduceDeliveryAddresses = () => {
    setDeliveryAddressesExpanded(false);
  };

  const handleInvoiceAddressFormSubmit = (values) => {
    changeInvoiceAddressCallback(values);
    handleReduceInvoiceAddresses();
  };

  const handleDeliveryAddressFormSubmit = (values) => {
    changeDeliveryAddressCallback(values);
    handleReduceDeliveryAddresses();
  };

  return (
    <div className="address-box">
      <div>
        {!isEmpty(addresses.invoice) && (
          <AddressItem
            address={addresses.invoice}
            titleId="invoice-data"
            title="Invoice data"
          />
        )}
        <ExpandableCard
          title={
            <FormattedMessage
              id="eo.change-invoice-address"
              defaultMessage="Change invoice address"
            />
          }
          body={
            <div>
              <Title>
                <FormattedMessage
                  id="eo.adjust-invoice-address"
                  defaultMessage="Adjust address"
                />
              </Title>
              <AddressForm
                onSubmit={handleInvoiceAddressFormSubmit}
                initialValues={addresses.invoice}
              />
            </div>
          }
          handleExpandChange={handleExpandChangeInvoiceAddresses}
          cardExpanded={invoiceAddressesExpanded}
        />
      </div>

      <div>
        {!isEmpty(addresses.delivery) && (
          <AddressItem
            address={addresses.delivery}
            titleId="delivery-address"
            title="Delivery address"
          />
        )}
        <ExpandableCard
          title={
            <FormattedMessage
              id="eo.change-delivery-address"
              defaultMessage="Change delivery address"
            />
          }
          body={
            <div>
              <Title>
                <FormattedMessage
                  id="eo.new-delivery-address-in"
                  defaultMessage="New address in {country}"
                  values={{
                    country: intl.formatMessage({
                      id: countryTranslationCode,
                      defaultMessage: country,
                    }),
                  }}
                />
              </Title>
              <AddressForm
                onSubmit={handleDeliveryAddressFormSubmit}
                validateAddress={true}
              />
            </div>
          }
          handleExpandChange={handleExpandChangeDeliveryAddresses}
          cardExpanded={deliveryAddressesExpanded}
        />
      </div>
    </div>
  );
};

Addresses.propTypes = {
  addresses: PropTypes.object.isRequired,
  changeInvoiceAddressCallback: PropTypes.func.isRequired,
  changeDeliveryAddressCallback: PropTypes.func.isRequired,
};

export default Addresses;
