import * as t from "./actionTypes";
import axios from "axios";
import config from "../../config";
import Auth from "../core/Auth";
import { handleRequestError } from "../core/ErrorHandler";

export const fetchTaDetails = () => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
    },
  };

  return (dispatch) => {
    dispatch({ type: t.FETCH_TA_REQUEST });

    axios
      .get(config.apiUrl + "eo/ta/details", token)
      .then((response) =>
        dispatch({
          type: t.FETCH_TA_SUCCESS,
          data: response.data,
        })
      )
      .catch(
        (error) =>
          handleRequestError(dispatch, error, t.FETCH_TA_FAILURE) /*dispatch({
                type: t.FETCH_TA_FAILURE,
                error: error.response.statusText
            })*/
      );
  };
};

export const requestVisitCall = () => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getToken(),
    },
  };

  return axios.post(config.apiUrl + "eo/contact/request", {}, token);
  // .then((response) =>
  //   dispatch({
  //     type: t.REQUEST_VISIT_SUCCESS,
  //   })
  // )
  // .catch((response) =>
  //   handleRequestError(dispatch, response, t.REQUEST_VISIT_FAILURE)
  // );
};

export const switchUnloggedContactTo = (value) => {
  return (dispatch) => {
    dispatch({ type: t.SWITCH_UNLOGGED_CONTACT_TO, data: value });
  };
};

export const switchValuesFromProductCalls = (value) => {
  return (dispatch) => {
    dispatch({ type: t.SWITCH_TO_REQUEST_FORM, data: value });
    dispatch({ type: t.SWITCH_TO_SIGNIN_FORM, data: value });
  };
};

export const handleSubmit = (values) => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getUnloggedToken(),
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();

  formData.append("message", values.request);
  formData.append("name", values.name);
  formData.append("company", values.company);
  formData.append("phone", values.phone);

  return axios
    .post(config.apiUrl + "eo/open/contact/request", formData, token)
    .then((response) => {
      return {
        type: t.FORM_REQUEST_SUBMIT_SUCCESS,
        data: response.data,
      };
    });
};

export const handleSigninSubmit = (values) => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getUnloggedToken(),
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("email", values.email);

  return axios
    .post(config.apiUrl + "eo/open/contact/signin", formData, token)
    .then((response) => {
      return {
        type: t.FORM_SIGNIN_SUBMIT_SUCCESS,
        data: response.data,
      };
    });
};
