import styled from "styled-components";
import { List, ListItem } from "@material-ui/core";

const ProfileOuterContainer = styled.div`
  max-width: 768px;
  margin: 0 auto;

  @media screen and (min-width: 1170px) {
    max-width: 1170px;
  }
  padding: 0 10px 0 10px;
`;

const ProfileContainer = styled.div`
  max-width: 550px;
  margin: 0 auto;
`;

const ProfileTitle = styled.h2`
  text-align: center;
  color: #666;
  font-size: 18px;
`;

const TopProfileTitle = styled(ProfileTitle)`
  margin-top: 40px;
`;

export const StyledList = styled(List)`
  && {
    border-top: 2px dotted #929495;
    margin-top: 40px;
    font-weight: 700;
    font-size: 13px;
    padding: 0;
  }
`;

export const StyledListItem = styled(ListItem)`
  && {
    border-bottom: 2px dotted #929495;
    color: #000000;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
  }
`;

export {
  ProfileContainer,
  ProfileTitle,
  ProfileOuterContainer,
  TopProfileTitle,
};
