import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { size } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";
import {
  fetchOrders,
  filterOrders,
  clearFilterOrders,
  closeNewOrderRegisteredInfo,
  handleNewOrderExpand,
  fetchOrderAuthors,
  clearOrdersHistory,
} from "../../actions";
import { reorder } from "../../../cart/actions";
import Order from "../Order/Order";
import OrdersFilter from "./OrdersFilter";
import OrdersFilterDesktop from "./OrdersFilterDesktop";
import NewOrderRegistered from "./NewOrderRegistered";
import MyOrdersLoader from "./MyOrdersLoader";
import MyOrdersLineLoader from "./MyOrdersLineLoader";
import MyOrdersLineLoaderTablet from "./MyOrdersLineLoaderTablet";
import MyOrdersLoaderDesktop from "./MyOrdersLoaderDesktop";
import MyOrdersLineLoaderDesktop from "./MyOrdersLineLoaderDesktop";
import EmptyOrdersInfo from "../EmptyOrdersInfo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 55px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
`;

const Header = styled.h2`
  font-size: 18px;
  color: #333;
  font-weight: 800;
  line-height: 45px;
  margin: 10px 0 0;
`;

const MyOrders = ({ value, index }) => {
  const dispatch = useDispatch();

  const { deviceType } = useSelector((state) => state.translations);
  const {
    filters,
    orders,
    ordersAuthors,
    ordersCount,
    ordersLoader,
    showNewOrderExpanded,
    showInfoNewOrderRegistered,
    newOrderContainsRiskyProducts,
  } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(fetchOrders(1));
    dispatch(fetchOrderAuthors());

    return () => {
      dispatch(handleNewOrderExpand(false));
      dispatch(clearOrdersHistory());
      window.scrollTo(0, 0);
    };
  }, []);

  const handleCloseNewOrderRegistered = () => {
    dispatch(handleNewOrderExpand(true));
    dispatch(closeNewOrderRegisteredInfo());
  };

  const loader = () => {
    switch (deviceType) {
      case "mobile":
        return <MyOrdersLineLoader />;
      case "tablet":
        return <MyOrdersLineLoaderTablet />;
      case "desktop":
        return <MyOrdersLineLoaderDesktop />;
      default:
        return <MyOrdersLineLoader />;
    }
  };

  const hasMore = () => {
    return ordersCount > size(orders);
  };

  const shouldShow = index === value ? {} : { display: "none" };

  const filtersCount = Object.values(filters)?.filter(
    (item) => item !== null
  ).length;

  const reorderCallback = (order) => {
    dispatch(reorder(order));
  };

  const filterCallback = (filters) => {
    dispatch(filterOrders(filters));
  };

  const handleLoadMore = (page) => {
    dispatch(fetchOrders(page));
  };

  let ordersList = orders.map((order, index) => (
    <Order
      initiallyExpanded={
        showNewOrderExpanded && !showInfoNewOrderRegistered
          ? index < 3
          : index < 2
      }
      order={order}
      key={order.orderId}
      reorderCallback={reorderCallback}
    />
  ));

  return (
    <Container style={shouldShow}>
      {deviceType === "mobile" && (
        <OrdersFilter
          applyCallback={filterCallback}
          clearCallback={() => dispatch(clearFilterOrders())}
          filtersCount={filtersCount || 0}
          filterValues={filters}
          ordersAuthors={ordersAuthors}
        />
      )}
      {deviceType !== "mobile" && (
        <OrdersFilterDesktop
          applyCallback={filterCallback}
          clearCallback={() => dispatch(clearFilterOrders())}
          filtersCount={filtersCount || 0}
          filterValues={filters}
          ordersAuthors={ordersAuthors}
        />
      )}
      {deviceType !== "mobile" && (
        <div>
          <Header>
            <FormattedMessage id="eo.myorders" defaultMessage="My orders" />
          </Header>
        </div>
      )}

      {ordersLoader && deviceType === "mobile" && <MyOrdersLoader />}
      {ordersLoader && deviceType !== "mobile" && <MyOrdersLoaderDesktop />}
      {orders.length > 0 ? (
        <InfiniteScroll
          pageStart={1}
          loadMore={handleLoadMore}
          loader={loader()}
          hasMore={hasMore()}
          threshold={100}
        >
          {ordersList}
        </InfiniteScroll>
      ) : (
        <EmptyOrdersInfo
          filtersCount={filtersCount || 0}
          ordersLoader={ordersLoader}
        />
      )}

      {showInfoNewOrderRegistered && (
        <NewOrderRegistered
          closeCallback={handleCloseNewOrderRegistered}
          containsRiskyProducts={newOrderContainsRiskyProducts}
        />
      )}
    </Container>
  );
};

export default MyOrders;
