import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { push } from "connected-react-router";

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const UnloggedContactUs = () => {
  const dispatch = useDispatch();

  const handleClick = (destination) => {
    dispatch(push(destination));
  };

  const switchToRequestForm = () => {
    dispatch(push("/contact/request"));
  };

  const switchToSignInForm = () => {
    dispatch(push("/contact/signin"));
  };

  return (
    <Container>
      <Button
        variant="contained"
        color="primary"
        fullWidth={true}
        onClick={switchToRequestForm}
      >
        <FormattedMessage
          id="eo.request-a-call-back"
          defaultMessage="Request a call back"
        />
      </Button>
      <div>
        <FormattedMessage id="eo.or" defaultMessage="or" />
      </div>
      <Button
        color="primary"
        variant="contained"
        fullWidth={true}
        onClick={switchToSignInForm}
      >
        <FormattedMessage id="eo.register" defaultMessage="Sign in" />
      </Button>
      <div>
        <FormattedMessage
          id="eo.question-already-registered"
          defaultMessage="Already registered?"
        />
        <Button color="primary" onClick={() => handleClick("/login")}>
          <FormattedMessage id="eo.log-in" defaultMessage="Log in" />
        </Button>
      </div>
      <div className="clearfix" />
    </Container>
  );
};

export default UnloggedContactUs;
