import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import * as yup from "yup";
import FormNew from "../../../components/Form/FormNew";
import FormInputNew from "../../../components/Form/FormInputNew";
import NewPasswordFormValidation from "./NewPasswordFormValidation";

const Container = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 4px;
`;

const NewPasswordForm = ({
  handleSubmitForm,
  onSuccess,
  withCurrentPassword,
}) => {
  const [currentPasswordVisible, setCurrentPasswordVisible] =
    React.useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [rePasswordVisible, setRePasswordVisible] = React.useState(false);
  const [validationRules, setValidationRules] = React.useState([]);
  const intl = useIntl();

  const defaultValues = {
    currentPassword: "",
    password: "",
    rePassword: "",
  };

  const schema = yup
    .object({
      currentPassword: withCurrentPassword
        ? yup
            .string()
            .max(
              72,
              <FormattedMessage
                id="eo.password-too-long"
                defaultMessage="Password too long"
              />
            )
            .required(
              <FormattedMessage id="eo.required" defaultMessage="Required" />
            )
        : null,
      password: yup
        .string()
        .max(
          72,
          <FormattedMessage
            id="eo.password-too-long"
            defaultMessage="Password too long"
          />
        )
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        )
        .notOneOf(
          [yup.ref("currentPassword"), null],
          <FormattedMessage
            id="eo.password-match-old"
            defaultMessage="Your new password must be different from your old password"
          />
        ),
      rePassword: yup
        .string()
        .max(
          72,
          <FormattedMessage
            id="eo.password-too-long"
            defaultMessage="Password too long"
          />
        )
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        )
        .oneOf(
          [yup.ref("password"), null],
          <FormattedMessage
            id="eo.passwords-not-identical"
            defaultMessage="Passwords are not identical"
          />
        ),
    })
    .required();

  const onSubmit = (data) => {
    const allItemsChecked = validationRules.every((item) => item.checked);
    if (allItemsChecked) {
      return handleSubmitForm(data).then(() => {
        onSuccess && onSuccess();
      });
    }
  };

  const toggleCurrentPassword = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleRePassword = () => {
    setRePasswordVisible(!rePasswordVisible);
  };

  const handlePasswordChange = (value) => {
    setValidationRules([
      {
        text: intl.formatMessage({
          id: "eo.rule-pw-length",
          defaultMessage: "Contain at least 8 characters",
        }),
        checked: /.{8,}/.test(value),
      },
      {
        text: intl.formatMessage({
          id: "eo.rule-pw-lc-letter",
          defaultMessage: "Include at least one lower case letter (a-z)",
        }),
        checked: /[a-z]/.test(value),
      },
      {
        text: intl.formatMessage({
          id: "eo.rule-pw-uc-letter",
          defaultMessage: "Include at least one upper case letter (A-Z)",
        }),
        checked: /[A-Z]/.test(value),
      },
      {
        text: intl.formatMessage({
          id: "eo.rule-pw-number",
          defaultMessage: "Include at least one number (0-9)",
        }),
        checked: /\d/.test(value),
      },
      {
        text: intl.formatMessage({
          id: "eo.rule-pw-special",
          defaultMessage: "Include at least one special character (!@#$...)",
        }),
        checked: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value),
      },
    ]);
  };
  return (
    <Container>
      <FormNew
        subTitle={
          <FormattedMessage
            id="eo.type-new-password"
            defaultMessage="Type your new password"
          />
        }
        onSubmitForm={onSubmit}
        defaultValues={defaultValues}
        schema={schema}
        watchValue={"password"}
        watchCallback={handlePasswordChange}
        isCaptcha={false}
        btnLabel={<FormattedMessage id="eo.ok" defaultMessage="Submit" />}
      >
        {!!withCurrentPassword ? (
          <FormInputNew
            name="currentPassword"
            type={currentPasswordVisible ? "text" : "password"}
            label={
              <FormattedMessage
                id="eo.current-password"
                defaultMessage="Current password"
              />
            }
            icon={
              <IconButton size={"small"} onClick={toggleCurrentPassword}>
                {currentPasswordVisible ? (
                  <Visibility fontSize="inherit" />
                ) : (
                  <VisibilityOff fontSize="inherit" />
                )}
              </IconButton>
            }
            required={true}
          />
        ) : null}

        <FormInputNew
          name="password"
          type={passwordVisible ? "text" : "password"}
          label={
            <FormattedMessage
              id="eo.new-password"
              defaultMessage="New password"
            />
          }
          icon={
            <IconButton size={"small"} onClick={togglePassword}>
              {passwordVisible ? (
                <Visibility fontSize="inherit" />
              ) : (
                <VisibilityOff fontSize="inherit" />
              )}
            </IconButton>
          }
          required={true}
        />
        <FormInputNew
          name="rePassword"
          type={rePasswordVisible ? "text" : "password"}
          label={
            <FormattedMessage
              id="eo.retype-password"
              defaultMessage="Retype password"
            />
          }
          icon={
            <IconButton size={"small"} onClick={toggleRePassword}>
              {rePasswordVisible ? (
                <Visibility fontSize="inherit" />
              ) : (
                <VisibilityOff fontSize="inherit" />
              )}
            </IconButton>
          }
          required={true}
        />
        <NewPasswordFormValidation
          title={intl.formatMessage({
            id: "eo.password-must",
            defaultMessage: "Password must:",
          })}
          items={validationRules}
        />
      </FormNew>
    </Container>
  );
};

NewPasswordForm.propTypes = {
  handleSubmitForm: PropTypes.func.isRequired,
};

export default NewPasswordForm;
