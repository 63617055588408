import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";
import { Icon, IconButton, ListItemText } from "@material-ui/core";

import {
  StyledListItem,
  Name,
  Prices,
  Price,
  Input,
  Actions,
  Total,
  QuantityButton,
  Remove,
} from "./EditItem.styles";

const EditItem = ({
  item,
  currency,
  removeCallback,
  increaseQuantityCallback,
  decreaseQuantityCallback,
  editQuantityCallback,
}) => (
  <StyledListItem className="cart-EditItem">
    <ListItemText
      primary={
        <Name>
          <span data-ect="name">{item.name}</span> /&nbsp;
          <span data-ect="variant">
            {item.amount > 1 ? item.amount + " x " : ""}
            {item.capacity}
          </span>
        </Name>
      }
      secondary={
        <Prices>
          <Actions>
            <Remove>
              <IconButton
                className="quantity-button"
                onClick={() => removeCallback(item)}
                style={{ marginTop: "-1px" }}
                data-ect="remove-item"
              >
                <Icon color="primary">clear</Icon>
              </IconButton>
            </Remove>

            <Price data-ect="price">
              {currency}{" "}
              <FormattedNumber
                minimumFractionDigits={2}
                value={item.price * item.amount}
              />
            </Price>

            <span>
              <QuantityButton
                onClick={() => decreaseQuantityCallback(item)}
                data-ect="remove-from-cart"
              >
                <Icon color="primary">remove_circle_outline</Icon>
              </QuantityButton>
              <Input
                type="number"
                min="1"
                max="999"
                name="quantity"
                value={item.quantity}
                onChange={(e) => {
                  editQuantityCallback(item, e.target.value);
                }}
                data-ect="quantity"
              />
              <QuantityButton
                onClick={() => increaseQuantityCallback(item)}
                data-ect="add-to-cart"
              >
                <Icon color="primary">add_circle_outline</Icon>
              </QuantityButton>
            </span>
          </Actions>

          <Total>
            {currency}&nbsp;
            <FormattedNumber
              minimumFractionDigits={2}
              value={item.price * item.amount * item.quantity}
            />
          </Total>
        </Prices>
      }
    />
  </StyledListItem>
);

EditItem.propTypes = {
  item: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  removeCallback: PropTypes.func.isRequired,
  increaseQuantityCallback: PropTypes.func.isRequired,
  decreaseQuantityCallback: PropTypes.func.isRequired,
};

export default EditItem;
