import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Dialog, DialogContent, Button } from "@material-ui/core";

import {
  ProfileContainer,
  ProfileTitle,
  ProfileOuterContainer,
} from "./Profile.styles";
import NewPasswordForm from "../../login/components/NewPasswordForm";
import {
  handleChangePasswordSubmit,
  handleClosePasswordChangedPopup,
} from "../actions";
import { ProfileBack } from "./ProfileBack";
import { useDevice } from "../../../hooks/useDevice";

const dialogContentStyle = {
  textAlign: "center",
};

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [requestSent, setRequestSent] = useState(null);
  const { device } = useDevice();
  const isMobile = device === "mobile";

  const handleSuccess = () => {
    setRequestSent(true);
  };

  return (
    <ProfileOuterContainer>
      {!isMobile && <ProfileBack />}
      <ProfileContainer>
        {isMobile && <ProfileBack />}
        <ProfileTitle>
          <FormattedMessage
            id="eo.profile-settings"
            defaultMessage="Profile settings"
          />
        </ProfileTitle>
        <NewPasswordForm
          handleSubmitForm={handleChangePasswordSubmit}
          onSuccess={handleSuccess}
          withCurrentPassword={true}
        />
        <Dialog className="activate-Activate-popup" open={requestSent}>
          <DialogContent
            style={dialogContentStyle}
            className="activate-Activate-popupContent"
          >
            <p>
              <FormattedMessage
                id="eo.password-set"
                defaultMessage="Your password is set. Now you can log in."
              />
            </p>
            <Button
              color="primary"
              variant={"contained"}
              onClick={() => dispatch(handleClosePasswordChangedPopup())}
            >
              <FormattedMessage id="eo.log-in" defaultMessage="Log in" />
            </Button>
          </DialogContent>
        </Dialog>
      </ProfileContainer>
    </ProfileOuterContainer>
  );
};

export default ChangePassword;
