import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Icon } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { CART_MENU_NAME, CART_MENU_TITLE } from "../constants";
import MenuCartPopoverDesktop from "./MenuCartPopoverDesktop";

const MenuItem = withTheme(styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 9px;
  fill: currentColor;
  cursor: pointer;

  ${({ $isSelected, theme }) =>
    $isSelected
      ? `color: #fff;
    border-bottom: 5px solid ${theme.palette.primary.main};
    margin-bottom: -4px;`
      : ""}

  @media screen and (max-width: 1169px) and (min-width: 768px) {
    flex-direction: column;
  }

  @media screen and (min-width: 1170px) {
    padding: 9px 25px;
    font-size: 14px;
  }
`);

const MenuIcon = styled.div`
  text-align: center;
  height: 24px;
  color: #d1d1d1;

  @media screen and (min-width: 1170px) {
    .material-icons {
      float: right;
    }
  }
`;

const Badge = withTheme(styled.div`
  float: right;
  margin-top: 3px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.primary.main};
  background: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;

  @media screen and (min-width: 768px) {
    float: left;
    background: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
    margin-right: 5px;
  }
`);

const MenuCartItemDesktop = ({ item, onClick, isSelected }) => {
  const domElement = React.createRef();
  const { items } = useSelector((state) => state.cart);

  return (
    <>
      <MenuItem
        onClick={onClick}
        $isSelected={isSelected}
        data-ect="begin-checkout"
      >
        <MenuIcon ref={domElement}>
          <span>
            <Icon>{item.icon}</Icon>
            {items.length > 0 && <Badge>{items.length}</Badge>}
          </span>
        </MenuIcon>
        <FormattedMessage
          id={"eo." + CART_MENU_NAME}
          defaultMessage={CART_MENU_TITLE}
        />
      </MenuItem>
      <MenuCartPopoverDesktop anchorEl={domElement} />
    </>
  );
};

MenuCartItemDesktop.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default MenuCartItemDesktop;
