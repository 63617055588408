import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import styled from "styled-components";

const Container = styled.div`
  max-width: 310px;
  margin: 0 auto;
  padding: 15px 10px;
  text-align: center;
`;

const Title = styled.h2`
  margin-top: 34px;
  margin-bottom: 10px;
  padding-bottom: 0;
  font-weight: bold;
  color: #333;
  font-size: 16px;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const UnloggedCart = ({ loginCallback, requestCallback, signinCallback }) => {
  return (
    <Container>
      <div>
        <Title>
          <FormattedMessage id="eo.cart-empty" defaultMessage="Cart is empty" />
        </Title>
        <FormattedMessage
          id="eo.enable-ordering"
          defaultMessage="In order to enable ordering"
        />
      </div>
      <Wrapper>
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          className="reorder-button"
          style={{ boxShadow: "none", float: "none", maxWidth: "none" }}
          onClick={requestCallback}
        >
          <FormattedMessage
            id="eo.request-a-call-back"
            defaultMessage="Request a call back"
          />
        </Button>

        <div>{<FormattedMessage id="eo.or" defaultMessage="or" />}</div>

        <Button
          style={{ boxShadow: "none", float: "none", maxWidth: "none" }}
          fullWidth={true}
          color="primary"
          variant="contained"
          className="reorder-button"
          onClick={signinCallback}
        >
          <FormattedMessage id="eo.register" defaultMessage="Sign in" />
        </Button>

        <div>
          <FormattedMessage
            id="eo.question-already-registered"
            defaultMessage="Already registered?"
          />
          <Button color="primary" onClick={loginCallback}>
            <FormattedMessage id="eo.log-in" defaultMessage="Log in" />
          </Button>
        </div>
      </Wrapper>
    </Container>
  );
};

UnloggedCart.propTypes = {
  loginCallback: PropTypes.func.isRequired,
  requestCallback: PropTypes.func.isRequired,
  signinCallback: PropTypes.func.isRequired,
};

export default UnloggedCart;
