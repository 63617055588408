import { useEffect, useState } from "react";
import { fetchProductsByQuery } from "../../actions/actions";

const useSearch = (query, from, to, filters, type) => {
  const [searchProducts, setSearchProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async (query) => {
      try {
        setLoading(true);
        const response = await fetchProductsByQuery(query);
        setSearchProducts(response.data.products);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchProducts(query);
  }, [query]);

  const filteredProducts = filters.length
    ? searchProducts.filter((product) => {
        return filters.every((filterId) =>
          product[type]?.some((filter) => filter.id.toString() === filterId)
        );
      })
    : searchProducts;

  const data = filteredProducts.slice(from, to);

  return {
    products: data,
    queryProducts: searchProducts,
    filteredProducts: filteredProducts,
    loading,
  };
};

export default useSearch;
