import { push, replace } from "connected-react-router";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ImageWithPlaceholder } from "../../../../components/ImageWithPlaceholder/ImageWithPlaceholder";
import { FiltersDesktop, useFilters } from "../../../filters";
import { changeCategory, fetchCatalogue, showProduct } from "../../actions";
import Categories from "../Categories";
import CategoriesDesktop from "../CategoriesDesktop";
import ShopLoaderDesktop from "./ShopLoaderDesktop";
import { SubcategoryDesktop } from "../Subcategories/SubcategoryDesktop";
import { Icon } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

export const ShopContainer = styled.div`
  width: 768px;
  margin: 0 auto;

  @media screen and (min-width: 1170px) {
    width: 1170px;
  }
`;

export const ShopList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 30px 0;
`;

export const ShopItem = styled.div`
  width: 320px;
  border: 2px solid #d1d1d1;
  border-radius: 4px;
  margin: 10px 8px;
  padding: 20px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  cursor: pointer;

  @media screen and (min-width: 1170px) {
    width: 330px;
  }

  h2 {
    font-size: 14px;
    color: #333;
    font-weight: 800;
    margin: 0;
  }

  p {
    font-size: 12px;
    color: #999;
    font-weight: 400;
  }
`;

export const Photo = styled.div`
  width: 100px;
  height: 100px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100px;
    max-height: 100px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 215px;
  position: relative;
`;

const MessageContainer = styled.div`
  padding: 30px;
  text-align: center;
  color: #666;
`;

const StyledSubcategoryHeader = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 30px 0;
`);

const StyledSubcategoryName = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: black;
`;

const ShopDesktop = (props) => {
  const dispatch = useDispatch();
  const { catalogue, currentCategory } = useSelector((state) => state.shop);
  const { deviceType } = useSelector((state) => state.translations);

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [currentSubcategory, setCurrentSubcategory] = useState(null);

  const searchParams = new URLSearchParams(props.location.search);
  const tags = searchParams.getAll("tags");
  const subcategoryParam = searchParams.get("subcategory");

  const handleChangeCategory = (categoryId) => {
    dispatch(changeCategory(categoryId));
    dispatch(replace(props.location.pathname));
  };

  useEffect(() => {
    dispatch(fetchCatalogue());

    return () => window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const cats = catalogue
      .filter(
        ({ subCategories, products }) =>
          subCategories?.some(
            (subCategory) => subCategory.products?.length > 0
          ) || products?.length > 0
      )
      .map((category) => ({
        categoryId: category.categoryId,
        name: category.name,
        token: category.token,
      }));

    setCategories(cats);
  }, [catalogue]);

  useEffect(() => {
    const cat = catalogue.find((category) => {
      return category.categoryId === currentCategory;
    });

    if (subcategoryParam) {
      const subcat = cat?.subCategories?.find(
        (subcategory) => subcategory.categoryId === Number(subcategoryParam)
      );
      const products = subcat?.products;
      setProducts(products?.length ? products : []);
      setCurrentSubcategory(subcat);
    } else {
      setProducts(cat !== undefined ? cat.products : []);
      setCurrentSubcategory(null);
    }
  }, [catalogue, currentCategory, subcategoryParam]);

  useEffect(() => {
    const cat = catalogue.find((category) => {
      return category.categoryId === currentCategory;
    });

    setSubcategories(cat?.subCategories ? cat.subCategories : []);
  }, [catalogue, currentCategory]);

  const filteredProducts = useFilters(products, tags, "tags");

  const handleChangeSubcategory = (subcategoryId) => {
    searchParams.set("subcategory", subcategoryId);
    const newUrl = props.location.pathname + "?" + searchParams.toString();
    dispatch(push(newUrl));
  };

  const handleGoBack = () => {
    dispatch(replace("/shop"));
  };

  const subcategoriesList = subcategories
    ?.filter((subcategory) => subcategory.products.length)
    .map((subcategory, index) => {
      return (
        <SubcategoryDesktop
          key={index}
          subcategory={subcategory}
          handleChangeSubcategory={handleChangeSubcategory}
        />
      );
    });

  const productsList = filteredProducts?.map((product, index) => (
    <ShopItem
      key={index}
      onClick={() =>
        dispatch(
          showProduct(`/product/${product.categoryId}/${product.groupCode}`)
        )
      }
    >
      <Photo>
        <ImageWithPlaceholder
          src={product.image?.replace("tiny", "small")}
          alt={product.name}
        />
      </Photo>
      <Details>
        <h2>{product.name}</h2>
        <p>{product.lead}</p>
      </Details>
    </ShopItem>
  ));

  return (
    <ShopContainer>
      {!isEmpty(categories) &&
        (deviceType === "desktop" ? (
          <CategoriesDesktop
            currentCategory={currentCategory}
            categories={categories}
            changeCallback={handleChangeCategory}
          />
        ) : (
          <Categories
            currentCategory={currentCategory}
            categories={categories}
            changeCallback={handleChangeCategory}
            deviceType={deviceType}
          />
        ))}
      {currentSubcategory && (
        <StyledSubcategoryHeader onClick={handleGoBack}>
          <Icon color="primary">chevron_left</Icon>
          <FormattedMessage id="eo.back" defaultMessage="Back" />
          <StyledSubcategoryName>
            {currentSubcategory.name}
          </StyledSubcategoryName>
        </StyledSubcategoryHeader>
      )}
      {products && (
        <FiltersDesktop
          products={products}
          filterPer={"tags"}
          filteredProducts={filteredProducts}
        />
      )}
      <ShopList>
        {!subcategoryParam && subcategoriesList}
        {!subcategoriesList && isEmpty(products) ? (
          <>
            {categories.length > 0 ? (
              <MessageContainer>
                <FormattedMessage
                  id="eo.no-products"
                  defaultMessage="No products available"
                />
              </MessageContainer>
            ) : (
              <ShopItem>
                <ShopLoaderDesktop />
              </ShopItem>
            )}
          </>
        ) : (
          productsList
        )}
      </ShopList>
    </ShopContainer>
  );
};

export default ShopDesktop;
