import { routingMappings } from "./constants";
import { get } from "lodash";
import { history } from "../../router";
import { useEffect, useState } from "react";

export const getSelectedIndex = (location) => {
  if (location) {
    const dest = location.pathname;
    const r = routingMappings.find((x) => dest.indexOf(x.to) !== -1);
    return get(r, "id", 0);
  }
};

export const getSelectedHeaderByDevice = (deviceType) => {
  if (history.location) {
    const dest = history.location.pathname;
    const r = routingMappings.find((x) => dest.indexOf(x.to) !== -1);
    if (deviceType === "mobile") {
      return get(r, "header", 0);
    } else {
      return get(r, "headerDesktop", 0);
    }
  }
};

export const useSelectedMenu = () => {
  const [isMenu, setIsMenu] = useState(0);

  useEffect(() => {
    const handleLocationChange = () => {
      setIsMenu(getSelectedMenu());
    };
    handleLocationChange();
    const unlisten = history.listen(handleLocationChange);
    return () => {
      unlisten();
    };
  }, []);

  return isMenu;
};

export const getSelectedMenu = () => {
  if (history.location) {
    const dest = history.location.pathname;
    const r = routingMappings.find((x) => dest.indexOf(x.to) !== -1);
    return get(r, "menu", 0);
  }
  return 0;
};

export const getSelectedFooter = (deviceType) => {
  if (history.location) {
    const dest = history.location.pathname;
    const r = routingMappings.find((x) => dest.indexOf(x.to) !== -1);
    if (deviceType === "mobile") {
      return get(r, "footer", 0);
    } else {
      return get(r, "footerDesktop", 0);
    }
  }
};
