import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { push } from "connected-react-router";
import {
  Icon,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {
  ProfileContainer,
  TopProfileTitle,
  StyledList,
  StyledListItem,
} from "./Profile.styles";
import { initiateAccount } from "../actions";
import { useCountry } from "../../../hooks/useCountry";
import OpenChangeCountry from "../../login/components/OpenChangeCountry";

const Profile = () => {
  const dispatch = useDispatch();
  const { language, availableLanguages } = useCountry();
  const { deviceType } = useSelector((state) => state.translations);
  const openChangeCountryRef = useRef();
  const { notInitiated, localeChanged } = useSelector((state) => state.header);

  const showInfoForInitialize = () => {
    return notInitiated && localeChanged;
  };

  const onLinkClick = (dest) => {
    dispatch(push(dest));
  };

  const handleOpenChangeCountry = (event) => {
    if (openChangeCountryRef.current) {
      openChangeCountryRef.current.openChangeCountry(event);
    }
  };

  useEffect(() => {
    if (showInfoForInitialize()) {
      dispatch(initiateAccount());
    }
  }, []);

  return (
    <ProfileContainer>
      <TopProfileTitle>
        <FormattedMessage
          id="eo.profile-settings"
          defaultMessage="Profile settings"
        />
      </TopProfileTitle>
      {showInfoForInitialize() && (
        <FormattedMessage
          id="eo.profile-initiate"
          defaultMessage="Previously selected country and language are overwritten by company settings."
        />
      )}
      <StyledList>
        <StyledListItem onClick={() => onLinkClick("/profile/password")}>
          <ListItemText
            primary={
              <FormattedMessage id="eo.password" defaultMessage="Password" />
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={() => onLinkClick("/profile/password")}>
              <Icon color="primary">chevron_right</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </StyledListItem>
        <StyledListItem onClick={() => onLinkClick("/profile/delivery")}>
          <ListItemText
            primary={
              <FormattedMessage
                id="eo.delivery-address"
                defaultMessage="Delivery address"
              />
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={() => onLinkClick("/profile/delivery")}>
              <Icon color="primary">chevron_right</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </StyledListItem>
        {availableLanguages?.length > 1 && (
          <StyledListItem onClick={handleOpenChangeCountry}>
            <ListItemText
              primary={<FormattedMessage id="L8" defaultMessage="Language" />}
            />
            <ListItemSecondaryAction>
              <OpenChangeCountry
                disableCountryChange={true}
                ref={openChangeCountryRef}
                buttonLabel={language}
              />
            </ListItemSecondaryAction>
          </StyledListItem>
        )}
        {deviceType === "mobile" && (
          <StyledListItem onClick={() => onLinkClick("/profile/legal")}>
            <ListItemText
              primary={
                <FormattedMessage id="eo.legal" defaultMessage="Legal" />
              }
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => onLinkClick("/profile/legal")}>
                <Icon color="primary">chevron_right</Icon>
              </IconButton>
            </ListItemSecondaryAction>
          </StyledListItem>
        )}
      </StyledList>
    </ProfileContainer>
  );
};

export default Profile;
