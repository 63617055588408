import { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Checkbox } from "@material-ui/core";
import FormInput from "../../../components/Form/FormInput";

const Container = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Buttons = styled.div`
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media screen and (min-width: 768px) {
    justify-content: end;
  }
`;

const TermsLink = styled.a`
  color: #333;
`;

const TnC = styled.label`
  display: flex;
  margin: 16px 0;
  align-items: center;
  color: #333;
  font-size: 14px;
  cursor: pointer;
`;

const OrderButtons = ({
  cancelCallback,
  submitCallback,
  orderNumberCallback,
  termsUrl,
}) => {
  const [tncAccepted, setTncAccepted] = useState(false);

  return (
    <Container>
      <div>
        <FormInput
          name="orderNumber"
          label={
            <FormattedMessage
              id="eo.order-number"
              defaultMessage="Order Number"
            />
          }
          onChange={(e) => orderNumberCallback(e.target.value)}
          register={() => {}}
          maxLength={255}
        />
      </div>
      <div>
        {termsUrl && (
          <TnC>
            <Checkbox
              type="checkbox"
              color="primary"
              checked={tncAccepted}
              onClick={(e) => setTncAccepted(!tncAccepted)}
            />{" "}
            <span>
              <FormattedMessage
                id="eo.order-tnc-accept"
                defaultMessage="I accept the"
              />{" "}
              <TermsLink href={termsUrl} target="_blank">
                <FormattedMessage
                  id="eo.order-tnc-terms"
                  defaultMessage="delivery and payment terms"
                />
              </TermsLink>
            </span>
          </TnC>
        )}
      </div>
      <Buttons>
        <Button
          color="primary"
          onClick={cancelCallback}
          data-ect="remove-from-cart"
        >
          <FormattedMessage id="eo.cancel-order" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!!termsUrl && !tncAccepted}
          onClick={submitCallback}
          data-ect="purchase"
        >
          <FormattedMessage
            id="eo.submit-order"
            defaultMessage="Submit Order"
          />
        </Button>
      </Buttons>
    </Container>
  );
};

OrderButtons.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  submitCallback: PropTypes.func.isRequired,
  orderNumberCallback: PropTypes.func.isRequired,
};

export default OrderButtons;
