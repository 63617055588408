class Localization {
  static parseLocale(locale) {
    return locale.split("-");
  }

  static getLanguage() {
    let lang = window.localStorage.getItem("languageCode");

    if (!lang) {
      lang = Localization.retrieveLangFromLocale();
    }

    return lang;
  }

  static getBrowserLanguage() {
    return Localization.retrieveLangFromLocale();
  }

  static retrieveLangFromLocale() {
    const localeArray = Localization.parseLocale(window.navigator.language);

    return localeArray[0];
  }

  static getCountry() {
    let country = window.localStorage.getItem("countryCode");
    let localeArray;

    if (country === null) {
      localeArray = Localization.parseLocale(window.navigator.language);

      if (localeArray.length > 1) {
        country = localeArray[1];
      } else {
        country = localeArray[0];
      }
    }

    return country;
  }

  static setLanguage(languageCode) {
    window.localStorage.setItem("languageCode", languageCode.toLowerCase());
  }

  static setCountry(countryCode) {
    window.localStorage.setItem("countryCode", countryCode.toLowerCase());
  }
}

export default Localization;
