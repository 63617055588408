import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 30px 0;
  border-top: 2px dotted #929495;

  h2 {
    font-size: 14px;
    text-transform: uppercase;
    color: #999;
    margin-top: 0;
    margin-bottom: 18px;
    align-self: center;
  }
`;

export const DocumentsList = styled.div`
  display: flex;
  flex-wrap: wrap;

  a {
    display: flex;
    width: 50%;
    justify-content: center;
  }
`;

export const Document = styled(Paper)`
  && {
    width: 160px;
    margin: 5px 6px;
    float: left;
    height: 105px;
    margin: 5px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    padding: 5px;
    box-sizing: border-box;
    text-transform: capitalize;

    @media screen and (min-width: 768px) {
      width: 170px;
    }

    .material-icons {
      font-size: 42px;
      color: #e42313;
      margin: 10px 0;
    }
  }
`;
