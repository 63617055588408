import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goBack, replace } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import { filter, isEmpty, sampleSize } from "lodash";
import { Icon } from "@material-ui/core";

import { fetchRecommendedProducts } from "../../../home/actions";
import {
  fetchProductDetails,
  clearProductDetails,
  fetchCatalogue,
  changeCategoryOnProductDetails,
  requestFreeTraining,
  showProduct,
  changeCategory,
} from "../../actions";

import Categories from "../Categories";
import CategoriesDesktop from "../CategoriesDesktop";
import ProductVariants from "../ProductVariants/ProductVariants";
import UnloggedProductVariants from "../ProductVariants/UnloggedProductVariants";
import Documents from "../Documents/Documents";
import ProductDetailsLoaderDesktop from "./ProductDetailsLoaderDesktop";
import FreeTrainingDialog from "../ProductVariants/FreeTrainingDialog";
import RandomRecommendedProductForProductDetails from "./RandomRecommendedProductForProductDetails";
import { ImageWithPlaceholder } from "../../../../components/ImageWithPlaceholder/ImageWithPlaceholder";

import {
  Container,
  Breadcrumbs,
  BreadcrumbItem,
  Details,
  Description,
  Header,
  Photo,
  Text,
  Variants,
} from "./ProductDetailsDesktop.styles";

const ProductDetailsDesktop = (props) => {
  const dispatch = useDispatch();

  const { productCategoryId, productId } = props.match.params;

  const { product, currentCategory } = useSelector((state) => state.shop);
  const { logged } = useSelector((state) => state.login);
  const { recommendations } = useSelector((state) => state.home);
  const { firstTimeOrdered } = useSelector((state) => state.cart);
  const { catalogue, selectedVariant } = useSelector((state) => state.shop);
  const { deviceType } = useSelector((state) => state.translations);

  const [categories, setCategories] = useState([]);
  const [variantData, setVariantData] = useState();
  const [recommendationsList, setRecommendationsList] = useState([]);

  const requestFreeTrainingCallback = () => {
    dispatch(requestFreeTraining(variantData));
  };

  const detailsCallback = (destination) => {
    dispatch(showProduct(destination));
  };

  const handleGoBack = () => {
    dispatch(goBack());
  };

  const handleChangeCategory = (categoryId) => {
    dispatch(changeCategoryOnProductDetails(categoryId));
  };

  useEffect(() => {
    dispatch(fetchRecommendedProducts());

    return () => dispatch(clearProductDetails());
  }, []);

  useEffect(() => {
    dispatch(fetchCatalogue());
    dispatch(fetchProductDetails(productId));
  }, [productId]);

  useEffect(() => {
    const cats = catalogue
      .filter(
        ({ subCategories, products }) =>
          subCategories?.some(
            (subCategory) => subCategory.products?.length > 0
          ) || products?.length > 0
      )
      .map((category) => ({
        categoryId: category.categoryId,
        name: category.name,
        token: category.token,
      }));

    setCategories(cats);
  }, [catalogue]);

  useEffect(() => {
    let variant = undefined;

    if (product?.variants && product.variants[selectedVariant]) {
      variant = product.variants[selectedVariant];
    }

    setVariantData(variant);
  }, [product, selectedVariant]);

  useEffect(() => {
    const filtered = isEmpty(product)
      ? recommendations
      : filter(recommendations, function (item) {
          return item.groupCode !== product.groupCode;
        });

    setRecommendationsList(filtered.length ? sampleSize(filtered, 2) : []);
  }, [recommendations, product]);

  useEffect(() => {
    if (!productCategoryId && currentCategory) {
      dispatch(replace("/product/" + currentCategory + "/" + productId));
    }
    if (productCategoryId && currentCategory !== productCategoryId) {
      dispatch(changeCategory(Number(productCategoryId)));
    }
  }, [productCategoryId, currentCategory]);

  return (
    <Container>
      {deviceType === "desktop" ? (
        <CategoriesDesktop
          currentCategory={currentCategory}
          categories={categories}
          changeCallback={(category) =>
            dispatch(changeCategoryOnProductDetails(category))
          }
        />
      ) : (
        <Categories
          currentCategory={currentCategory}
          categories={categories}
          changeCallback={handleChangeCategory}
          deviceType={deviceType}
        />
      )}
      <Breadcrumbs>
        <BreadcrumbItem onClick={handleGoBack}>
          <Icon color="primary">chevron_left</Icon>
          <FormattedMessage id="eo.back" defaultMessage="Back" />
        </BreadcrumbItem>
      </Breadcrumbs>
      {isEmpty(product) ? (
        <ProductDetailsLoaderDesktop />
      ) : (
        <Description>
          <Details>
            <Header>
              <h2 data-ect="item-name">{product.name}</h2>
              <p>{product.lead}</p>
            </Header>

            <Text>
              {product.image && (
                <Photo elevation={0}>
                  <ImageWithPlaceholder
                    alt={product.name}
                    src={product.image.replace("small", "large")}
                  />
                </Photo>
              )}

              <ul>
                {product?.list.map((element, index) => (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: element }}
                  />
                ))}
              </ul>
              {deviceType === "desktop" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: product.description,
                  }}
                />
              )}
            </Text>
          </Details>
          <Variants>
            {logged && variantData && (
              <ProductVariants
                selectedIndex={selectedVariant}
                variants={product.variants}
                selected={variantData}
                productName={product.name}
              />
            )}
            {!logged && <UnloggedProductVariants productName={product.name} />}
            {deviceType !== "desktop" && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: product.description,
                }}
              />
            )}
            {product.attachments && (
              <Documents attachments={product.attachments} />
            )}
            <RandomRecommendedProductForProductDetails
              recommendations={recommendationsList}
              detailsCallback={detailsCallback}
            />
          </Variants>
        </Description>
      )}
      <FreeTrainingDialog
        isFirstTimeOrdered={firstTimeOrdered}
        requestFreeTrainingCallback={requestFreeTrainingCallback}
      />
    </Container>
  );
};

export default ProductDetailsDesktop;
