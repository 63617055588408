import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Container = styled.div`
  padding: 15px 0;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const Name = styled.h2`
  font-size: 16px;
  margin: 5px 0;
`;

const Sub = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 0;
`;

const TaDetails = ({ taDetails }) => (
  <Container>
    <Avatar
      src={taDetails?.photo}
      style={{ width: "57px", height: "57px", display: "inline-flex" }}
    >
      A
    </Avatar>
    <Name>{taDetails?.name}</Name>
    <Sub>
      <FormattedMessage
        id="eo.your-ta"
        defaultMessage="Your Technical Adviser"
      />
    </Sub>
  </Container>
);

TaDetails.propTypes = {
  taDetails: PropTypes.object.isRequired,
};

export default TaDetails;
