import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

const Title = styled.h2`
  color: #666666;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  margin: 5px 0;
`;

const EmptyOrdersInfo = ({ filtersCount, ordersLoader }) => (
  <div>
    <Title>
      {filtersCount > 0 && (
        <FormattedMessage
          id="eo.orders-no-matching-orders"
          defaultMessage="No history of orders matching your criteria"
        />
      )}
      {ordersLoader === false && filtersCount === 0 && (
        <FormattedMessage
          id="eo.orders-no-history"
          defaultMessage="No history of orders"
        />
      )}
    </Title>
  </div>
);

EmptyOrdersInfo.propTypes = {
  filtersCount: PropTypes.number.isRequired,
  ordersLoader: PropTypes.bool.isRequired,
};

export default EmptyOrdersInfo;
