import React from "react";
import styled from "styled-components";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import LoginLogo from "../../../login/components/LoginLogo";

const Logo = styled.div`
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 105px;
  width: 204px;
  text-align: center;
  cursor: pointer;
`;

const HeaderLogoDesktop = () => {
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    dispatch(push("/"));
  };

  return (
    <Logo onClick={handleLogoClick}>
      <LoginLogo />
    </Logo>
  );
};

export default HeaderLogoDesktop;
