import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  ProfileContainer,
  ProfileOuterContainer,
  ProfileTitle,
} from "./Profile.styles";
import { MenuItem, Select } from "@material-ui/core";
import EN from "../../login/images/EN.png";
import DE from "../../login/images/DE.png";
import ES from "../../login/images/ES.png";
import FR from "../../login/images/FR.png";
import IT from "../../login/images/IT.png";
import NL from "../../login/images/NL.png";
import PL from "../../login/images/PL.png";
import CZ from "../../login/images/CZ.png";
import { useCountry, useCountryDispatch } from "../../../hooks/useCountry";
import { useDevice } from "../../../hooks/useDevice";
import { ProfileBack } from "./ProfileBack";

const loadCountryIcon = (country) => {
  switch (country) {
    case "EN":
      return EN;
    case "DE":
      return DE;
    case "ES":
      return ES;
    case "FR":
      return FR;
    case "IT":
      return IT;
    case "NL":
      return NL;
    case "PL":
      return PL;
    case "CZ":
      return CZ;
    default:
      return EN;
  }
};

const ChangeLanguage = () => {
  const { country, language } = useCountry();
  const countryDispatch = useCountryDispatch();
  const { countries } = useSelector((state) => state.login);
  const { device } = useDevice();
  const [lang, setLang] = useState(language?.toUpperCase() || "EN");

  const isMobile = device === "mobile";

  const fetchAvailableLanguages = () => {
    let found = false;

    countries.forEach(function (item) {
      if (item.landCode === country) {
        found = item.availableLanguages;
        return false;
      }
    });

    return found || [{ value: "EN", label: "English" }];
  };

  const handleLanguageChange = (event) => {
    setLang(event.target.value);
    countryDispatch({
      type: "setLanguage",
      value: event.target.value,
    });
  };

  const items = fetchAvailableLanguages().map((value, index) => (
    <MenuItem
      className="login-LanguageSelect-item"
      key={index}
      value={value.value}
    >
      <span>
        <img src={loadCountryIcon(value.value)} alt={value.label} />
        {value.label}
      </span>
    </MenuItem>
  ));

  return (
    <ProfileOuterContainer>
      {!isMobile && <ProfileBack />}
      <ProfileContainer>
        {isMobile && <ProfileBack />}
        <ProfileTitle>
          <FormattedMessage
            id="eo.profile-settings"
            defaultMessage="Profile settings"
          />
        </ProfileTitle>
        <p>
          <FormattedMessage
            id="eo.your-country"
            defaultMessage="Your country is:"
          />
          &nbsp;
          {country}
        </p>
        <p>
          <FormattedMessage
            id="eo.your-initial-lang"
            defaultMessage="Your initial language is:"
          />{" "}
          {lang}
        </p>
        <p>
          <FormattedMessage
            id="eo.change-interface-lang"
            defaultMessage="You can change interface language to:"
          />
        </p>
        <Select
          className="login-LanguageSelect-list"
          fullWidth={true}
          value={lang}
          onChange={(event) => handleLanguageChange(event)}
        >
          {items}
        </Select>
      </ProfileContainer>
    </ProfileOuterContainer>
  );
};

export default ChangeLanguage;
