export const FETCH_HEADER_REQUEST = "header/FETCH_HEADER_REQUEST";
export const FETCH_HEADER_SUCCESS = "header/FETCH_HEADER_SUCCESS";
export const FETCH_HEADER_FAILURE = "header/FETCH_HEADER_FAILURE";

export const CHANGE_INVOICE_ADDRESS = "header/CHANGE_INVOICE_ADDRESS";
export const CHANGE_DELIVERY_ADDRESS = "header/CHANGE_DELIVERY_ADDRESS";

export const LOCALE_CHANGED = "header/LOCALE_CHANGED";
export const ACCOUNT_NOT_INITIATED = "header/ACCOUNT_NOT_INITIATED";
export const ACCOUNT_INITIATED = "header/ACCOUNT_INITIATED";
