import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  ProfileContainer,
  ProfileOuterContainer,
  ProfileTitle,
} from "./Profile.styles";
import AddressItem from "../../cart/components/Addresses/AddressItem";
import { useDevice } from "../../../hooks/useDevice";
import { ProfileBack } from "./ProfileBack";

const ChangeDeliveryAddress = () => {
  const { device } = useDevice();
  const addresses = useSelector((state) => ({
    delivery: state.header.deliveryAddress,
    invoice: state.header.invoiceAddress,
  }));

  const isMobile = device === "mobile";

  return (
    <ProfileOuterContainer>
      {!isMobile && <ProfileBack />}
      <ProfileContainer>
        {isMobile && <ProfileBack />}
        <ProfileTitle>
          <FormattedMessage
            id="eo.profile-settings"
            defaultMessage="Profile settings"
          />
        </ProfileTitle>
        {addresses.delivery && (
          <AddressItem
            address={addresses.delivery}
            titleId="currently-selected"
            title="Current delivery address"
          />
        )}
        <br />
        <br />
      </ProfileContainer>
    </ProfileOuterContainer>
  );
};

export default ChangeDeliveryAddress;
