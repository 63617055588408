import { IconButton, InputAdornment, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { history } from "../../../../router";

const StyledInput = styled(InputBase)`
  && {
    background-color: ${(props) => (props.value ? "#fff" : "#f2f2f2")};
    padding: 5px 5px 5px 8px;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid;
    border-color: #ccc;
    color: #666666;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 0px;
    margin: 0px;
    color: #333333;
  }
`;

export const MIN_SEARCH_LENGTH = 2;
const SearchInputMobile = () => {
  const search = history.location.search;
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get("query");
  const [searchInput, setSearchInput] = useState(query);
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleSubmit = () => {
    if (searchInput?.length >= MIN_SEARCH_LENGTH) {
      const encodedSearchInput = encodeURIComponent(searchInput);
      dispatch(push(`/search?query=${encodedSearchInput}&page=1`));
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    setSearchInput(searchParams.get("query"));
  }, [query]);

  return (
    <StyledInput
      type={"text"}
      value={searchInput || ""}
      onChange={handleChange}
      placeholder={`${intl.formatMessage({
        id: "eo.search",
        defaultMessage: "Search",
      })}...`}
      onKeyDown={handleKeyDown}
      endAdornment={
        <InputAdornment position="end">
          <StyledIconButton onClick={handleSubmit}>
            <SearchIcon />
          </StyledIconButton>
        </InputAdornment>
      }
    />
  );
};

export default SearchInputMobile;
