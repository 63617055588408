import styled from "styled-components";

import { Dialog, IconButton, Button } from "@material-ui/core";

export const Container = styled.div`
  text-align: center;
  margin: 34px 0;
`;

export const Title = styled.h2`
  margin-bottom: 34px;
  padding-bottom: 0;
  color: #000;
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  text-transform: uppercase;
`;

export const ThankYouTitle = styled.h2`
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
`;

export const ThankYouMessage = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    position: absolute;
    top: 15%;
  }
`;

export const StyledDialogContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 40px;
`;

export const StyledIconButton = styled(IconButton)`
  && {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const StyledDialogButton = styled(Button)`
  width: 77px;
`;
