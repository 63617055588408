import * as t from "./actionTypes";
import * as l from "../login/actionTypes";

const initialState = {
  contactLoader: true,
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCH_TA_SUCCESS:
      // always return new object (state is immutable)
      return Object.assign({}, state, {
        ...state,
        ...action.data,
        contactLoader: false,
      });
    case t.FETCH_TA_FAILURE:
      return Object.assign({}, state, {
        contactLoader: false,
      });
    case l.AUTH_LOGOUT:
    case l.SYNC_AUTH_USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default contactReducer;
