const LogoIcon = (props) => (
  <svg
    width="301px"
    height="88px"
    viewBox="0 0 301 88"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns-xlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <polygon
        id="path-1"
        points="0.0002 0.3801 72.7242 0.3801 72.7242 62 0.0002 62"
      />
    </defs>
    <g
      id="brand-/-interflon-logo-2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-15" transform="translate(12.000000, 12.000000)">
        <g id="Group-3" transform="translate(0.000000, 0.620000)">
          <g id="Clip-2"></g>
          <path
            d="M41.9142,0.3801 C30.1542,0.3801 19.9402,6.9731 14.7482,16.6601 C14.6782,16.6591 14.6102,16.6491 14.5402,16.6491 C6.5092,16.6491 0.0002,23.1601 0.0002,31.1901 C0.0002,39.2201 6.5092,45.7311 14.5402,45.7311 C14.6102,45.7311 14.6782,45.7211 14.7482,45.7201 C19.9402,55.4071 30.1542,62.0001 41.9142,62.0001 C58.9302,62.0001 72.7242,48.2051 72.7242,31.1901 C72.7242,14.1741 58.9302,0.3801 41.9142,0.3801 M14.5402,38.8591 C10.3112,38.8591 6.8822,35.4301 6.8822,31.2011 C6.8822,26.9711 10.3112,23.5431 14.5402,23.5431 C18.7692,23.5431 22.1982,26.9711 22.1982,31.2011 C22.1982,35.4301 18.7692,38.8591 14.5402,38.8591 M41.9142,55.1271 C33.3582,55.1271 25.8722,50.6251 21.6392,43.8731 C26.0772,41.3841 29.0812,36.6411 29.0812,31.1901 C29.0812,25.7391 26.0772,20.9961 21.6392,18.5061 C25.8722,11.7551 33.3582,7.2531 41.9142,7.2531 C55.1342,7.2531 65.8512,17.9701 65.8512,31.1901 C65.8512,44.4101 55.1342,55.1271 41.9142,55.1271"
            id="Fill-1"
            fill={props?.fill ? props.fill : "#E1271D"}
            mask="url(#mask-2)"
          />
        </g>
        <polygon
          id="Fill-4"
          fill={props?.fill ? props.fill : "#E1271D"}
          points="83.033 45.244 90.122 45.244 90.122 17.971 83.033 17.971"
        />
        <polygon
          id="Fill-5"
          fill={props?.fill ? props.fill : "#E1271D"}
          points="92.9118 17.9713 100.0018 17.9713 112.9868 34.6463 113.0588 34.6463 113.0588 17.9713 120.1488 17.9713 120.1488 45.2443 113.0588 45.2443 100.0738 28.5333 100.0018 28.5333 100.0018 45.2443 92.9118 45.2443"
        />
        <polygon
          id="Fill-6"
          fill={props?.fill ? props.fill : "#E1271D"}
          points="135.2302 45.2443 128.1402 45.2443 128.1402 23.9763 122.2802 23.9763 122.2802 17.9713 141.0892 17.9713 141.0892 23.9763 135.2302 23.9763"
        />
        <polygon
          id="Fill-7"
          fill={props?.fill ? props.fill : "#E1271D"}
          points="149.7927 23.9757 149.7927 28.5327 157.7867 28.5327 157.7867 34.5377 149.7927 34.5377 149.7927 39.2397 158.2207 39.2397 158.2207 45.2437 142.7027 45.2437 142.7027 17.9707 158.2207 17.9707 158.2207 23.9757"
        />
        <path
          d="M183.4665,45.2443 L174.6405,45.2443 L167.9485,34.7543 L167.8765,34.7543 L167.8765,45.2443 L160.7865,45.2443 L160.7865,17.9713 L171.3855,17.9713 C176.7745,17.9713 180.8625,20.5393 180.8625,26.3623 C180.8625,30.1243 178.7635,33.3793 174.8935,34.0673 L183.4665,45.2443 Z M167.8765,30.1973 L168.5645,30.1973 C170.8785,30.1973 173.4825,29.7623 173.4825,26.7973 C173.4825,23.8303 170.8785,23.3973 168.5645,23.3973 L167.8765,23.3973 L167.8765,30.1973 Z"
          id="Fill-8"
          fill={props?.fill ? props.fill : "#E1271D"}
        />
        <polygon
          id="Fill-10"
          fill={props?.fill ? props.fill : "#E1271D"}
          points="191.5676 23.9757 191.5676 28.5327 199.3436 28.5327 199.3436 34.5377 191.5676 34.5377 191.5676 45.2437 184.4776 45.2437 184.4776 17.9707 200.1396 17.9707 200.1396 23.9757"
        />
        <polygon
          id="Fill-11"
          fill={props?.fill ? props.fill : "#E1271D"}
          points="209.9245 39.2394 218.4245 39.2394 218.4245 45.2444 202.8345 45.2444 202.8345 17.9714 209.9245 17.9714"
        />
        <polygon
          id="Fill-12"
          fill={props?.fill ? props.fill : "#E1271D"}
          points="269.7264 17.9089 269.7264 34.5839 269.6544 34.5839 256.6694 17.9089 249.5794 17.9089 249.5794 45.1819 256.6694 45.1819 256.6694 28.4709 256.7414 28.4709 269.7264 45.1819 276.8154 45.1819 276.8154 17.9089"
        />
        <path
          d="M232.8351,17.0665 C224.8041,17.0665 218.2941,23.5765 218.2941,31.6075 C218.2941,39.6375 224.8041,46.1475 232.8351,46.1475 C240.8661,46.1475 247.3761,39.6375 247.3761,31.6075 C247.3761,23.5765 240.8661,17.0665 232.8351,17.0665 M232.8351,39.2755 C228.6061,39.2755 225.1771,35.8475 225.1771,31.6175 C225.1771,27.3885 228.6061,23.9595 232.8351,23.9595 C237.0641,23.9595 240.4931,27.3885 240.4931,31.6175 C240.4931,35.8475 237.0641,39.2755 232.8351,39.2755"
          id="Fill-13"
          fill={props?.fill ? props.fill : "#E1271D"}
        />
      </g>
    </g>
  </svg>
);

export default LogoIcon;
