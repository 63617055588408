const LogoSmallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="25"
    viewBox="0 0 29 25"
    {...props}
  >
    <path
      d="M16.895.396c-4.6 0-8.594 2.593-10.624 6.403l-.081-.004c-3.141 0-5.687 2.56-5.687 5.719 0 3.158 2.546 5.719 5.687 5.719l.08-.005c2.031 3.81 6.026 6.404 10.625 6.404 6.654 0 12.048-5.426 12.048-12.118C28.943 5.821 23.55.396 16.895.396M6.19 15.53a3.003 3.003 0 01-2.995-3.012A3.003 3.003 0 016.19 9.506a3.003 3.003 0 012.994 3.012A3.003 3.003 0 016.19 15.53m10.705 6.399c-3.346 0-6.274-1.771-7.93-4.427a5.72 5.72 0 002.91-4.988 5.72 5.72 0 00-2.91-4.989C10.622 4.87 13.55 3.1 16.896 3.1c5.17 0 9.36 4.215 9.36 9.415 0 5.2-4.19 9.415-9.36 9.415"
      fill={props?.fill ? props.fill : "#E1271D"}
      fillRule="evenodd"
    />
  </svg>
);

export default LogoSmallIcon;
