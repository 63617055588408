import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { MenuItem } from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import Auth from "../../../core/Auth";
import { logout, redirectToLogin } from "../../actions";

import {
  UserBar,
  UserIcon,
  StyledButton,
  StyledTitle,
  StyledContainer,
} from "./UserBarDesktop.styles";

const UserBarDesktop = () => {
  const dispatch = useDispatch();
  const contactPerson = useSelector((state) => state.header);
  const { logged } = useSelector((state) => state.login);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const handleLoginClick = () => dispatch(redirectToLogin());

  const handlePopover = (event) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const logOut = () => {
    Auth.logOut();
    handleRequestClose();
    dispatch(logout());
  };

  const profile = () => {
    handleRequestClose();
    dispatch(push("/profile"));
  };

  return (
    <StyledContainer>
      {logged ? (
        <UserBar onClick={handlePopover}>
          <UserIcon>account_circle</UserIcon>

          <StyledTitle
            user={contactPerson.userName}
            company={contactPerson.clientName}
          />

          <IconButton>
            <Icon style={{ color: "#fff" }}>expand_more</Icon>
          </IconButton>

          <Popover
            id={Boolean(open) ? "user-menu-popover" : undefined}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            onClose={handlePopover}
          >
            <MenuList>
              <MenuItem onClick={profile}>
                <FormattedMessage id="eo.profile" defaultMessage="Profile" />
              </MenuItem>
              <MenuItem onClick={logOut}>
                <FormattedMessage id="eo.logout" defaultMessage="Logout" />
              </MenuItem>
            </MenuList>
          </Popover>
        </UserBar>
      ) : (
        <UserBar>
          <StyledButton variant="contained" onClick={handleLoginClick}>
            <FormattedMessage id="eo.log-in" defaultMessage="Log in" />
          </StyledButton>
        </UserBar>
      )}
    </StyledContainer>
  );
};

export default UserBarDesktop;
