import React, { useState } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import { withTheme } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import unset from "lodash/unset";
import values from "lodash/values";

const Container = styled.div`
  padding: 10px 15px;
  position: relative;
`;

const Link = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;
`);

const Header = styled.h2`
  font-size: 18px;
  color: #666;
  text-align: center;
  margin: 0;
  font-weight: 500;
  float: left;
  line-height: 45px;
`;

const SubHeader = styled.h3`
  font-size: 14px;
  margin: 0;
`;

const Row = styled.div`
  &:after {
    content: "";
    display: block;
    clear: both;
  }
`;

const CloseIconButton = styled(IconButton)`
  && {
    float: right;
  }
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  margin: 8px 0;
`;

const Input = styled.input`
  border: 2px solid #d1d1d1;
  font-size: 14px;
  padding: 5px 10px;
  margin: 5px 0;
  width: 200px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-around;
`;

const OrdersFilter = ({
  filterValues,
  filtersCount,
  ordersAuthors,
  applyCallback,
  clearCallback,
}) => {
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState({
    when: "all",
    product: null,
    who: null,
  });

  const handleShow = () => {
    setShow(!show);
  };

  const changeWhen = (value) => {
    let filtersSet = filters;
    filtersSet.when = value;

    setFilters(filtersSet);
  };

  const changeWho = (value) => {
    let filtersSet = filters;

    if (filtersSet.who === null) {
      filtersSet.who = {};
    }

    if (includes(filtersSet.who, value)) {
      unset(filtersSet.who, value);
    } else {
      filtersSet.who[value] = value;
    }

    if (values(filtersSet.who).length === 0) {
      filtersSet.who = null;
    }

    setFilters(filtersSet);
  };

  const changeProductName = (value) => {
    let filtersSet = filters;
    if (value) {
      filtersSet.product = value;
    } else {
      filtersSet.product = null;
    }

    setFilters(filtersSet);
  };

  const applyFilters = () => {
    applyCallback(filters);
    handleShow();
  };

  const clearFilters = () => {
    setFilters({
      when: null,
      product: null,
      who: null,
    });
    clearCallback();
    handleShow();
  };

  const getFilterValue = (filterName) => {
    if (filterValues !== undefined) {
      return filterValues.hasOwnProperty(filterName)
        ? filterValues[filterName]
        : "";
    }

    return "";
  };

  const authors = ordersAuthors.map((author, index) => (
    <li key={index}>
      <label>
        <input
          defaultChecked={includes(getFilterValue("who"), author.authorId)}
          type="checkbox"
          name="author[]"
          value={author.authorId}
        />{" "}
        {author.name}
      </label>
    </li>
  ));

  return (
    <Container>
      {!show && (
        <Link onClick={handleShow}>
          <FormattedMessage id="eo.filters" defaultMessage="Filters" />{" "}
          {filtersCount > 0 && <span>({filtersCount})</span>}
        </Link>
      )}
      {show && (
        <div>
          <Row>
            <Header>
              <FormattedMessage
                id="eo.show-orders"
                defaultMessage="Show orders"
              />
            </Header>
            <CloseIconButton onClick={handleShow}>
              <Icon>close</Icon>
            </CloseIconButton>
          </Row>
          <SubHeader>
            <FormattedMessage id="eo.by-who" defaultMessage="Create by" />
          </SubHeader>
          <List onChange={(event) => changeWho(event.target.value)}>
            {authors}
          </List>
          <SubHeader>
            <FormattedMessage id="eo.when" defaultMessage="When" />
          </SubHeader>
          <List onChange={(event) => changeWhen(event.target.value)}>
            <li>
              <label>
                <input
                  defaultChecked={getFilterValue("when") === "6"}
                  type="radio"
                  name="dates"
                  value="6"
                />{" "}
                <FormattedMessage
                  id="eo.filter-when6"
                  defaultMessage="last 6 months"
                />
              </label>
            </li>
            <li>
              <label>
                <input
                  defaultChecked={getFilterValue("when") === "12"}
                  type="radio"
                  name="dates"
                  value="12"
                />{" "}
                <FormattedMessage
                  id="eo.filter-when12"
                  defaultMessage="last year (12 months)"
                />
              </label>
            </li>
            <li>
              <label>
                <input
                  defaultChecked={getFilterValue("when") === "all"}
                  type="radio"
                  name="dates"
                  value="all"
                />{" "}
                <FormattedMessage
                  id="eo.filter-whenAll"
                  defaultMessage="ever since"
                />
              </label>
            </li>
          </List>
          <SubHeader>
            <FormattedMessage
              id="eo.contains-product"
              defaultMessage="Contains product"
            />
          </SubHeader>
          <Input
            type="text"
            name="product"
            onChange={(event) => changeProductName(event.target.value)}
            defaultValue={getFilterValue("product")}
          />
          <Buttons>
            {filtersCount > 0 && (
              <Button
                color="primary"
                className="home-Filter-clearButton"
                onClick={clearFilters}
              >
                <FormattedMessage
                  id="eo.clear-filters"
                  defaultMessage="Clear filters"
                />
              </Button>
            )}

            <Button
              color="primary"
              variant="contained"
              className="reorder-button"
              onClick={applyFilters}
            >
              <FormattedMessage
                id="eo.apply-filters"
                defaultMessage="Apply filters"
              />
            </Button>
          </Buttons>
        </div>
      )}
    </Container>
  );
};

OrdersFilter.propTypes = {
  applyCallback: PropTypes.func.isRequired,
  // filterValues: PropTypes.string.isRequired,
  filtersCount: PropTypes.number.isRequired,
  ordersAuthors: PropTypes.array.isRequired,
  clearCallback: PropTypes.func.isRequired,
};

export default OrdersFilter;
