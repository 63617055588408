import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import RecommendationsMobile from "./RecommendationsMobile";
import RecommendationsDesktop from "./RecommendationsDesktop";

const Container = styled.div`
  border-top: 2px dotted #929495;
  margin-top: 15px;
  padding-top: 10px;
`;

const Title = styled.h2`
  font-size: 16px;
  margin: 0 15px;
`;

const Recommendations = ({ products, detailsCallback, deviceType }) => {
  return (
    <Container>
      <Title>
        <FormattedMessage
          id="eo.recommendations"
          defaultMessage="Companies in your industry have also bought:"
        />
      </Title>
      {deviceType === "mobile" ? (
        <RecommendationsMobile
          products={products}
          detailsCallback={detailsCallback}
        />
      ) : (
        <RecommendationsDesktop
          products={products}
          detailsCallback={detailsCallback}
        />
      )}
    </Container>
  );
};

Recommendations.propTypes = {
  products: PropTypes.array.isRequired,
  detailsCallback: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
};

export default Recommendations;
