import * as t from "./actionTypes";
import * as l from "../login/actionTypes";
import axios from "axios";
import config from "../../config";
import Auth from "../core/Auth";
import { push } from "connected-react-router";
import { handleRequestError } from "../core/ErrorHandler";

export const handleClosePasswordChangedPopup = () => {
  Auth.logOut();

  return (dispatch) => {
    dispatch({ type: l.CLOSE_SUCCESS_PASSWORD_SET });
    dispatch({ type: l.AUTH_LOGOUT });
    dispatch(push("/login"));
  };
};

export const fetchContactPerson = () => {
  return (dispatch) => {
    dispatch({ type: t.FETCH_HEADER_REQUEST });

    axios
      .get(config.apiUrl + "eo/profile", Auth.getAuthorizationRequestConfig())
      .then((response) => {
        dispatch({
          type: t.FETCH_HEADER_SUCCESS,
          data: response.data,
        });

        if (response.data.initiated === false) {
          dispatch({ type: t.ACCOUNT_NOT_INITIATED });
          dispatch(push("/profile"));
        }
      })
      .catch((error) =>
        handleRequestError(dispatch, error, t.FETCH_HEADER_FAILURE)
      );
  };
};

export const changeInvoiceAddress = (address) => {
  return (dispatch) => {
    dispatch({ type: t.CHANGE_INVOICE_ADDRESS, data: address });
  };
};

export const changeDeliveryAddress = (address) => {
  return (dispatch) => {
    dispatch({ type: t.CHANGE_DELIVERY_ADDRESS, data: address });
  };
};

export const redirectToLogin = () => {
  return (dispatch) => {
    dispatch(push("/login"));
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: "login/AUTH_LOGOUT" });
    dispatch(push("/"));
  };
};

export const handleChangePasswordSubmit = (values) => {
  const formData = new FormData();
  formData.append("password", values.password);
  formData.append("oldPassword", values.currentPassword);
  return axios.post(
    `${config.apiUrl}eo/profile/password`,
    formData,
    Auth.getAuthorizationRequestConfig()
  );
};

export const initiateAccount = () => {
  return (dispatch) => {
    dispatch({ type: t.ACCOUNT_INITIATED });
  };
};
