import * as t from "./actionTypes";
import * as l from "../login/actionTypes";

const initialState = {
  catalogue: [],
  currentCategory: 0,
  product: {},
  selectedVariant: 0,
  fetchPriceRequest: false,
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCH_CATALOGUE_SUCCESS:
      return Object.assign({}, state, {
        catalogue: [...action.data.catalogue],
        currentCategory: state.currentCategory
          ? state.currentCategory
          : action.data.catalogue[0].categoryId,
      });
    case t.CHANGE_CATEGORY:
      return Object.assign({}, state, {
        currentCategory: action.data,
      });
    case t.FETCH_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        product: action.data.details,
      });
    case t.CHANGE_VARIANT:
      return Object.assign({}, state, {
        selectedVariant: action.data,
      });
    case t.FETCH_PRICE_SUCCESS:
      return Object.assign({}, state, {
        product: {
          ...state.product,
          variants: state.product.variants.map(function (variant) {
            if (action.data.variantId === variant.variantId) {
              variant.price = action.data.price;
              variant.quantity = action.data.quantity;
              variant.nextSegment = action.data.nextSegment;
            }

            return variant;
          }),
        },
        fetchPriceRequest: false,
      });
    case t.FETCH_PRICE_FAILURE:
      return Object.assign({}, state, {
        fetchPriceRequest: false,
      });
    case t.FETCH_PRICE_REQUEST:
      return Object.assign({}, state, {
        fetchPriceRequest: true,
      });
    case l.AUTH_LOGOUT:
      return initialState;
    case t.CLEAR_PRODUCT_DETAILS:
      return Object.assign({}, state, {
        product: {},
      });
    default:
      return state;
  }
};

export default shopReducer;
