import React from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { history } from "../../../router";
import { useUniqueFilters } from "../service/filters.service";

import FiltersDropdown from "./FiltersDropdown";

const FiltersMobile = ({ products, filterPer, filteredProducts }) => {
  const dispatch = useDispatch();
  const uniqueFilters = useUniqueFilters(
    filteredProducts ?? products,
    filterPer
  );
  const searchParams = new URLSearchParams(history.location.search);

  const filters = searchParams.getAll(filterPer);

  const handleChange = (id) => {
    if (filters.includes(String(id))) {
      searchParams.delete(filterPer, id);
    } else {
      searchParams.append(filterPer, id);
    }
    if (history.location.pathname === "/search") {
      searchParams.set("page", "1");
    }
    const newUrl = history.location.pathname + "?" + searchParams.toString();
    dispatch(push(newUrl));
  };

  return (
    <FiltersDropdown
      items={uniqueFilters}
      handleChange={handleChange}
      filters={filters}
    />
  );
};

export default FiltersMobile;
