import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import loginLogo from "../../app/images/loginLogo.png";

import {
  Container,
  Logo,
  StyledImg,
  User,
  UserName,
  UserIcon,
  ArrowIcon,
} from "./Header.styles";

const Header = ({ userName, profileCallback }) => {
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    dispatch(push("/"));
  };

  return (
    <Container>
      <Logo onClick={handleLogoClick}>
        <StyledImg src={loginLogo} alt="Interflon" />
      </Logo>
      <User onClick={profileCallback}>
        <UserIcon>account_circle</UserIcon>
        <UserName>{userName}</UserName>
        <ArrowIcon>keyboard_arrow_right</ArrowIcon>
      </User>
    </Container>
  );
};

export default Header;
