import OpenChangeCountry from "./OpenChangeCountry";
import LoginLogo from "./LoginLogo";
import React from "react";
import { LoginContainer, LoginInnerContainer } from "./Login.styles";
import styled from "styled-components";
import UnloggedTiles from "./UnloggedTiles";

const ContentWrapper = styled.div`
  max-width: 290px;
  margin: 30px auto 0 auto;
  background: #fff;
  padding: 30px 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
`;

const CountryPickerContainer = styled.div`
  position: absolute;
  right: 0;
  padding-top: 5px;
`;

const HelpLayout = ({ children }) => {
  return (
    <LoginContainer>
      <LoginInnerContainer>
        <CountryPickerContainer>
          <OpenChangeCountry />
        </CountryPickerContainer>
        <LoginLogo />

        <ContentWrapper>{children}</ContentWrapper>
        <UnloggedTiles />
      </LoginInnerContainer>
    </LoginContainer>
  );
};

export default HelpLayout;
