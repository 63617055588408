import styled from "styled-components";
import bgImg from "../../app/images/bg.jpg";

const LoginContainer = styled.div`
  text-align: center;
  background: #fff url(${bgImg}) top center no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
`;

const LoginInnerContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 1170px) {
    width: 1170px;
    margin: 0 auto;
    position: relative;
    top: 0;

    .App-logo {
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 0;
    }

    h2 {
      margin-bottom: 30px;
      font-size: 20px;
    }
  }
`;

export { LoginContainer, LoginInnerContainer };
