import { NAME } from "./constants";

export const getAll = (state) => state[NAME];

export const getCategories = (state) => {
  return state[NAME].catalogue.map((category) => {
    return {
      categoryId: category.categoryId,
      name: category.name,
      token: category.token,
    };
  });
};

export const getCurrentCategory = (state) => state[NAME].currentCategory;

export const getProductDetails = (state) => state[NAME].product;

export const getVariant = (state) => {
  let variant = undefined;
  const selectedIndex = state[NAME].selectedVariant;
  const product = state[NAME].product;
  if (product?.variants && product.variants[selectedIndex]) {
    variant = product.variants[selectedIndex];
  }

  return variant;
};

export const getSelectedVariantIndex = (state) => state[NAME].selectedVariant;

export const getRequestResult = (state) => state[NAME].requestResult;

export const getRequestStatus = (state) => state[NAME].requestStatus;

export const getCategory = (state) =>
  state[NAME].catalogue.find((category) => {
    return category.categoryId === state[NAME].currentCategory;
  });

export const getFetchPriceRequestState = (state) => {
  return state[NAME].fetchPriceRequest;
};
