import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import Form from "../../../components/Form/Form";
import FormInput from "../../../components/Form/FormInput";
import { handleSubmit as handleSubmitForm } from "../actions";

const schema = yup
  .object({
    login: yup
      .string()
      .email(
        <FormattedMessage
          id="eo.invalid-email"
          defaultMessage="Invalid email address"
        />
      )
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
    password: yup
      .string()
      .max(
        72,
        <FormattedMessage
          id="eo.password-too-long"
          defaultMessage="Password too long"
        />
      )
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
  })
  .required();

const LoginForm = () => {
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const defaultValues = {
    login: "",
    password: "",
  };
  const onSubmit = (data) =>
    handleSubmitForm(data).then((result) => dispatch(result));

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Form
      title={<FormattedMessage id="eo.sign" defaultMessage="Sign in" />}
      onSubmitForm={onSubmit}
      defaultValues={defaultValues}
      schema={schema}
    >
      <FormInput
        name="login"
        label={<FormattedMessage id="eo.email" defaultMessage="E-MAIL" />}
      />
      <FormInput
        name="password"
        type={passwordVisible ? "text" : "password"}
        label={<FormattedMessage id="eo.password" defaultMessage="PASSWORD" />}
        icon={
          <IconButton size={"small"} onClick={togglePassword}>
            {passwordVisible ? (
              <Visibility fontSize="inherit" />
            ) : (
              <VisibilityOff fontSize="inherit" />
            )}
          </IconButton>
        }
      />
    </Form>
  );
};

export default LoginForm;
