import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  fetchBasketCheckAvailability,
  switchToEditMode,
  closeProductUnavailable,
  closePlaceOrderErrors,
  redirectToContact,
  redirectToSignin,
} from "../actions";
import ProductUnavailable from "./ProductUnavailable";
import UnloggedCart from "./UnloggedCart";
import {
  redirectToLogin,
  changeInvoiceAddress,
  changeDeliveryAddress,
} from "../../header/actions";
import CartView from "./CartView";
import PlaceOrderErrors from "./PlaceOrderErrors";

const Container = styled.div`
  padding-bottom: 30px;

  @media screen and (min-width: 1170px) {
    width: 1130px;
    margin: 0 auto;
  }
`;

const Cart = () => {
  const dispatch = useDispatch();

  const {
    items,
    orderDetails,
    errors,
    orderErrors,
    cartLoader,
    containsRiskyProducts,
  } = useSelector((state) => state.cart);
  const { currency } = useSelector((state) => state.header);
  const { logged } = useSelector((state) => state.login);
  const addresses = useSelector((state) => ({
    delivery: state.header.deliveryAddress,
    invoice: state.header.invoiceAddress,
  }));

  const [showCartLoader, setShowCartLoader] = useState(false);

  const handleChangeInvoiceAddress = (addresses) => {
    dispatch(changeInvoiceAddress(addresses));
  };

  const handleChangeDeliveryAddress = (addresses) => {
    dispatch(changeDeliveryAddress(addresses));
  };

  useEffect(() => {
    if (logged) {
      dispatch(fetchBasketCheckAvailability());
    }

    return () => window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setShowCartLoader(cartLoader > 0 && items.length > 0);
  }, [cartLoader, items]);

  return (
    <Container>
      {logged ? (
        <div>
          <CartView
            addresses={addresses}
            currency={currency}
            isFull={items.length > 0}
            list={items}
            handleEditClick={() => dispatch(switchToEditMode())}
            orderDetails={orderDetails}
            showCartLoader={showCartLoader}
            changeInvoiceAddressCallback={handleChangeInvoiceAddress}
            changeDeliveryAddressCallback={handleChangeDeliveryAddress}
            containsRiskyProducts={!!containsRiskyProducts}
          />

          {errors.length > 0 && (
            <ProductUnavailable
              errors={errors}
              closeCallback={() => dispatch(closeProductUnavailable())}
            />
          )}
          {orderErrors.length > 0 && (
            <PlaceOrderErrors
              errors={orderErrors}
              closeCallback={() => dispatch(closePlaceOrderErrors())}
            />
          )}
        </div>
      ) : (
        <UnloggedCart
          loginCallback={() => dispatch(redirectToLogin())}
          requestCallback={() => dispatch(redirectToContact())}
          signinCallback={() => dispatch(redirectToSignin())}
        />
      )}
    </Container>
  );
};

export default Cart;
