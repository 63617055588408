import React from "react";
import { Card, CardHeader, CardContent, Typography } from "@material-ui/core";
import ProductVariants from "./ProductVariants";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledCard = styled(Card)`
  && {
    border-radius: 4px;
    border: 2px solid #d1d1d1;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
`;

const Title = styled(CardHeader)`
  && {
    font-weight: 800;
    color: #333;
    cursor: pointer;
  }
`;

const StyledCardContent = styled(CardContent)`
  && {
    border-top: 2px dotted #929495;
    &:last-child {
      padding: 0;
    }
  }
`;

const Product = ({
  product,
  priceCallback,
  addCallback,
  currency,
  redirectCallback,
  cartState,
}) => (
  <li>
    <StyledCard>
      <Title
        title={
          <Typography
            component="p"
            style={{
              display: "flex",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
            }}
            data-ect="item-name"
          >
            {product.name}
          </Typography>
        }
        onClick={() => redirectCallback("/product/" + product.code)}
      />
      <StyledCardContent>
        {product.variants && (
          <ProductVariants
            variants={product.variants}
            productName={product.name}
            priceCallback={priceCallback}
            addCallback={addCallback}
            currency={currency}
            cartState={cartState}
          />
        )}
      </StyledCardContent>
    </StyledCard>
  </li>
);

Product.propTypes = {
  product: PropTypes.object.isRequired,
  priceCallback: PropTypes.func.isRequired,
  addCallback: PropTypes.func.isRequired,
  currency: PropTypes.string,
  redirectCallback: PropTypes.func.isRequired,
  cartState: PropTypes.object,
};

export default Product;
