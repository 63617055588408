import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #f5f5f5;
  color: #666;
  padding: 5px 5px;
`;
