import React from "react";
import styled from "styled-components";
import LogoIcon from "../../../components/Icons/Logo/Logo";
import { useCountry } from "../../../hooks/useCountry";
import { FormattedMessage } from "react-intl";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;
  background: #1a1a1a;
  width: 100%;
  box-sizing: border-box;
`;

const StyledInnerContainer = styled.div`
  width: 100%;
`;

const StyledCopyright = styled.div`
  color: #999;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;

  img {
    margin-bottom: 30px;
  }
`;

const StyledAnchor = styled.a`
  color: white;
`;

const StyledLinksContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const FooterComponent = () => {
  const { termsUrl, privacyUrl, legalDisclosure, cookies } = useCountry();
  const items = [
    {
      url: termsUrl,
      id: "eo.terms-and-conditions",
      defaultMessage: "Terms and conditions",
    },
    {
      url: privacyUrl,
      id: "eo.privacy-statement",
      defaultMessage: "Privacy statement",
    },
    {
      url: legalDisclosure,
      id: "eo.legal-disclosure",
      defaultMessage: "Legal disclosure",
    },
    {
      url: cookies,
      id: "eo.cookies",
      defaultMessage: "Cookies",
    },
  ];

  const currentYear = new Date().getFullYear();
  const links = items
    .filter((item) => item.url)
    .map((item) => (
      <StyledAnchor key={item.id} href={item.url} target="_blank">
        <FormattedMessage id={item.id} defaultMessage={item.defaultMessage} />
      </StyledAnchor>
    ));

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledLinksContainer>{links}</StyledLinksContainer>
        <StyledCopyright>
          <LogoIcon fill="#ccc" width="175px" />
          <div>&copy; {currentYear} Interflon</div>
        </StyledCopyright>
      </StyledInnerContainer>
    </StyledContainer>
  );
};
