import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useCountry } from "../../hooks/useCountry";

const StyledContainer = styled.h3`
  color: #333;
  font-size: 14px;
  text-align: left;
`;

const StyledAnchor = styled.a`
  color: #333;
`;

const PrivacyStatement = () => {
  const intl = useIntl();
  const { privacyUrl } = useCountry();
  return (
    <StyledContainer>
      <FormattedMessage
        id="eo.agreement-to-contact"
        defaultMessage="By clicking {button} you agree to our {privacyStatement}"
        values={{
          button: intl
            .formatMessage({
              id: "eo.contact-me",
              defaultMessage: "Contact me",
            })
            .toUpperCase(),
          privacyStatement: (
            <StyledAnchor href={privacyUrl} target="_blank">
              <FormattedMessage
                id="eo.privacy-statement"
                defaultMessage="Privacy Statement"
              />
            </StyledAnchor>
          ),
        }}
      />
    </StyledContainer>
  );
};

export default PrivacyStatement;
