import React from "react";
import PropTypes from "prop-types";
import {
  Icon,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

const RecommendationsMobile = ({ products, detailsCallback }) => {
  return (
    <List>
      {products.map((product, index) => (
        <ListItem
          style={{ border: "0", borderBottom: "2px solid #e7e7e7" }}
          onClick={() => detailsCallback("/product/" + product.groupCode)}
          key={index}
        >
          <ListItemAvatar>
            {product.image ? (
              <Avatar style={{ borderRadius: "0" }} src={product.image} />
            ) : (
              <Avatar>I</Avatar>
            )}
          </ListItemAvatar>
          <ListItemText primary={product.name} secondary={product.lead} />
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => detailsCallback("/product/" + product.groupCode)}
            >
              <Icon>chevron_right</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

RecommendationsMobile.propTypes = {
  products: PropTypes.array.isRequired,
  detailsCallback: PropTypes.func.isRequired,
};

export default RecommendationsMobile;
