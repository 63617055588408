import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

const Container = withTheme(styled.div`
  text-align: center;
  padding: 10px 0;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  border-top: 2px solid #d1d1d1;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
`);

const MoreVariants = ({ onClick }) => (
  <Container onClick={onClick}>
    <FormattedMessage
      id="eo.othervolumes"
      defaultMessage="check other volumes"
    />
  </Container>
);

MoreVariants.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MoreVariants;
