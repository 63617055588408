import styled from "styled-components";
import {
  Avatar,
  Icon,
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "../../../../../node_modules/@material-ui/core/index";
import { placeholderImg } from "../../../../components/ImageWithPlaceholder/ImageWithPlaceholder";
import { StyledListItem } from "../Shop/Shop";
import { FormattedMessage } from "react-intl";

const CounterContainer = styled.div`
  font-size: 10px;
  color: #1a1a1a;
`;

export const Subcategory = ({ subcategory, handleChangeSubcategory }) => {
  return (
    <StyledListItem
      onClick={() => handleChangeSubcategory(subcategory.categoryId)}
    >
      <ListItemAvatar>
        {subcategory.image ? (
          <Avatar
            style={{ borderRadius: "0" }}
            imgProps={{ style: { objectFit: "scale-down" } }}
            src={subcategory.image}
          >
            <Avatar src={placeholderImg} />
          </Avatar>
        ) : (
          <Avatar src={placeholderImg} />
        )}
      </ListItemAvatar>
      <div>
        <ListItemText primary={subcategory.name} secondary={subcategory.lead} />
        <CounterContainer>
          {`${subcategory.products.length} `}
          <FormattedMessage
            id="eo.subcat-products"
            defaultMessage="Product(s)"
          />
        </CounterContainer>
      </div>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          onClick={() => handleChangeSubcategory(subcategory.categoryId)}
        >
          <Icon>chevron_right</Icon>
        </IconButton>
      </ListItemSecondaryAction>
    </StyledListItem>
  );
};
