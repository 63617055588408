import React, { useEffect, useState } from "react";
import { getSelectedFooter } from "../selectors";
import { useSelector } from "react-redux";
import { FooterComponent } from "./FooterComponent";

const MenuFooter = () => {
  const { location } = useSelector((state) => state.router);
  const { deviceType } = useSelector((state) => state.translations);
  const [isMenu, setIsMenu] = useState(getSelectedFooter(deviceType));

  useEffect(() => {
    setIsMenu(getSelectedFooter(deviceType));
  }, [location, deviceType]);

  return isMenu === 1 && <FooterComponent />;
};

export default MenuFooter;
