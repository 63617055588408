class DeviceSelector {

    static isMobile() {
        return window.matchMedia('(max-width: 767px)').matches;
    }

    static isTablet() {
        return window.matchMedia('(min-width: 768px) and (max-width: 1169px)').matches;
    }

    static isDesktop() {
        return window.matchMedia('(min-width: 1170px)').matches;
    }

    static currentDevice() {
        let result = '';
        if(DeviceSelector.isMobile()){
            result = 'mobile';
        }

        if(DeviceSelector.isTablet()) {
            result = 'tablet';
        }

        if(DeviceSelector.isDesktop()) {
            result = 'desktop';
        }

        return result;
    }
}

export default DeviceSelector;