import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";
import { ListItem, ListItemText } from "@material-ui/core";

const StyledListItem = styled(ListItem)`
  && {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Name = styled.span`
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`;

const Prices = styled.span`
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: flex;
`;

const Price = styled.span`
  flex: 1;
  min-width: 80px;

  @media screen and (min-width: 768px) {
    flex: 2;
  }
`;

const Quantity = styled.span`
  flex: 1;
  text-align: right;
`;
const Total = styled.span`
  font-weight: 600;
  text-align: right;
  flex: 2;

  @media screen and (min-width: 768px) {
    flex: 1;
  }
`;

const Item = ({ item, currency }) => (
  <StyledListItem className="cart-Item">
    <ListItemText
      primary={<Name data-ect="name">{item.name}</Name>}
      secondary={
        <Prices>
          <Price data-ect="variant">
            {item.capacity}&nbsp;
            {item.unit.translation_code ? (
              <FormattedMessage
                id={item.unit.translation_code}
                defaultMessage={item.unit.name}
              />
            ) : (
              item.unit.name
            )}
            &nbsp;
            {item.package.translation_code && (
              <FormattedMessage
                id={item.package.translation_code}
                defaultMessage={item.package.name}
              />
            )}
          </Price>
          <Quantity data-ect="quantity">
            {item.amount > 1 && (
              <FormattedMessage
                id="eo.packs"
                defaultMessage="{amount} packs"
                values={{ amount: item.amount }}
              />
            )}
            <strong> x {item.quantity}</strong>
          </Quantity>
          <Total data-ect="price">
            {currency}&nbsp;
            <FormattedNumber
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              value={item.price * item.amount * item.quantity}
            />
          </Total>
        </Prices>
      }
    />
  </StyledListItem>
);

Item.propTypes = {
  item: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

export default Item;
