import React from "react";
import { Icon } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { push } from "connected-react-router";

const Wrapper = styled.div`
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  text-align: center;
  font-size: 12px;
`;

const Tiles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: #fff;
  width: 30%;
  border: 2px solid #d1d1d1;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  color: #333;
  font-weight: 900;
  text-transform: uppercase;
  padding: 5px;
  height: 60px;
  cursor: pointer;

  @media screen and (min-width: 1170px) {
    width: 250px;
  }
`;

const UnloggedTiles = () => {
  const dispatch = useDispatch();
  const handleClick = (destination) => {
    dispatch(push(destination));
  };

  return (
    <Wrapper>
      <Tiles>
        <Tile onClick={() => handleClick("/shop")}>
          <Icon color="primary">store</Icon>
          <FormattedMessage
            id="eo.explore-store"
            defaultMessage="Explore our&nbsp;store"
          />
        </Tile>
        <Tile onClick={() => handleClick("/contact/request")}>
          <Icon color="primary">call</Icon>
          <FormattedMessage
            id="eo.request-contact"
            defaultMessage="Request contact"
          />
        </Tile>
      </Tiles>
    </Wrapper>
  );
};

export default UnloggedTiles;
