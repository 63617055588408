import * as t from './actionTypes';
import * as c from '../cart/actionTypes';
import * as l from '../login/actionTypes';

const initialState = {
    orders: [],
    ordersCount: 0,
    products: [],
    recommendations:[],
    filters:{},
    showInfoNewOrderRegistered: false,
    newOrderContainsRiskyProducts: false,
    showNewOrderExpanded: false,
    ordersLoader: true,
    productsLoader: true,
    ordersAuthors: []
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case t.FETCH_ORDERS_SUCCESS:
            // always return new object (state is immutable)
            // return [...state, ...action.data];
            return Object.assign({}, state, {
                orders: [
                    ...state.orders,
                    ...action.data.orders
                ],
                ordersCount: action.data.total,
                ordersLoader: false
            });
        case t.FETCH_FILTERED_ORDERS_SUCCESS:
            // always return new object (state is immutable)
            // return [...state, ...action.data];
            return Object.assign({}, state, {
                orders: [
                    ...action.data.orders
                ],
                ordersCount: action.data.total,
                ordersLoader: false
            });
        case t.FETCH_ORDERS_AUTHORS_SUCCESS:
            return Object.assign({}, state, {
                ordersAuthors: [
                    ...action.data.authors
                ]
            });
        case t.CLEAR_ORDERS_HISTORY:
            return Object.assign({}, state, {
                orders: [],
                ordersCount: 0
            });
        case t.FETCH_PRODUCTS_SUCCESS:
            // always return new object (state is immutable)
            // return [...state, ...action.data];
            return Object.assign({}, state, {
                products: [
                    ...action.data.products
                ],
                productsLoader: false
            });
        case t.FETCH_RECOMMENDATIONS_SUCCESS:
            return Object.assign({}, state, {
                recommendations: [
                    ...action.data.recommendations
                ]
            });
        case t.FETCH_RECOMMENDATIONS_FAILURE:
            return Object.assign({}, state, {
                recommendations: [
                    ...state.orders
                ]
            });
        case t.FETCH_ORDERS_FAILURE:
            return Object.assign({}, state, {
                orders: [
                    ...state.orders
                ],
                ordersLoader: false
            });
        case t.FETCH_PRODUCTS_FAILURE:
            return Object.assign({}, state, {
                products: [
                    ...state.products
                ],
                productsLoader: false
            });
        case t.FETCH_PRICE_SUCCESS:
            return Object.assign({}, state, {
                products: state.products.map(function (product) {
                    let variantIndex = product.variants.findIndex(function (element) {
                        return action.data.productId === element.variantId;
                    });

                    if (variantIndex > -1) {
                        product.variants[variantIndex].price = action.data.price;
                        product.variants[variantIndex].quantity = action.data.quantity;
                    }

                    return product;
                })
            });
        case t.APPLY_FILTERS:
            return Object.assign({}, state, {
               filters:{
                   ...action.data
               }
            });
        case t.CLEAR_FILTERS:
            return Object.assign({}, state, {
                filters:{}
            });
        case c.PLACE_ORDER_SUCCESS:
            return Object.assign({}, state, {
                showInfoNewOrderRegistered: true,
                newOrderContainsRiskyProducts: action.data.containsRiskyProducts
            });
        case t.CLOSE_NEW_ORDER_REGISTERED_INFO:
            return Object.assign({}, state, {
                showInfoNewOrderRegistered: false,
            });
        case t.SHOW_NEW_ORDER_EXPANDED:
            return Object.assign({}, state, {
                showNewOrderExpanded: action.data,
            });
        case l.AUTH_LOGOUT:
        case l.SYNC_AUTH_USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default homeReducer;