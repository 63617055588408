import React, { useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import forEach from "lodash/forEach";
import values from "lodash/values";
import { Input } from "@material-ui/core";

const Container = styled.div`
  border-bottom: 2px dotted #929495;
  padding: 15px 0;
`;

const Filters = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;

const Item = styled.div`
  flex: ${({ isLong }) => (isLong ? 2 : 1)};
`;

const Header = styled.h2`
  font-size: 18px;
  margin: 0;
  line-height: 45px;
  color: #333;
  font-weight: 800;
`;

const SubHeader = styled.h3`
  font-size: 14px;
  margin: 0;
`;

const StyledInput = styled(Input)`
  border: 1px solid #d1d1d1;
  background: #f9f9f9;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0;
  height: 25px;
  padding: 16px 10px;
`;

const StyledSelect = styled(Select)`
  border: 1px solid #d1d1d1;
  background: #f9f9f9;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0;
`;

const StyledMenuItem = styled(MenuItem)`
  min-height: auto !important;
`;

const OrdersFilterDesktop = ({
  filterValues,
  ordersAuthors,
  applyCallback,
}) => {
  const [filters, setFilters] = useState({
    when: "all",
    product: null,
    who: null,
  });

  const changeWho = (selectedValues) => {
    let filtersSet = filters;

    filtersSet.who = {};

    forEach(selectedValues, function (value) {
      filtersSet.who[value] = value;
    });

    if (values(filtersSet.who).length === 0) {
      filtersSet.who = null;
    }

    setFilters(filtersSet);
    applyFilters();
  };

  const changeWhen = (value) => {
    let filtersSet = filters;
    filtersSet.when = value;
    setFilters(filtersSet);
    applyFilters();
  };

  const changeProductName = (value) => {
    let filtersSet = filters;
    if (value) {
      filtersSet.product = value;
    } else {
      filtersSet.product = null;
    }

    setFilters(filtersSet);
    applyFilters();
  };

  const applyFilters = () => {
    applyCallback(filters);
  };

  const getFilterValue = (filterName) => {
    if (filterValues !== undefined) {
      return filterValues.hasOwnProperty(filterName)
        ? filterValues[filterName]
        : "";
    }

    return "";
  };

  const authors = ordersAuthors.map((author, index) => (
    <StyledMenuItem key={index} value={author.authorId}>
      {author.name}
    </StyledMenuItem>
  ));

  return (
    <Container>
      <div className="home-Filter-row">
        <Header>
          <FormattedMessage id="eo.filters" defaultMessage="Filters" />
        </Header>
      </div>
      <Filters>
        <Item>
          <SubHeader>
            <FormattedMessage
              id="eo.contains-product"
              defaultMessage="Contains product"
            />
          </SubHeader>
          <StyledInput
            variant="outlined"
            name="product"
            onChange={(event) => changeProductName(event.target.value)}
            defaultValue={getFilterValue("product")}
            disableUnderline
          />
        </Item>
        <Item>
          <SubHeader>
            <FormattedMessage id="eo.when" defaultMessage="When" />
          </SubHeader>
          <StyledSelect
            onChange={(event) => changeWhen(event.target.value)}
            value={getFilterValue("when")}
            disableUnderline
            MenuProps={{ MenuListProps: { disablePadding: true } }}
            SelectDisplayProps={{ style: { paddingLeft: "15px" } }}
          >
            <MenuItem value="6" className="home-Filter-MenuItem">
              <FormattedMessage
                id="eo.filter-when6"
                defaultMessage="last 6 months"
              />
            </MenuItem>
            <MenuItem value="12" className="home-Filter-MenuItem">
              <FormattedMessage
                id="eo.filter-when12"
                defaultMessage="last year (12 months)"
              />
            </MenuItem>
            <MenuItem value="all" className="home-Filter-MenuItem">
              <FormattedMessage
                id="eo.filter-whenAll"
                defaultMessage="ever since"
              />
            </MenuItem>
          </StyledSelect>
        </Item>
        <Item isLong>
          <SubHeader>
            <FormattedMessage id="eo.by-who" defaultMessage="Create by" />
          </SubHeader>
          <StyledSelect
            multiple
            value={values(getFilterValue("who"))}
            onChange={(event) => changeWho(event.target.value)}
            disableUnderline
            MenuProps={{ MenuListProps: { disablePadding: true } }}
            SelectDisplayProps={{ style: { paddingLeft: "15px" } }}
          >
            {authors}
          </StyledSelect>
        </Item>
      </Filters>
    </Container>
  );
};

export default OrdersFilterDesktop;
