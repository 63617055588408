import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { withTheme } from "@material-ui/core/styles";

const StyledFieldGroup = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  border-collapse: separate;
  width: 100%;
  background: #fff;
  border: 2px solid
    ${({ active, theme }) => (active ? theme.palette.primary.main : "#d1d1d1")};
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
`);

const Input = styled.textarea`
  border: none;
  color: #000;
  font-size: 14px;
  outline: none;
  height: 7em;
  width: 100%;
  resize: none;
`;

const Label = styled.label`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  padding: 10px;
`;

const Error = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 12px;
  margin-top: 5px;
  flex: 0 1 100%;
  padding-left: 40%;
`);

const FormTextarea = ({ register, name, label, errors, ...rest }) => {
  const [active, setActive] = useState(false);

  return (
    <StyledFieldGroup active={active}>
      <Label>
        <Input
          {...register(name)}
          {...rest}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        />
        {errors && <Error>{errors?.message}</Error>}
      </Label>
    </StyledFieldGroup>
  );
};

export default FormTextarea;
