import styled from "styled-components";
import { ImageWithPlaceholder } from "../../../../components/ImageWithPlaceholder/ImageWithPlaceholder";
import { Details, Photo, ShopItem } from "../Shop/ShopDesktop";
import { FormattedMessage } from "react-intl";

const CounterContainer = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  font-size: 10px;
  color: #1a1a1a;
`;

export const SubcategoryDesktop = ({
  subcategory,
  handleChangeSubcategory,
}) => {
  return (
    <ShopItem onClick={() => handleChangeSubcategory(subcategory.categoryId)}>
      <Photo>
        <ImageWithPlaceholder
          src={subcategory.image?.replace("tiny", "small")}
          alt={subcategory.name}
        />
      </Photo>
      <Details>
        <h2>{subcategory.name}</h2>
        <p>{subcategory.lead}</p>
        <CounterContainer>
          {`${subcategory.products.length} `}
          <FormattedMessage
            id="eo.subcat-products"
            defaultMessage="Product(s)"
          />
        </CounterContainer>
      </Details>
    </ShopItem>
  );
};
