import React from "react";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  padding: 10px;
`;

const NotFound = () => (
  <Container>
    <h1>404 Not found</h1>
    <p>Sorry, that page doesn’t exist!</p>
  </Container>
);

export default NotFound;
