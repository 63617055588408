import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

export const Wrapper = styled.div`
  margin-top: -20px;
`;

export const AppWrapper = styled.div`
  padding: 15px 10px;
`;

export const ImageWrapper = styled(Paper)`
  && {
    width: 100px;
    height: 100px;
    text-align: center;
    padding: 5px;
    float: left;

    img {
      max-width: 90px;
      max-height: 90px;
    }
  }
`;

export const Title = styled.div`
  float: right;
  width: 60%;

  h2 {
    font-size: 18px;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 8px;
  }

  p {
    font-size: 13px;
    color: #999;
    margin-top: 0;
    padding-top: 0;
  }
`;

export const DotsWrapper = styled.div`
  margin: 20px 0;
  font-size: 13px;

  ul {
    padding-left: 30px;
  }
`;

export const ReadMore = withTheme(styled.div`
  position: absolute;
  font-size: 14px;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 40px 0 0;
  color: ${({ theme }) => theme.palette.primary.main};
  text-transform: uppercase;
  font-weight: bold;
  background-image: linear-gradient(to bottom, transparent, white);
`);

export const Description = styled.div`
  max-height: ${({ isFull }) => (isFull ? "none" : "90px")};
  position: relative;
  overflow: hidden;
  font-size: 13px;
  padding-bottom: 20px;
`;
