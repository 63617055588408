import { Container } from "./CustomMessage.styles";

const CustomMessage = ({ htmlMessage }) => {
  return (
    <Container>
      <div
        dangerouslySetInnerHTML={{
          __html: htmlMessage,
        }}
      />
    </Container>
  );
};

export default CustomMessage;
