import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import * as selectors from "./selectors";

export { default as Home } from "./components/Home";
export { default as HomeDesktop } from "./components/HomeDesktop";

const home = { actions, constants, reducer, selectors };
export default home;
