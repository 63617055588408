import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  padding: 0 16px;
`;

const Title = styled.h2`
  color: #999999;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.5em;
  text-align: left;
  text-transform: uppercase;
`;

const Details = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5em;
  text-align: left;
`;

const AddressItem = ({ title, titleId, address }) => {
  return (
    <Container>
      <Title>
        <FormattedMessage id={"eo." + titleId} defaultMessage={title} />
      </Title>
      <Details>
        <Item>{address.name}</Item>
        {address.recipient && <Item>{address.recipient}</Item>}
        <Item>{address.address}</Item>
        <Item>{address.city}</Item>
        <Item>{address.zipcode}</Item>
      </Details>
    </Container>
  );
};

AddressItem.propTypes = {
  address: PropTypes.object.isRequired,
  titleId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddressItem;
