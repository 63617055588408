import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Items = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 15px 10px 15px;
`;

const Item = styled.li`
  display: flex;
  font-size: 14px;
  color: #333;
`;

const Name = styled.span`
  font-weight: 600;
  flex: 4;
`;

const Quantity = styled.span`
  text-align: center;
  font-size: 14px;
  color: #999;
  flex: 1;
`;

const Package = styled.span`
  margin-left: 10px;
  flex: 1;
  text-align: right;
`;

const OrderItems = ({ items }) => {
  return (
    <Items>
      {items.map(({ code, name, quantity, package: packageItem }) => (
        <Item key={code}>
          <Name>{name}</Name>
          <Quantity>x {quantity}</Quantity>
          <Package>{packageItem}</Package>
        </Item>
      ))}
    </Items>
  );
};

OrderItems.propTypes = {
  items: PropTypes.array.isRequired,
};

export default OrderItems;
