import React from "react";
import { FormattedMessage } from "react-intl";
import UnloggedContactUs from "./UnloggedContactUs";
import { Container, Title } from "./UnloggedContact.styles";

const UnloggedContact = () => {
  return (
    <Container>
      <Title>
        <FormattedMessage id="eo.contact-us" defaultMessage="Contact us" />
      </Title>
      <UnloggedContactUs />
    </Container>
  );
};

export default UnloggedContact;
