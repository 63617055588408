import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Icon, IconButton, Menu, MenuItem } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import Auth from "../../../core/Auth";

const TopBarProfile = styled.div`
  text-align: center;
  margin-top: -3px;
`;

const Label = withTheme(styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
`);

const TopBarMenu = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const logOut = () => {
    Auth.logOut();
    dispatch({ type: "login/AUTH_LOGOUT" });
    dispatch(push("/"));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const goProfile = () => {
    dispatch(push("/profile"));
    setOpen(false);
  };

  return (
    <TopBarProfile>
      <IconButton
        style={{ paddingBottom: "0px", paddingTop: "3px" }}
        onClick={handleClick}
      >
        <Icon style={{ fontSize: "30px" }}>account_circle</Icon>
      </IconButton>
      <Menu
        id="mobile-user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
      >
        <MenuItem onClick={goProfile}>
          <FormattedMessage id="eo.profile" defaultMessage="Profile" />
        </MenuItem>
        <MenuItem onClick={logOut}>
          <FormattedMessage id="eo.logout" defaultMessage="Logout" />
        </MenuItem>
      </Menu>
      <Label onClick={handleClick}>
        <FormattedMessage id="eo.my-profile" defaultMessage="My profile" />
      </Label>
    </TopBarProfile>
  );
};

export default TopBarMenu;
