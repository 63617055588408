import * as t from './actionTypes';
import * as l from '../login/actionTypes';

const emptyBasketDetails = {
    totalWithoutVat: 0,
    deliveryCost: 0,
    vatPercent: 0,
    vatWithTransport: 0,
    vatAmount: 0,
    totalWithVatAndTransport: 0
};

const initialState = {
    items: [],
    errors: [],
    orderErrors: [],
    orderDetails: emptyBasketDetails,
    cartLoader: 0,
    changeCartRequest: null,
    cartPopoverWillBeOpen: false,
    cartPopoverIsOpen: false,
    firstTimeOrdered: false,
    containsRiskyProducts: false,
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case t.FETCH_BASKET_REQUEST:
        case t.CLEAR_BASKET_REQUEST:
        case t.PLACE_ORDER_REQUEST:
            return Object.assign({}, state, {
                cartLoader: state.cartLoader + 1
            });

        case t.DELETE_ITEM_FROM_BASKET_SUCCESS:
            return Object.assign({}, state, {
                items: state.items.filter(
                    (val, i) => val.cartItemId !== action.data.cartItemId
                )
            });

        case t.PLACE_ORDER_SUCCESS:
        case t.CLEAR_BASKET_SUCCESS:
            return Object.assign({}, state, {
                items: [],
                orderDetails: emptyBasketDetails,
                cartLoader: Math.max(state.cartLoader - 1, 0)
            });

        case t.FETCH_BASKET_SUCCESS:
            return Object.assign({}, state, {
                cartPopoverIsOpen: state.cartPopoverWillBeOpen ? true : state.cartPopoverIsOpen,
                cartPopoverWillBeOpen: false,
                items: action.data.cartItems.map(function (item) {
                    return {
                        code: item.code,
                        quantity: item.quantity,
                        price: item.unitPrice,
                        amount: item.amount,
                        capacity: item.capacity,
                        unit: item.unit,
                        package: item.package,
                        name: item.name,
                        currency: item.currency,
                        cartItemId: item.cartItemId,
                        availableToBuy: item.availableToBuy,
                    };
                }),
                containsRiskyProducts: action.data.containsRiskyProducts,
            });

        case t.FETCH_ORDER_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                orderDetails: action.data,
                cartLoader: Math.max(state.cartLoader - 1, 0)
            });

        case t.FETCH_ORDER_DETAILS_FAILURE:
        case t.CLEAR_BASKET_FAILURE:
            return Object.assign({}, state, {
                cartLoader: Math.max(state.cartLoader - 1, 0),
            });

        case t.PLACE_ORDER_FAILURE:
            return Object.assign({}, state, {
                cartLoader: Math.max(state.cartLoader - 1, 0),
                orderErrors: [
                    ...state.orderErrors,
                    action.error
                ]
            });

        case t.REORDER_SUCCESS:
            return Object.assign({}, state, {
                errors: [...state.errors, ...action.data.unavailable || []]
            });

        case t.PRODUCT_UNAVAILABLE:
            return Object.assign({}, state, {
                errors: [...state.errors, action.data]
            });
        case t.PRODUCT_UNAVAILABLE_CLOSE:
            return Object.assign({}, state, {
                errors: []
            });

        case t.PLACE_ORDER_ERRORS_CLOSE:
            return Object.assign({}, state, {
                orderErrors: []
            });

        case l.AUTH_LOGOUT:
        case l.SYNC_AUTH_USER_LOGOUT:
            return initialState;

        case t.UPDATE_ITEM_IN_BASKET_REQUEST:
            return Object.assign({}, state, {
                changeCartRequest: action.variant,
                cartPopoverWillBeOpen: true
            });

        case t.UPDATE_ITEM_IN_EDIT_BASKET_REQUEST:
            return Object.assign({}, state, {
                changeCartRequest: action.variant,
            });

        case t.ADD_ITEM_TO_BASKET_REQUEST:
            return Object.assign({}, state, {
                changeCartRequest: action.variant,
                cartPopoverWillBeOpen: true,
            });

        case t.ADD_ITEM_IN_BASKET_SUCCESS:
            return Object.assign({}, state, {
                changeCartRequest: null,
                firstTimeOrdered: action.data
            });

        case t.UPDATE_ITEM_IN_BASKET_FAILURE:
        case t.UPDATE_ITEM_IN_BASKET_SUCCESS:
            return Object.assign({}, state, {
                changeCartRequest: null,
            });

        case t.CART_POPOVER_CLOSE:
            return Object.assign({}, state, {
                cartPopoverIsOpen: false
            });
        case t.CLOSE_FREE_TRAININGS_DIALOG:
            return Object.assign({}, state, {
                firstTimeOrdered: false
            });

        default:
            return state;
    }
};

export default cartReducer;

