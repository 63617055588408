import { useContext } from "react";
import {
  CountryContext,
  CountryDispatchContext,
} from "../contexts/countryContext";

export const useCountry = () => {
  return useContext(CountryContext);
};

export const useCountryDispatch = () => {
  return useContext(CountryDispatchContext);
};
