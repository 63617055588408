import * as t from "./actionTypes";
import axios from "axios";
import config from "../../config";
import { translationsCodes } from "./constants";
import { updateIntl } from "react-intl-redux";

export const fetchTranslations = (lang) => {
  const formData = new FormData();

  formData.append("language_code", lang);
  for (let i = 0; i < translationsCodes.length; i++) {
    formData.append("translation_codes[]", translationsCodes[i]);
  }

  return (dispatch) => {
    dispatch({ type: t.FETCH_TRANSLATION_REQUEST });

    axios
      .post(config.apiUrl + "eo/open/translations", formData)
      .then((response) => {
        dispatch(updateIntl(response.data));
        dispatch({ type: t.FETCH_TRANSLATION_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: t.FETCH_TRANSLATION_FAILURE,
          data: error.response.statusText,
        });
      });
  };
};

export const changeDeviceType = (deviceType) => {
  return (dispatch) => {
    dispatch({ type: t.RESOLUTION_CHANGE, data: deviceType });
  };
};
