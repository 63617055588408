import { withTheme } from "@material-ui/core/styles";
import { useState } from "react";
import styled from "styled-components";

const StyledFieldGroup = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  border-collapse: separate;
  width: 100%;
  background: #fff;
  border: 2px solid
    ${({ theme, error }) => (error ? theme.palette.primary.main : "#d1d1d1")};
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
`);

const Text = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #999;
  width: auto;
  flex-basis: 45%;
  min-width: 1px;
  align-self: center;
  padding: 0;
  margin: 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-basis: 55%;
  min-width: 1px;
`;

const Input = styled.input`
  border: none;
  color: #000;
  font-size: 14px;
  outline: none;
  flex-basis: 100%;
  min-width: 1px;
  padding: 0;
  margin: 0;
`;

const Label = styled.label`
  display: flex;
  padding: 10px 5px 10px 10px;
  cursor: pointer;
  gap: 5px;
`;

const Error = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 14px;
  height: 24px;
  padding-left: 8px;
`);

const FormInputNew = ({
  register,
  name,
  label,
  errors,
  type,
  icon,
  required,
  ...rest
}) => {
  const [active, setActive] = useState(false);

  return (
    <div>
      <StyledFieldGroup active={active} error={!!errors}>
        <Label>
          <Text>
            {label}
            {required && "*"}:
          </Text>
          <InputContainer>
            <Input
              {...register(name)}
              type={type}
              {...rest}
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
            />
            {icon}
          </InputContainer>
        </Label>
      </StyledFieldGroup>
      <Error>{errors?.message}</Error>
    </div>
  );
};

export default FormInputNew;
