import React from "react";
import VariantDetails from "./VariantDetails";
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import VariantSelect from "./VariantsSelect";

const StyledPaper = styled(Paper)`
  && {
    border: 2px solid #d1d1d1;
    border-radius: 4px;

    @media screen and (min-width: 768px) {
      width: 450px;
      margin: 0 auto 30px;
    }

    @media screen and (min-width: 1170px) {
      width: 100%;
    }
  }
`;

const ProductVariants = ({
  productName,
  variants,
  selected,
  selectedIndex,
}) => {
  return (
    <StyledPaper elevation={0}>
      <VariantSelect selectedIndex={selectedIndex} variants={variants} />
      {selected && (
        <VariantDetails variant={selected} productName={productName} />
      )}
    </StyledPaper>
  );
};

ProductVariants.propTypes = {
  selected: PropTypes.object.isRequired,
  variants: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
};

export default ProductVariants;
