import Auth from "../../core/Auth";
import axios from "axios";
import config from "../../../config";

export const fetchProductsByQuery = (query) => {
  const queryString = new URLSearchParams({ query: query }).toString();
  const isLoggedIn = Auth.isLoggedIn();
  const searchApiUrl = isLoggedIn
    ? `eo/shop/search?${queryString}`
    : `eo/open/shop/search?${queryString}`;
  const url = config.apiUrl + searchApiUrl;
  const fetchConfig = isLoggedIn
    ? Auth.getAuthorizationRequestConfig()
    : Auth.getAuthorizationRequestUnloggedConfig();

  return axios.get(url, fetchConfig);
};
