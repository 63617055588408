export const getCategoryColor = (category) => {
  switch (category.toLowerCase()) {
    case "oils":
      return "#e90381";
    case "greases":
      return "#7ac043";
    case "cleaners":
      return "#00a6da";
    case "coatings":
      return "#fbb033";
    case "food grade":
      return "#b6b8ba";
    case "food gradebs":
      return "#b6b8ba";
    case "hardware":
      return "#000001";
    case "miscellaneous":
      return "#747474";
    default:
      return "";
  }
};
