import * as t from "./actionTypes";
import * as l from "../login/actionTypes";

const initialState = {
  headerLoader: true,
  localeChanged: false,
  notInitiated: false,
  termsUrl: "",
  countryOptions: {
    banner: "",
    header: "",
    cart_middle: "",
    cart_bullet_1: "",
    cart_bullet_2: "",
    cart_bullet_3: "",
    cart_bullet_4: "",
    cart_bullet_1_risky: "",
    cart_bullet_2_risky: "",
    cart_bullet_3_risky: "",
    cart_bullet_4_risky: "",
  },
  userId: "",
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCH_HEADER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        ...action.data,
        headerLoader: false,
      });
    case t.CHANGE_INVOICE_ADDRESS:
      return Object.assign({}, state, {
        invoiceAddress: action.data,
      });
    case t.CHANGE_DELIVERY_ADDRESS:
      return Object.assign({}, state, {
        deliveryAddress: action.data,
      });
    case t.FETCH_HEADER_FAILURE:
      return Object.assign({}, state, {
        headerLoader: false,
      });
    case l.AUTH_LOGOUT:
      return { headerLoader: true };
    case t.LOCALE_CHANGED:
      return Object.assign({}, state, {
        localeChanged: true,
      });
    case t.ACCOUNT_NOT_INITIATED:
      return Object.assign({}, state, {
        notInitiated: true,
      });
    case t.ACCOUNT_INITIATED:
      return Object.assign({}, state, {
        notInitiated: false,
        localeChanged: false,
      });
    default:
      return state;
  }
};

export default headerReducer;
