import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Icon, SvgIcon, ListItemIcon, ListItemText } from "@material-ui/core";
import { OrdersIcon } from "../../../core/icons/OrdersIcon";

import {
  AppWrapper,
  StyledDetails,
  StyledTitle,
  StyledList,
  StyledItem,
  StyledLink,
  cartIconStyle,
} from "./EmptyCard.styles";

const EmptyCart = () => {
  const dispatch = useDispatch();

  const reloadToMyOrders = () => {
    dispatch(push("/orders"));
  };
  const reloadToShop = () => {
    dispatch(push("/shop"));
  };

  return (
    <AppWrapper>
      <StyledDetails>
        <Icon style={cartIconStyle}>shopping_cart</Icon>
        <StyledTitle>
          <FormattedMessage
            id="eo.cart-empty"
            defaultMessage="Your cart is empty"
          />
        </StyledTitle>
      </StyledDetails>
      <StyledList>
        <StyledItem onClick={reloadToMyOrders}>
          <ListItemIcon>
            <SvgIcon color="primary">
              <path {...OrdersIcon} />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <StyledLink>
                <FormattedMessage
                  id="eo.empty-cart-previous-orders"
                  defaultMessage="Use one of previous orders"
                />
              </StyledLink>
            }
          />
        </StyledItem>
        <StyledItem onClick={reloadToShop}>
          <ListItemIcon>
            <Icon color="primary">store</Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <StyledLink>
                <FormattedMessage
                  id="eo.empty-cart-explore-shop"
                  defaultMessage="Explore interflon shop"
                />
              </StyledLink>
            }
          />
        </StyledItem>
      </StyledList>
    </AppWrapper>
  );
};

export default EmptyCart;
