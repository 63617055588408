import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import LogoSmallIcon from "../../../components/Icons/Logo/LogoSmall";
import { LoginContainer } from "./Login.styles";
import LoginLogo from "./LoginLogo";
import CountrySelector from "./CountrySelector";

const StyledLoginContainer = styled(LoginContainer)`
  display: flex;
  flex-direction: column;
  min-height: 550px;
  align-items: center;
`;

const StyledMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 564px;
`;

const StyledMessageInnerContainer = styled.div`
  flex: 1;
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin: 0 10px;
  padding: 46px 10px 40px 10px;

  @media screen and (min-width: 768px) {
    padding: 46px 100px 40px 100px;
    margin: 0;
  }
`;

const StyledButtonContainer = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 24px 10px 0 10px;
  @media screen and (min-width: 768px) {
    margin: 24px 0 0 0;
  }
  cursor: pointer;
`;

const StyledHeaderMessage = styled.div`
  font-size: 16px;
  color: black;
  font-weight: bold;
  margin-bottom: 40px;
`;

const StyledMessage = styled.p`
  font-size: 14px;
  color: #333;
  line-height: 24px;
`;

const StyledAnchor = styled.a`
  color: #333;
`;

const StyledButtonLabel = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  color: #333;
`;

const StyledFormControlContainer = styled.div`
  margin-top: 40px;
`;

const WEBSITE_URL = "https://interflon.com";

const NotSupportedCountry = () => {
  const handleWebsiteClick = () => {
    window.open(WEBSITE_URL, "_blank");
  };

  return (
    <StyledLoginContainer>
      <LoginLogo />
      <div>
        <StyledMessageContainer>
          <StyledMessageInnerContainer>
            <StyledHeaderMessage>
              <FormattedMessage
                id="eo.country-dear-visitor"
                defaultMessage="Dear visitor,"
              />
            </StyledHeaderMessage>
            <StyledMessage>
              <FormattedMessage
                id="eo.country-webshop-not-avail"
                defaultMessage="Our webshop is currently not available in your country."
              />
            </StyledMessage>
            <StyledMessage>
              <FormattedMessage
                id="eo.country-company-registered"
                defaultMessage="If your company is registered in a country listed in the menu below, please select it to request an account or to log in."
              />
            </StyledMessage>
            <StyledMessage>
              <FormattedMessage
                id="eo.country-for-more-info"
                defaultMessage="For more information about our solutions and for contact details, please "
              />
              <StyledAnchor href={WEBSITE_URL} target="_blank">
                <FormattedMessage
                  id="eo.country-our-website"
                  defaultMessage="visit our website."
                />
              </StyledAnchor>
            </StyledMessage>
            <StyledFormControlContainer>
              <CountrySelector />
            </StyledFormControlContainer>
          </StyledMessageInnerContainer>
        </StyledMessageContainer>
        <StyledMessageContainer>
          <StyledButtonContainer onClick={handleWebsiteClick}>
            <LogoSmallIcon width="24px" height="24px" />
            <StyledButtonLabel>
              <FormattedMessage
                id="eo.country-website"
                defaultMessage="Website"
              />
            </StyledButtonLabel>
          </StyledButtonContainer>
        </StyledMessageContainer>
      </div>
    </StyledLoginContainer>
  );
};

export default NotSupportedCountry;
