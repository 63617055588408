import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Icon,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import HeaderTextMobile from "../../../header/components/Header/HeaderTextMobile";

const StyledAppBar = styled(AppBar)`
  && {
    top: 0;
    position: fixed;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.35);
    background: #fff;
  }
`;

const StyledToolbar = styled(Toolbar)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

const StyledBackContainer = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  min-height: 42px;
  color: #e42313;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
`;

const ProductDetailsHeader = ({ callback }) => (
  <StyledAppBar>
    <StyledToolbar>
      <StyledBackContainer>
        <IconButton onClick={callback} color="primary">
          <Icon>chevron_left</Icon>
        </IconButton>
        <Typography onClick={callback}>
          <FormattedMessage id="eo.back" defaultMessage="Back" />
        </Typography>
      </StyledBackContainer>
      <HeaderTextMobile />
    </StyledToolbar>
  </StyledAppBar>
);

ProductDetailsHeader.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default ProductDetailsHeader;
