import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { handleSubmit } from "../actions";
import FormInput from "../../../components/Form/FormInput";
import FormTextarea from "../../../components/Form/FormTextarea";
import Form from "../../../components/Form/Form";
import * as yup from "yup";

const phoneRegExp = /^(\d+-?\s?)+\d+$/;
const schema = yup
  .object({
    request: yup
      .string()
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
    name: yup
      .string()
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
    company: yup
      .string()
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
    phone: yup
      .string()
      .required(<FormattedMessage id="eo.required" defaultMessage="Required" />)
      .matches(phoneRegExp, {
        message: (
          <FormattedMessage
            id="eo.invalid-phone"
            defaultMessage="Invalid phone number"
          />
        ),
      }),
  })
  .required();

const RequestForm = ({ initialValues, onSuccess, onError }) => {
  const dispatch = useDispatch();
  const defaultValues = {
    request: initialValues?.request,
    name: "",
    company: "",
    phone: "",
  };

  const onSubmit = (data) =>
    handleSubmit(data)
      .then((result) => {
        onSuccess && onSuccess();
        dispatch(result);
      })
      .catch(() => {
        onError && onError();
      });

  return (
    <Form
      onSubmitForm={onSubmit}
      defaultValues={defaultValues}
      schema={schema}
      btnLabel={
        <FormattedMessage id="eo.contact-me" defaultMessage="Contact me" />
      }
      isCaptcha={true}
      isPrivacy={true}
    >
      <FormTextarea
        name="request"
        label={<FormattedMessage id="eo.request" defaultMessage="Request" />}
      />
      <FormInput
        name="name"
        label={
          <FormattedMessage id="eo.your-name" defaultMessage="Your name" />
        }
        required={true}
      />
      <FormInput
        name="company"
        label={
          <FormattedMessage id="eo.company-name" defaultMessage="Company" />
        }
        required={true}
      />
      <FormInput
        name="phone"
        label={
          <FormattedMessage
            id="eo.your-contact-phone"
            defaultMessage="Your contact phone"
          />
        }
        required={true}
      />
    </Form>
  );
};

RequestForm.propTypes = {
  initialValues: PropTypes.object,
};

export default RequestForm;
