import React from "react";
import { Provider } from "react-intl-redux";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import GlobalStyle from "./GlobalStyle";
import store from "../../../store";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#E42313",
    },
    gray: {
      main: "#999999",
    },
    error: {
      main: "#E42313",
    },
  },
  typography: {
    fontFamily: "Lato, Arial, sans-serif",
  },
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: "#999",
        "&$checked": {
          color: "#E42313",
        },
      },
    },
  },
});

const Providers = ({ children }) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <GlobalStyle />
      <Provider store={store}>{children}</Provider>
    </MuiThemeProvider>
  );
};

export default Providers;
