import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PropTypes from "prop-types";

const Header = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 15px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`);

const CartEditHeaderDesktop = ({ callback }) => (
  <Header onClick={callback}>
    <ChevronLeftIcon className="material-icons" />
    <FormattedMessage id="eo.back-to-cart" defaultMessage="Back to cart" />
  </Header>
);

CartEditHeaderDesktop.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default CartEditHeaderDesktop;
