import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import { withTheme } from "@material-ui/core/styles";
import OrderItems from "./OrderItems";
import OrderStatus from "./OrderStatus";

const StyledCard = styled(Card)`
  && {
    position: relative;
    border-radius: 4px;
    border: 2px solid #d1d1d1;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    border-left: ${({ $isSelected }) =>
      $isSelected ? "6px solid #E42313" : "2px solid #d1d1d1"};
  }
`;

const StyledCardActions = styled(CardActions)`
  && {
    background: #f3f3f3;
    padding: 10px;
    border-top: 2px dotted #929495;
    display: flex;
    justify-content: space-between;
    gap: 5px;

    @media screen and (min-width: 768px) {
      border-top: none;
      width: 230px;
      border-top: none;
      border-left: 2px dotted #929495;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (min-width: 1170px) {
      width: 300px;
    }
  }
`;

const StyledCardHeader = styled(CardHeader)`
  && {
    display: flex;
    color: #333;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    padding: 5px 10px;

    @media screen and (min-width: 768px) {
      width: 428px;
    }

    @media screen and (min-width: 1170px) {
      width: 748px;
    }
  }
`;

const StyledCardContent = styled(CardContent)`
  && {
    padding: 0;

    @media screen and (min-width: 768px) {
      width: 450px;
      min-height: 45px;
    }

    @media screen and (min-width: 1170px) {
      width: 770px;
    }
  }
`;

const ReorderButton = styled(Button)`
  && {
    max-width: 180px;
    margin: 0 !important;
  }
`;

const ExpandButton = withTheme(styled(IconButton)`
  && {
    width: 24px;
    height: 24px;
    padding: 0;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`);

const RefNr = styled.span`
  font-weight: 600;
  flex: 3;
`;

const Date = styled.span`
  display: inline-block;
  font-weight: normal;
  color: #999;
  flex: 1;
  text-align: right;
`;

const Order = ({ order, initiallyExpanded, reorderCallback }) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const handleExpandChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <StyledCard $isSelected={order?.referenceNumber === null}>
        <StyledCardHeader
          avatar={
            <ExpandButton onClick={handleExpandChange} color="primary">
              {expanded ? (
                <Icon>keyboard_arrow_up</Icon>
              ) : (
                <Icon>keyboard_arrow_down</Icon>
              )}
            </ExpandButton>
          }
          title={
            <Typography
              component="p"
              style={{
                display: "flex",
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "400",
              }}
            >
              <RefNr>
                <span>
                  {order.referenceNumber === null ? (
                    <FormattedMessage
                      id="eo.new-order"
                      defaultMessage="New order"
                    />
                  ) : (
                    <>
                      <FormattedMessage id="eo.order" defaultMessage="Order" />:{" "}
                      {order.referenceNumber}
                    </>
                  )}
                </span>
              </RefNr>
              <Date>{moment(order.createDate).format("DD.MM.YYYY")}</Date>
            </Typography>
          }
        />
        <Collapse in={expanded} timeout={{ enter: 0, exit: 0 }} unmountOnExit>
          <StyledCardContent className="order-card-text home-Order-details">
            <OrderItems items={order.items} />
          </StyledCardContent>

          <StyledCardActions
            className="home-Order-footer"
            style={{ marginLeft: "0px" }}
          >
            <OrderStatus status={order.deliveryStatus} />
            <ReorderButton
              variant="contained"
              color="primary"
              onClick={() => reorderCallback(order)}
              fullWidth={true}
            >
              <FormattedMessage id="eo.reorder" defaultMessage="RE-ORDER" />
            </ReorderButton>
          </StyledCardActions>
        </Collapse>
      </StyledCard>
    </div>
  );
};

Order.propTypes = {
  order: PropTypes.object.isRequired,
  initiallyExpanded: PropTypes.bool.isRequired,
  reorderCallback: PropTypes.func.isRequired,
};

export default Order;
