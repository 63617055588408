import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import Form from "../../../components/Form/Form";
import FormInput from "../../../components/Form/FormInput";
import { handleMoreInfoSubmit } from "../actions";
import { useCountry } from "../../../hooks/useCountry";

const phoneRegExp = /^(\d+-?\s?)+\d+$/;
const vatNumberRegExp = /^[a-zA-Z0-9.-]*$/;

const getSchema = (vatNumberRequired, intl) => {
  return yup
    .object({
      email: yup
        .string()
        .email(
          <FormattedMessage
            id="eo.invalid-email"
            defaultMessage="Invalid email address"
          />
        )
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        ),
      firstName: yup
        .string()
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        ),
      lastName: yup
        .string()
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        ),
      country: yup
        .string()
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        ),
      phone: yup
        .string()
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        )
        .matches(phoneRegExp, {
          message: (
            <FormattedMessage
              id="eo.invalid-phone"
              defaultMessage="Invalid phone number"
            />
          ),
        }),
      company: yup
        .string()
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        ),
      city: yup
        .string()
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        ),
      function: yup
        .string()
        .required(
          <FormattedMessage id="eo.required" defaultMessage="Required" />
        ),
      department: yup.string(),
      vatNumber: yup.string().when([], {
        is: () => vatNumberRequired,
        then: yup
          .string()
          .matches(
            vatNumberRegExp,
            intl.formatMessage({
              id: "eo.vat-invalid-characters",
              defaultMessage:
                "Only letters, numbers, periods, and dashes are allowed",
            })
          )
          .required(
            <FormattedMessage id="eo.required" defaultMessage="Required" />
          ),
        otherwise: yup
          .string()
          .matches(
            vatNumberRegExp,
            intl.formatMessage({
              id: "eo.vat-invalid-characters",
              defaultMessage:
                "Only letters, numbers, periods, and dashes are allowed",
            })
          )
          .notRequired(),
      }),
    })
    .required();
};

const MoreInfoForm = ({ email, onSuccess, onError }) => {
  const { vatNumberRequired } = useCountry();
  const intl = useIntl();

  const defaultValues = {
    email: email,
    firstName: "",
    lastName: "",
    country: "",
    phone: "",
    company: "",
    city: "",
    function: "",
    department: "",
    vatNumber: "",
  };

  const onSubmit = (data) => {
    return handleMoreInfoSubmit(data)
      .then((result) => {
        onSuccess && onSuccess();
      })
      .catch(() => {
        onError && onError();
      });
  };

  return (
    <div>
      <Form
        subTitle={
          <FormattedMessage
            id="eo.type-more-info"
            defaultMessage="Please give us more info about you"
          />
        }
        btnLabel={
          <FormattedMessage id="eo.contact-me" defaultMessage="Contact me" />
        }
        onSubmitForm={onSubmit}
        defaultValues={defaultValues}
        schema={getSchema(vatNumberRequired, intl)}
        isPrivacy={true}
      >
        <FormInput
          name="email"
          label={
            <FormattedMessage
              id="eo.your-email"
              defaultMessage="Email address"
            />
          }
          required={true}
        />
        <FormInput
          name="firstName"
          label={
            <FormattedMessage
              id="eo.your-first-name"
              defaultMessage="First name"
            />
          }
          required={true}
        />
        <FormInput
          name="lastName"
          label={
            <FormattedMessage
              id="eo.your-last-name"
              defaultMessage="Last name"
            />
          }
          required={true}
        />
        <FormInput
          name="vatNumber"
          label={
            <FormattedMessage id="eo.vat-number" defaultMessage="VAT number" />
          }
          required={vatNumberRequired}
        />
        <FormInput
          name="company"
          label={
            <FormattedMessage
              id="eo.company-name"
              defaultMessage="Company name"
            />
          }
          required={true}
        />
        <FormInput
          name="department"
          label={
            <FormattedMessage id="eo.department" defaultMessage="Department" />
          }
        />
        <FormInput
          name="function"
          label={
            <FormattedMessage
              id="eo.function-title"
              defaultMessage="Job title"
            />
          }
          required={true}
        />
        <FormInput
          name="country"
          label={
            <FormattedMessage id="eo.your-country" defaultMessage="Country" />
          }
          required={true}
        />
        <FormInput
          name="city"
          label={<FormattedMessage id="eo.city" defaultMessage="City" />}
          required={true}
        />
        <FormInput
          name="phone"
          label={
            <FormattedMessage
              id="eo.your-phone"
              defaultMessage="Phone number"
            />
          }
          required={true}
        />
      </Form>
    </div>
  );
};

export default MoreInfoForm;
