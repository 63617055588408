import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Button, Popover } from "@material-ui/core";
import styled from "styled-components";

import OpenCountryList from "./OpenCountryList";
import OpenChangeLanguage from "./OpenChangeLanguage";
import ChangeCountryLoader from "./ChangeCountryLoader";
import { useCountry, useCountryDispatch } from "../../../hooks/useCountry";
import LanguageIcon from "@material-ui/icons/Language";

const Container = styled.div`
  min-width: 200px;
`;

const StyledButton = styled(Button)`
  && {
    @media screen and (min-width: 768px) {
      &:hover {
        background: #d5d5d5;
      }
    }

    .MuiButton-label {
      display: flex;
      gap: 6px;
    }
  }
`;

const Title = styled.h4`
  text-transform: capitalize;
  padding: 10px 60px 10px 10px;
  margin: 0;
  font-size: 13px;
  font-weight: 700;
  white-space: nowrap;
`;

const OpenChangeCountry = forwardRef(
  ({ buttonLabel, disableCountryChange }, ref) => {
    const { country: currentCountry, language: currentLanguage } = useCountry();
    const countryDispatch = useCountryDispatch();

    const buttonRef = useRef();
    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState(currentCountry);
    const [language, setLanguage] = useState(
      currentLanguage?.toUpperCase() || ""
    );

    const {
      countries,
      changeCountry: { loader },
    } = useSelector((state) => state.login);

    const handleRequestOpen = (event) => {
      event.preventDefault();
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleSwitch = (country, language) => {
      setCountry(country);
      setLanguage(language);
    };

    const handleApply = () => {
      countryDispatch({ type: "set", country: country, language: language });
      handleClose();
    };

    useImperativeHandle(ref, () => ({
      openChangeCountry: handleRequestOpen,
    }));

    return (
      <>
        {!loader ? (
          <div>
            <StyledButton ref={buttonRef} onClick={handleRequestOpen}>
              {buttonLabel}
              <LanguageIcon />
            </StyledButton>

            <Popover
              open={open}
              anchorEl={buttonRef.current}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              transformOrigin={{ horizontal: "left", vertical: "top" }}
              onClose={handleClose}
            >
              <Container>
                {!disableCountryChange && (
                  <>
                    <Title>
                      <FormattedMessage
                        id="eo.change-country"
                        defaultMessage="Change country"
                      />
                    </Title>
                    <OpenCountryList
                      countries={countries}
                      changeCountryCallback={handleSwitch}
                    />
                  </>
                )}

                <OpenChangeLanguage
                  countries={countries}
                  country={country}
                  selected={language}
                  changeLanguageCallback={handleSwitch}
                />

                <Button
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  onClick={handleApply}
                >
                  <FormattedMessage id="eo.apply" defaultMessage="Apply" />
                </Button>
              </Container>
            </Popover>
          </div>
        ) : (
          <ChangeCountryLoader />
        )}
      </>
    );
  }
);

OpenChangeCountry.propTypes = {
  disableCountryChange: PropTypes.bool,
  buttonLabel: PropTypes.string,
};

export default OpenChangeCountry;
