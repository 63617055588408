import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import { Button } from "@material-ui/core";
import TopBarMenu from "./TopBarMenu";
import Title from "../Title";
import LogoIcon from "../../../../components/Icons/Logo/LogoSmall";
import { fetchContactPerson, redirectToLogin } from "../../actions";
import { fetchBasket } from "../../../cart/actions";
import { getSelectedHeaderByDevice } from "../../../menu/selectors";
import HeaderLoader from "./HeaderLoader";

import {
  StyledAppBar,
  Logo,
  UserDetails,
  StyledToolbar,
  StyledHeaderContainer,
  UserDetailsContainer,
} from "./HeaderMobile.styles";
import HeaderTextMobile from "./HeaderTextMobile";

const HeaderMobile = () => {
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.login);
  const { deviceType } = useSelector((state) => state.translations);
  const { headerLoader, userName, clientName } = useSelector(
    (state) => state.header
  );

  const header = useSelector((state) => state.header?.countryOptions?.header);
  const { location } = useSelector((state) => state.router);

  const [isHeader, setIsHeader] = useState(false);

  useEffect(() => {
    if (logged) {
      fetchContactPersonData();
    }
  }, [logged]);

  useEffect(() => {
    setIsHeader(getSelectedHeaderByDevice(deviceType));
  }, [deviceType, location]);

  const fetchContactPersonData = () => {
    dispatch(fetchContactPerson());
    dispatch(fetchBasket());
  };

  const handleLogoClick = () => {
    dispatch(push("/"));
  };

  return isHeader ? (
    <StyledAppBar position="fixed">
      <StyledToolbar height={header ? "128px" : "64px"}>
        {logged ? (
          headerLoader ? (
            <>
              <Logo
                alt=""
                onClick={handleLogoClick}
                width="57px"
                height="64px"
              />
              <HeaderLoader />
            </>
          ) : (
            <StyledHeaderContainer>
              <UserDetailsContainer>
                <UserDetails>
                  <Logo
                    alt=""
                    onClick={handleLogoClick}
                    width="57px"
                    height="64px"
                  />
                  <Title user={userName} company={clientName} />
                </UserDetails>
                <TopBarMenu />
              </UserDetailsContainer>
              <HeaderTextMobile />
            </StyledHeaderContainer>
          )
        ) : (
          <>
            <LogoIcon width="57px" height="64px" />
            <Button onClick={() => dispatch(redirectToLogin())}>
              <FormattedMessage id="eo.log-in" defaultMessage="Log in" />
            </Button>
          </>
        )}
      </StyledToolbar>
    </StyledAppBar>
  ) : (
    <span />
  );
};

export default HeaderMobile;
