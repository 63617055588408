import SearchIcon from "@material-ui/icons/Search";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const MessageContainer = styled.div`
  margin-top: 28px;
  padding: 49px;
  font: 14px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  background-color: #f2f2f2;
`;

export const Icon = styled(SearchIcon)`
  color: #cccccc;
`;

const SearchMessage = ({ id, defaultMessage, values = undefined }) => {
  return (
    <MessageContainer>
      <Icon />
      <FormattedMessage
        id={id}
        defaultMessage={defaultMessage}
        values={values}
      />
    </MessageContainer>
  );
};

export default SearchMessage;
