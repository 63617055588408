import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Select, MenuItem, ListItemText } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import styled from "styled-components";

import { getCategoryColor } from "../../../helpers/categories";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 52px;
`;
const Label = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: #666666;
  font-size: 14px;
`;

const StyledSelect = styled(Select)`
  width: 50%;
  float: right;
`;

const StyledMenuItem = styled(MenuItem)`
  color: #fff;
  font-weight: bold;
  background-color: ${({ kind }) => `${getCategoryColor(kind)} !important`};
`;

const Categories = ({ categories, currentCategory, changeCallback }) => {
  const getMenuColor = () => {
    const element = categories.find(
      (element) => element.categoryId === currentCategory
    );

    if (element !== undefined) {
      return getCategoryColor(element.token);
    }
    return "";
  };

  const selectStyle = {
    display: "flex",
    alignItems: "center",
    background: getMenuColor(),
    fontWeight: "bold",
    height: "40px",
    color: "#fff",
    borderRadius: "4px",
    marginLeft: "12px",
    marginRight: "16px",
    padding: 0,
    flex: 1,
  };

  const handleChange = (event) => {
    changeCallback(event.target.value);
  };

  const categoriesList = categories.map((category, index) => (
    <StyledMenuItem
      key={index}
      value={category.categoryId}
      style={{
        color: "#fff",
        height: "40px",
        minHeight: "40px",
        paddingLeft: "10px",
      }}
      kind={category.token.toLowerCase()}
    >
      <ListItemText primary={category.name} />
    </StyledMenuItem>
  ));

  return (
    <Wrapper>
      <Label>
        <FormattedMessage id="eo.categories" defaultMessage="Categories" />:
      </Label>
      <StyledSelect
        style={selectStyle}
        SelectDisplayProps={{ style: { paddingLeft: "10px" } }}
        value={currentCategory}
        onChange={handleChange}
        MenuProps={{ style: { padding: "0px" } }}
        IconComponent={() => (
          <ArrowDropDownIcon style={{ color: "#FFFFFF", marginRight: "8px" }} />
        )}
        disableUnderline
      >
        {categoriesList}
      </StyledSelect>
    </Wrapper>
  );
};

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
  changeCallback: PropTypes.func.isRequired,
  currentCategory: PropTypes.number,
  deviceType: PropTypes.string,
};

export default Categories;
