import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import CloseIcon from "@material-ui/icons/Close";
import { withTheme } from "@material-ui/core/styles";
import WhatNext from "../../../cart/components/WhatNext/WhatNext";
import { NewOrderIcon } from "../../../core/icons/NewOrderIcon";

const dialogContentStyle = {
  borderRadius: "4px",
  backgroundColor: "#ffffff",
  transform: "",
  minWidth: "",
};

const dialogBodyStyle = {
  padding: "",
  minHeight: "",
};

const StyledDialog = styled(Dialog)`
  color: #333;
  min-width: 295px;
`;

const StyledDialogContainer = styled.div`
  padding: 10px;
`;

const Title = styled.h2`
  color: #333333;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
`;

const Details = styled.div`
  padding: 5px 0;
  width: 100%;
  text-align: center;
`;

const StyledIcon = withTheme(styled(SvgIcon)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
    display: inline-block;
    margin: 20px auto 0 auto;
    width: 60px;
    height: 65px;
  }
`);

const NewOrderRegistered = ({ closeCallback, containsRiskyProducts }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    closeCallback && closeCallback();
  };

  return (
    <StyledDialog
      modal={true}
      autoScrollBodyContent={true}
      bodyStyle={dialogBodyStyle}
      bodyClassName="home-NewOrderRegistered-body"
      contentStyle={dialogContentStyle}
      contentClassName="home-NewOrderRegistered-content"
      className="home-NewOrderRegistered"
      open={open}
    >
      <StyledDialogContainer>
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          className="dialog-CloseButton"
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Details>
          <StyledIcon
            viewBox="0 0 60 65"
            style={{ display: "", color: "", fill: "", width: "", height: "" }}
          >
            <path {...NewOrderIcon} />
          </StyledIcon>
          <Title>
            <FormattedMessage
              id="eo.new-order-registered"
              defaultMessage="New order registered!"
            />
          </Title>
        </Details>
        <WhatNext selected="1" containsRiskyProducts={containsRiskyProducts} />
      </StyledDialogContainer>
    </StyledDialog>
  );
};

NewOrderRegistered.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  containsRiskyProducts: PropTypes.bool.isRequired,
};

export default NewOrderRegistered;
