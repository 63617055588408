import Localization from "./Localization";

class Auth {
  static isLoggedIn() {
    return !!localStorage.token;
  }

  static getToken() {
    return localStorage.getItem("token");
  }

  static generateUnloggedToken() {
    let token =
      "lang:" +
      Localization.getLanguage() +
      ";country:" +
      Localization.getCountry();
    token = btoa(token);

    localStorage.setItem("unlogged-token", token);

    return token;
  }

  static getUnloggedToken() {
    if (localStorage.getItem("unlogged-token")) {
      return localStorage.getItem("unlogged-token");
    }

    return Auth.generateUnloggedToken();
  }

  static logIn(token) {
    localStorage.setItem("token", token);
  }

  static logOut() {
    localStorage.removeItem("token");
  }

  static getAuthorizationRequestConfig() {
    return {
      headers: {
        Authorization: "Bearer " + Auth.getToken(),
      },
    };
  }

  static getAuthorizationRequestUnloggedConfig() {
    return {
      headers: {
        Authorization: "Bearer " + Auth.getUnloggedToken(),
      },
    };
  }

  static getAuthorizationPutRequestConfig() {
    return {
      headers: {
        Authorization: "Bearer " + Auth.getToken(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }
}

export default Auth;
