import { NAME } from "./constants";

export const getAll = (state) => state[NAME];

export const getAddresses = (state) => ({
  delivery: state[NAME].deliveryAddress,
  invoice: state[NAME].invoiceAddress,
});

export const getCurrency = (state) => state[NAME].currency;
