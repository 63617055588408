import React from 'react';
import uuid from 'uuid';

const idGradient = uuid.v1();
const idClip = uuid.v1();

const MyOrdersLoader = () => (
    <svg viewBox="0 0 300 125" style={{margin: '0 9px'}} className="order-card">
        <rect style={{fill: `url(#${idGradient})`}} clipPath={`url("#${idClip}")`} x="0" y="0" width="290" height="125"/>

        <defs>
            <clipPath id={idClip}>
                <rect x="5" y="10" rx="5" ry="5" width="200" height="15" />
                <rect x="230" y="10" rx="5" ry="5" width="60" height="15" />

                <rect x="5" y="40" rx="3" ry="3" width="180" height="10" />
                <rect x="220" y="40" rx="3" ry="3" width="70" height="10" />

                <rect x="5" y="55" rx="3" ry="3" width="210" height="10" />
                <rect x="240" y="55" rx="3" ry="3" width="50" height="10" />

                <rect x="5" y="70" rx="3" ry="3" width="200" height="10" />
                <rect x="230" y="70" rx="3" ry="3" width="60" height="10" />

                <rect x="5" y="95" rx="5" ry="5" width="50" height="20" />
                <rect x="60" y="95" rx="5" ry="5" width="80" height="20" />
                <rect x="160" y="95" rx="5" ry="5" width="130" height="20" />
            </clipPath>

            <linearGradient id={idGradient}>
                <stop offset="0%" stopColor="#f0f0f0">
                    <animate attributeName="offset" values="-2; 1" dur="2s" repeatCount="indefinite"/>
                </stop>
                <stop offset="50%" stopColor="#e0e0e0">
                    <animate attributeName="offset" values="-1.5; 1.5" dur="2s" repeatCount="indefinite"/>
                </stop>
                <stop offset="100%" stopColor="#f0f0f0">
                    <animate attributeName="offset" values="-1; 2" dur="2s" repeatCount="indefinite"/>
                </stop>
            </linearGradient>
        </defs>
    </svg>
);

export default MyOrdersLoader;