import React from "react";
import { FormattedMessage } from "react-intl";
import RecommendationsDesktop from "../../../home/components/Recomendations/RecommendationsDesktop";
import PropTypes from "prop-types";
import RecommendationsMobile from "../../../home/components/Recomendations/RecommendationsMobile";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  border-top: 2px dotted #929495;
  margin-top: 15px;
  padding-top: 10px;
`;

const Title = styled.h2`
  font-size: 16px;
  margin: 0 15px;
`;

const RandomRecommendedProductForProductDetails = ({
  recommendations,
  detailsCallback,
}) => {
  const { deviceType } = useSelector((state) => state.translations);

  return recommendations.length === 0 ? (
    <div />
  ) : (
    <Container>
      <Title>
        <FormattedMessage
          id="eo.may-also-need"
          defaultMessage="You may also need"
        />
      </Title>

      {deviceType === "mobile" ? (
        <RecommendationsMobile
          products={recommendations}
          detailsCallback={detailsCallback}
        />
      ) : (
        <RecommendationsDesktop
          products={recommendations}
          detailsCallback={detailsCallback}
          fullWidth={deviceType === "desktop"}
        />
      )}
    </Container>
  );
};

RandomRecommendedProductForProductDetails.propTypes = {
  recommendations: PropTypes.array.isRequired,
  detailsCallback: PropTypes.func.isRequired,
};

export default RandomRecommendedProductForProductDetails;
