import React, { useState } from "react";
import {
  Container,
  StyledDialog,
  StyledDialogButton,
  StyledDialogContent,
  StyledIconButton,
  ThankYouMessage,
  ThankYouTitle,
  Title,
} from "./UnloggedContact.styles";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import RequestForm from "./RequestForm";
import { Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { parseEscapes } from "../../../helpers/stringHelpers";

const Request = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [requestSent, setRequestSent] = useState(null);

  const handleSubmit = () => {
    setRequestSent(true);
  };

  const handleError = () => {
    setRequestSent(false);
  };

  const closeModal = () => {
    dispatch(push("/login"));
  };

  const getInitialMessage = () => {
    const messages = defineMessages({
      request: {
        id: "eo.contact-default-message",
        defaultMessage:
          "Hello,\n\nI\u0027m interested in {productName}.\n\nPlease call me in order to set up a meeting.",
        description: "Request message",
      },
    });

    let name = {
      productName: props.match.params.productName
        ? props.match.params.productName
        : intl.formatMessage({
            id: "eo.contact-default-prod-name",
            defaultMessage: "Interflon products and services",
          }),
    };

    return {
      request: parseEscapes(intl.formatMessage(messages.request, name)),
    };
  };

  return (
    <Container>
      {!requestSent && (
        <Title>
          <FormattedMessage
            id="eo.request-contact"
            defaultMessage="Request contact"
          />
        </Title>
      )}
      {requestSent === null && (
        <RequestForm
          initialValues={getInitialMessage()}
          onSuccess={handleSubmit}
          onError={handleError}
        />
      )}
      {requestSent === false && (
        <Paper elevation={0}>
          <h3>
            <FormattedMessage id="eo.sorry" defaultMessage="We're sorry" />
          </h3>
          <p>
            <FormattedMessage
              id="eo.sorry"
              defaultMessage="There was a technical problem."
            />
          </p>
        </Paper>
      )}
      <StyledDialog open={!!requestSent} fullWidth={true}>
        <StyledDialogContent>
          <StyledIconButton onClick={closeModal}>
            <CloseIcon />
          </StyledIconButton>
          <ThankYouTitle>
            <FormattedMessage
              id="eo.request-thank-you"
              defaultMessage="Thank you!"
            />
          </ThankYouTitle>
          <ThankYouMessage>
            <FormattedMessage
              id="eo.request-will-call"
              defaultMessage="We will contact you soon."
            />
          </ThankYouMessage>
          <StyledDialogButton
            color="primary"
            variant={"contained"}
            onClick={closeModal}
          >
            <FormattedMessage id="eo.sign-ok" defaultMessage="OK" />
          </StyledDialogButton>
        </StyledDialogContent>
      </StyledDialog>
    </Container>
  );
};

export default Request;
