import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const StyledContainer = styled.div`
  padding-top: 10px;
`;

const StyledTitle = styled.div`
  color: #1a1a1a;
  font-size: 14px;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  align-items: center;
`;

const StyledLabel = styled.div`
  color: ${({ checked }) => (checked ? "#666666" : "#999")};
  font-size: 14px;
`;

const StyledItemContainer = styled.div`
  margin-top: 19px;
  margin-bottom: 32px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: #78be20;
  padding-right: 8px;
  && {
    font-size: 18px;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: #e42313;
  padding-right: 8px;
  && {
    font-size: 18px;
  }
`;

const NewPasswordFormValidation = ({ title, items }) => {
  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
      <StyledItemContainer>
        {items.map((item, index) => (
          <StyledItem key={`validation_${index}`}>
            {item.checked ? <StyledCheckIcon /> : <StyledCloseIcon />}
            <StyledLabel checked={item.checked}>{item.text}</StyledLabel>
          </StyledItem>
        ))}
      </StyledItemContainer>
    </StyledContainer>
  );
};

export default NewPasswordFormValidation;
