import { createContext, useEffect, useReducer, useState } from "react";

import Auth from "../modules/core/Auth";
import * as t from "../modules/header/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { fetchViewerCountryCode } from "../modules/login/actions";

export const CountryContext = createContext(null);
export const CountryDispatchContext = createContext(null);

const countryReducer = (state, action) => {
  switch (action.type) {
    case "set": {
      window.localStorage.setItem(
        "languageCode",
        action.language.toLowerCase()
      );
      window.localStorage.setItem(
        "countryCode",
        action?.country?.toLowerCase()
      );
      return {
        ...state,
        country: action.country,
        language: action.language,
        loading: false,
      };
    }
    case "setCountry": {
      return { ...state, country: action.value };
    }
    case "setLanguage": {
      return { ...state, language: action.value };
    }
    case "setLoading": {
      return { ...state, loading: action.value };
    }
    default: {
      throw Error(`Unknown action ${action.type}`);
    }
  }
};

const getInitialState = () => {
  const country = window.localStorage.getItem("countryCode") || "";
  const language = window.localStorage.getItem("languageCode") || "";
  return { country, language, loading: true };
};

const CountryProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [data, countryDispatch] = useReducer(countryReducer, getInitialState());
  const { countries } = useSelector((state) => state.login);
  const { country: profileCountry, language: profileLanguage } = useSelector(
    (state) => state.header
  );
  const [isCountryValid, setIsCountryValid] = useState(false);
  const { country, language, loading } = data;

  useEffect(() => {
    const initializeCountry = async () => {
      const country = window.localStorage.getItem("countryCode") || "";
      const language = window.localStorage.getItem("languageCode") || "";
      if (country && language) {
        countryDispatch({
          type: "setLoading",
          value: false,
        });
      } else {
        try {
          const result = await fetchViewerCountryCode();
          const viewerCountryCode = result.data?.viewerCountryCode || "";
          const countryCode = viewerCountryCode?.toUpperCase();
          const language =
            countries.find((country) => country.landCode === countryCode)
              ?.defaultLanguage || "";
          countryDispatch({
            type: "set",
            country: countryCode,
            language: language,
          });
        } catch (error) {
          console.error("Error fetching viewer country code:", error);
        }
      }
    };

    initializeCountry();
  }, [countries]);

  useEffect(() => {
    if (!country || !language) return;

    Auth.generateUnloggedToken();
    dispatch({ type: t.LOCALE_CHANGED });
  }, [country, language]);

  useEffect(() => {
    if (isEmpty(countries)) {
      setIsCountryValid(true);
    } else {
      setIsCountryValid(
        !!countries.find(
          ({ landCode }) => landCode.toUpperCase() === country?.toUpperCase()
        )
      );
    }
  }, [country, countries]);

  useEffect(() => {
    if (profileCountry && profileLanguage)
      countryDispatch({
        type: "set",
        country: profileCountry,
        language: profileLanguage,
      });
  }, [profileCountry, profileLanguage]);

  const currentCountry = countries.find(
    ({ landCode }) => landCode.toUpperCase() === country?.toUpperCase()
  );

  const ret = {
    ...data,
    isCountryValid,
    termsUrl: currentCountry?.termsUrl,
    privacyUrl: currentCountry?.privacyUrl,
    legalDisclosure: currentCountry?.legalDisclosure,
    cookies: currentCountry?.cookies,
    availableLanguages: currentCountry?.availableLanguages,
    translationCode: currentCountry?.translationCode,
    vatNumberRequired: currentCountry?.vatNumberRequired,
  };

  if (loading) {
    return null;
  }

  return (
    <CountryDispatchContext.Provider value={countryDispatch}>
      <CountryContext.Provider value={ret}>{children}</CountryContext.Provider>
    </CountryDispatchContext.Provider>
  );
};

export default CountryProvider;
