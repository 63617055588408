import * as t from "./actionTypes";
import axios from "axios";
import config from "../../config";
import Auth from "../core/Auth";
import { getFetchPriceRequestState } from "./selectors";
import { push } from "connected-react-router";
import { CLOSE_FREE_TRAININGS_DIALOG } from "../cart/actionTypes";
import { handleRequestError } from "../core/ErrorHandler";

const assignCategoryIdsToProducts = (responseData) => {
  return {
    ...responseData,
    catalogue: responseData?.catalogue?.map((category) => ({
      ...category,
      products: category?.products?.map((product) => ({
        ...product,
        categoryId: category?.categoryId,
      })),
      subCategories: category?.subCategories.map((subcategory) => ({
        ...subcategory,
        products: subcategory?.products?.map((product) => ({
          ...product,
          categoryId: category?.categoryId,
        })),
      })),
    })),
  };
};

const fetchCatalogueRequest = (dispatch) => {
  axios
    .get(config.apiUrl + "eo/shop", Auth.getAuthorizationRequestConfig())
    .then((response) => {
      const data = assignCategoryIdsToProducts(response.data);
      return dispatch({
        type: t.FETCH_CATALOGUE_SUCCESS,
        data,
      });
    })
    .catch((error) =>
      handleRequestError(dispatch, error, t.FETCH_CATALOGUE_FAIL)
    );
};

const fetchCatalogueUnloggedRequest = (dispatch) => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getUnloggedToken(),
    },
  };

  axios
    .get(config.apiUrl + "eo/open/shop", token)
    .then((response) => {
      const data = assignCategoryIdsToProducts(response.data);
      return dispatch({
        type: t.FETCH_CATALOGUE_SUCCESS,
        data,
      });
    })
    .catch((error) =>
      handleRequestError(dispatch, error, t.FETCH_CATALOGUE_FAIL)
    );
};

export const fetchCatalogue = () => {
  return (dispatch) => {
    dispatch({ type: t.FETCH_CATALOGUE_REQUEST });

    if (Auth.isLoggedIn()) {
      fetchCatalogueRequest(dispatch);
    } else {
      fetchCatalogueUnloggedRequest(dispatch);
    }
  };
};

const fetchProductDetailsRequest = (dispatch, groupCode) => {
  axios
    .get(
      config.apiUrl + "eo/product/details/" + groupCode,
      Auth.getAuthorizationRequestConfig()
    )
    .then((response) =>
      dispatch({
        type: t.FETCH_PRODUCT_SUCCESS,
        data: response.data,
      })
    )
    .catch((error) =>
      handleRequestError(dispatch, error, t.FETCH_PRODUCT_FAIL)
    );
};

const fetchProductDetailsUnloggedRequest = (dispatch, groupCode) => {
  const token = {
    headers: {
      Authorization: "Bearer " + Auth.getUnloggedToken(),
    },
  };

  axios
    .get(config.apiUrl + "eo/open/product/details/" + groupCode, token)
    .then((response) =>
      dispatch({
        type: t.FETCH_PRODUCT_SUCCESS,
        data: response.data,
      })
    )
    .catch((error) =>
      handleRequestError(dispatch, error, t.FETCH_PRODUCT_FAIL)
    );
};

export const fetchProductDetails = (groupCode) => {
  return (dispatch) => {
    dispatch({ type: t.FETCH_PRODUCT_REQUEST });
    if (Auth.isLoggedIn()) {
      fetchProductDetailsRequest(dispatch, groupCode);
    } else {
      fetchProductDetailsUnloggedRequest(dispatch, groupCode);
    }
  };
};

export const changeCategory = (categoryId) => {
  return (dispatch) => {
    dispatch({
      type: t.CHANGE_CATEGORY,
      data: categoryId,
    });
  };
};

export const changeCategoryOnProductDetails = (categoryId) => {
  return (dispatch) => {
    dispatch({
      type: t.CHANGE_CATEGORY,
      data: categoryId,
    });

    dispatch(push("/shop"));
  };
};

export const showProduct = (destination) => {
  return (dispatch) => {
    dispatch(push(destination));
  };
};

export const changeVariant = (variantIndex) => {
  return (dispatch) => {
    dispatch({
      type: t.CHANGE_VARIANT,
      data: variantIndex,
    });
  };
};

const fetchPriceRequest = (dispatch, productId, value, quantity) => {
  dispatch({ type: t.FETCH_PRICE_REQUEST });

  axios
    .get(
      config.apiUrl + "eo/product/price/" + productId + "/" + quantity,
      Auth.getAuthorizationRequestConfig()
    )
    .then((response) =>
      dispatch({
        type: t.FETCH_PRICE_SUCCESS,
        data: {
          variantId: productId,
          price: response.data.price,
          nextSegment: response.data.nextSegment,
          quantity: value,
        },
      })
    )
    .catch((error) =>
      handleRequestError(dispatch, error, t.FETCH_PRICE_FAILURE)
    );
};

export const fetchPrice = (productId, value, quantity) => {
  return (dispatch, getState) => {
    if (!getFetchPriceRequestState(getState())) {
      fetchPriceRequest(dispatch, productId, value, quantity);
    }
  };
};

export const requestDemoCall = (productId) => {
  return axios.post(
    config.apiUrl + "eo/contact/request/" + productId,
    {},
    Auth.getAuthorizationRequestConfig()
  );
};

export const redirectToContact = (productName) => {
  return (dispatch) => {
    dispatch(push(`/contact/request/${productName}`));
  };
};

export const redirectToSignin = () => {
  return (dispatch) => {
    dispatch(push("/contact/signin"));
  };
};

export const redirectToShop = () => {
  return (dispatch) => {
    dispatch(push("/shop"));
  };
};

export const clearProductDetails = () => {
  return (dispatch) => {
    dispatch({ type: t.CLEAR_PRODUCT_DETAILS });
  };
};

export const closeFreeTrainingsDialog = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_FREE_TRAININGS_DIALOG });
  };
};

export const requestFreeTraining = (product) => {
  return (dispatch) => {
    dispatch({
      type: t.REQUEST_FREE_TRAINING_REQUEST,
      data: { requestStatus: true },
    });

    axios
      .post(
        config.apiUrl + "eo/training/request/" + product.variantId,
        {},
        Auth.getAuthorizationRequestConfig()
      )
      .then((response) =>
        dispatch({
          type: CLOSE_FREE_TRAININGS_DIALOG,
        })
      )
      .catch((response) =>
        handleRequestError(dispatch, response, t.REQUEST_FREE_TRAINING_FAILURE)
      );
  };
};
