import React from "react";
import { useSelector } from "react-redux";

import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

const Header = () => {
  const { deviceType } = useSelector((state) => state.translations);

  return deviceType === "mobile" ? <HeaderMobile /> : <HeaderDesktop />;
};

export default Header;
