import React, { useEffect, useState } from "react";
import MoreInfoForm from "./MoreInfoForm";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Paper } from "@material-ui/core";
import styled from "styled-components";
import { push } from "connected-react-router";
import { useCountryDispatch } from "../../../hooks/useCountry";

const Container = styled.div`
  text-align: center;
  margin: 34px 0;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  padding-bottom: 0;
  font-weight: bold;
  color: #333;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
`;

const MoreInfo = (props) => {
  const dispatch = useDispatch();
  const countryDispatch = useCountryDispatch();

  const email = atob(props.match.params.token);
  const [requestSent, setRequestSent] = useState(null);

  const handleSubmit = () => {
    setRequestSent(true);
  };

  const handleError = () => {
    setRequestSent(false);
  };

  useEffect(() => {
    countryDispatch({
      type: "set",
      country: props.match.params.country,
      language: props.match.params.language,
    });
  }, []);

  const validateToken = () => {
    try {
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    } catch (e) {
      return false;
    }
  };

  return (
    <Container>
      {requestSent === null && (
        <>
          <Title>
            <FormattedMessage
              id="eo.more-info-title"
              defaultMessage="More info needed"
            />
          </Title>
          {validateToken() ? (
            <MoreInfoForm
              email={email}
              onSuccess={handleSubmit}
              onError={handleError}
            />
          ) : (
            <div>
              <p>
                <FormattedMessage
                  id="eo.error"
                  defaultMessage="Something goes wrong"
                />
              </p>
              <Button
                color="primary"
                variant="contained"
                onClick={() => dispatch(push("/contact/signin"))}
              >
                <FormattedMessage
                  id="eo.register-again"
                  defaultMessage="Request again"
                />
              </Button>
            </div>
          )}
        </>
      )}

      {requestSent && (
        <Paper elevation={0}>
          <p>
            <FormattedMessage
              id="eo.more-info-added"
              defaultMessage="Thank you for filling additional data. We'll contact with you."
            />
          </p>
          <p>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(push("/login"))}
            >
              <FormattedMessage id="eo.sign-ok" defaultMessage="OK" />
            </Button>
          </p>
        </Paper>
      )}
      {requestSent === false && (
        <Paper elevation={0}>
          <h3>
            <FormattedMessage id="eo.sorry" defaultMessage="We're sorry" />
          </h3>
          <p>
            <FormattedMessage
              id="eo.sorry"
              defaultMessage="There was a technical problem."
            />
          </p>
        </Paper>
      )}
    </Container>
  );
};

export default MoreInfo;
