import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  fetchProducts,
  fetchPrice,
  handleProductLink,
  fetchRecommendedProducts,
} from "../../actions";
import { addToBasket } from "../../../cart/actions";
import Product from "../Product/Product";
import MyProductsLoader from "./MyProductsLoader";
import MyProductsLoaderDesktop from "./MyProductsLoaderDesktop";
import EmptyProductsInfo from "./EmptyProductsInfo";
import Recommendations from "../Recomendations/Recommendations";

const List = styled.ul`
  list-style: none;
  padding: 0 10px;
  margin: 60px 0 10px;

  @media screen and (min-width: 768px) {
    margin: 10px 0;
  }
`;

const MyProducts = ({ index, value }) => {
  const dispatch = useDispatch();

  const { deviceType } = useSelector((state) => state.translations);
  const { products, recommendations, productsLoader } = useSelector(
    (state) => state.home
  );
  const { currency } = useSelector((state) => state.header);
  const { changeCartRequest } = useSelector((state) => state.cart);

  const shouldShow = index === value ? {} : { display: "none" };

  const detailsCallback = (link) => {
    dispatch(handleProductLink(link));
  };

  const addToBasketCallback = (data) => {
    dispatch(addToBasket(data));
  };

  const priceCallback = (productId, value, quantity) => {
    dispatch(fetchPrice(productId, value, quantity));
  };

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchRecommendedProducts());

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <div className="home-MyProducts history-content" style={shouldShow}>
      {productsLoader && deviceType === "mobile" && <MyProductsLoader />}
      {productsLoader && deviceType !== "mobile" && <MyProductsLoaderDesktop />}
      {products.length > 0 ? (
        <List>
          {products.map((product, index) => (
            <Product
              product={product}
              key={index}
              priceCallback={priceCallback}
              addCallback={addToBasketCallback}
              currency={currency}
              redirectCallback={detailsCallback}
              cartState={changeCartRequest}
            />
          ))}
        </List>
      ) : (
        <EmptyProductsInfo productsLoader={productsLoader} />
      )}
      {recommendations.length > 0 && (
        <Recommendations
          products={recommendations}
          detailsCallback={detailsCallback}
          deviceType={deviceType}
        />
      )}
    </div>
  );
};

export default MyProducts;
