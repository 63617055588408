import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import * as selectors from "./selectors";

export { default as Login } from "./components/Login";
export { default as Activate } from "./components/Activate";
export { default as MoreInfo } from "./components/MoreInfo";
export { default as NotSupportedCountry } from "./components/NotSupportedCountry";

const login = { actions, constants, reducer, selectors };
export default login;
