import { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
  color: #333;
`;

const Title = styled.h2`
  font-size: 18px;
`;

const List = styled.ul`
  padding-left: 15px;
  font-size: 14px;
`;

const ProductUnavailable = ({ errors, closeCallback }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    closeCallback();
  };

  const errorList = errors.map((error, index) => (
    <li key={index}>
      <FormattedMessage
        id="eo.error-unavailable"
        values={{ product: error }}
        defaultMessage="Product {product} is currently unavailable"
      />
    </li>
  ));

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogContent>
        <Title>
          <FormattedMessage
            id="eo.error-sorry"
            defaultMessage="We're sorry..."
          />
        </Title>
        <List>{errorList}</List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

ProductUnavailable.propTypes = {
  errors: PropTypes.array.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

export default ProductUnavailable;
