import React from "react";
import ReactCookieFirst from "react-cookiefirst";

export const CookiefirstProvider = ({ children }) => {
  const apiKey = process.env.REACT_APP_COOKIEFIRST_API_KEY;
  if (apiKey) {
    return (
      <ReactCookieFirst apiKey={apiKey} renderBeforeReady={true}>
        {children}
      </ReactCookieFirst>
    );
  } else {
    return children;
  }
};
