import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  CART_MENU_NAME,
  CART_FULL_MENU_NAME,
  mobileElements,
} from "../constants";
import { getSelectedIndex, getSelectedMenu } from "../selectors";
import isObject from "lodash/isObject";
import MenuCartItem from "./MenuCartItem";
import {
  Icon,
  SvgIcon,
  BottomNavigationAction,
  BottomNavigation,
  Paper,
} from "@material-ui/core";

const BottomMenu = styled(Paper)`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
  && {
    background-color: #1a1a1a;
  }
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  && {
    display: ${({ $isHidden }) => ($isHidden ? "none" : "block")};
    text-align: center;
    color: #d1d1d1;
    padding-bottom: 10px;
    background: ${({ $isSelected }) =>
      $isSelected ? "#E42313" : "transparent"};
    text-transform: uppercase;
    white-space: nowrap;

    ${({ $isSelected }) =>
      $isSelected
        ? `
       span.MuiBottomNavigationAction-wrapper {
          text-align: center;
          color: #fff;
          background: #E42313;
         
          
          .MuiBottomNavigationAction-label {
            font-size: 0.75rem;
          }
        }`
        : ""};
  }
`;

const MenuList = () => {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.router);

  const isMenu = getSelectedMenu();
  const selectedIndex = getSelectedIndex(location);

  const onItemClick = (destination) => {
    dispatch(push(destination));
  };

  const itemIcon = (iconName) =>
    isObject(iconName) ? (
      <SvgIcon>
        <path {...iconName} />
      </SvgIcon>
    ) : (
      <Icon>{iconName}</Icon>
    );

  const isCartItem = (itemName) =>
    [CART_MENU_NAME, CART_FULL_MENU_NAME].includes(itemName);

  let menuItems = mobileElements.map((item, index) => {
    return isCartItem(item.name) ? (
      <MenuCartItem
        item={item}
        key={item.name}
        onClick={() => onItemClick(item.to)}
        isSelected={selectedIndex === index}
      />
    ) : (
      <StyledBottomNavigationAction
        label={
          <FormattedMessage
            id={"eo." + item.name}
            defaultMessage={item.title}
          />
        }
        icon={itemIcon(item.icon)}
        key={item.name}
        onClick={() => onItemClick(item.to)}
        $isSelected={selectedIndex === index}
        $isHidden={0 === index}
      />
    );
  });

  return (
    !!isMenu && (
      <BottomMenu elevation={1}>
        {mobileElements.length > 0 ? (
          <StyledBottomNavigation value={selectedIndex} showLabels>
            {menuItems}
          </StyledBottomNavigation>
        ) : (
          <p>Loading...</p>
        )}
      </BottomMenu>
    )
  );
};

export default MenuList;
