import { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

const StyledDialog = styled(Dialog)`
  color: #333;
`;

const Title = styled.h2`
  font-size: 18px;
`;

const List = styled.ul`
  padding-left: 15px;
  font-size: 14px;
`;

const PlaceOrderErrors = ({ errors, closeCallback }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    closeCallback();
  };

  const errorList = errors.map((error, index) => <li key={index}>{error}</li>);

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogContent>
        <Title>
          <FormattedMessage
            id="eo.error-sorry"
            defaultMessage="We're sorry..."
          />
        </Title>
        <List>{errorList}</List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

PlaceOrderErrors.propTypes = {
  errors: PropTypes.array.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

export default PlaceOrderErrors;
