import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forEach } from "lodash";

import { MenuList, MenuFooter } from "../../menu";
import { Header } from "../../header";
import { Shop, ProductDetails } from "../../shop";
import { Home } from "../../home";
import { responsiveMatrix } from "../../../router";
import { fetchCountriesRequest } from "../../login/actions";
import { useCountry } from "../../../hooks/useCountry";
import { useDevice } from "../../../hooks/useDevice";
import { fetchTranslations, changeDeviceType } from "../actions";

import Providers from "./Providers";
import AppRouter from "./AppRouter";
import { AppContainer, ContentContainer } from "./App.styles";
import { useRollbar } from "@rollbar/react";
import {
  getSelectedHeaderByDevice,
  useSelectedMenu,
} from "../../menu/selectors";

const components = {
  orders: Home,
  shop: Shop,
  product: ProductDetails,
};

const App = () => {
  const dispatch = useDispatch();
  const { device } = useDevice();
  const { language } = useCountry();
  const rollbar = useRollbar();

  const { changeCountry } = useSelector((state) => state.login);
  const header = useSelector((state) => state.header?.countryOptions?.header);
  const { userId } = useSelector((state) => state.header);
  const isMenu = useSelectedMenu();
  const isHeader = getSelectedHeaderByDevice(device);
  const isMobile = device === "mobile";

  const [isReady, setIsReady] = useState(false);

  const matchDeviceToComponents = () => {
    forEach(responsiveMatrix, function (value, key) {
      if (value[device]) {
        components[key] = value[device];
      }
    });
    setIsReady(true);
  };

  useEffect(() => {
    dispatch(fetchCountriesRequest());
  }, []);

  useEffect(() => {
    dispatch(fetchTranslations(language));
  }, [language]);

  useEffect(() => {
    matchDeviceToComponents();
    dispatch(changeDeviceType(device));
  }, [device]);

  useEffect(() => {
    if (rollbar) {
      rollbar.configure({
        hostSafeList: [
          "easyorder.dev.interflon.cloud",
          "easyorder.uat.interflon.cloud",
          "easyorder.interflon.net",
        ],
        payload: {
          person: {
            id: userId || "unlogged",
          },
        },
      });
    }
  }, [rollbar, userId]);

  const calculatePaddingTop = () => {
    if (!isMobile) {
      return "0px";
    }
    if (header) {
      return "128px";
    }
    if (isHeader) {
      return "64px";
    }
    return "0px";
  };

  return (
    <Providers>
      {!changeCountry?.loader ? (
        <AppContainer className="App">
          <ContentContainer
            paddingTop={calculatePaddingTop()}
            paddingBottom={isMenu ? "56px" : "0px"}
          >
            <Header />
            {isReady && <AppRouter components={components} />}
          </ContentContainer>
          {device === "mobile" ? <MenuList /> : null}
          <MenuFooter />
        </AppContainer>
      ) : (
        <></>
      )}
    </Providers>
  );
};

export default App;
