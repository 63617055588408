import styled from "styled-components";

export const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  background: #1a1a1a;
  height: 160px;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1169px) and (min-width: 768px) {
    margin-left: 20px;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Menu = styled.div`
  display: flex;
  border-bottom: 1px solid #666;
`;
