import { useSelector } from "react-redux";
import { Container } from "./HeaderTextDesktop.styles";

const HeaderTextDesktop = () => {
  const header = useSelector((state) => state.header?.countryOptions?.header);
  return (
    <Container>
      <div
        dangerouslySetInnerHTML={{
          __html: header,
        }}
      />
    </Container>
  );
};

export default HeaderTextDesktop;
