import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { handleSigninSubmit } from "../actions";
import FormInput from "../../../components/Form/FormInput";
import Form from "../../../components/Form/Form";

const schema = yup
  .object({
    email: yup
      .string()
      .email(
        <FormattedMessage
          id="eo.invalid-email"
          defaultMessage="Invalid email address"
        />
      )
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
  })
  .required();

const SignInForm = ({ onSuccess, onError }) => {
  const dispatch = useDispatch();
  const defaultValues = {
    email: "",
  };
  const handleSubmit = (data) =>
    handleSigninSubmit(data)
      .then((result) => {
        onSuccess && onSuccess();
        dispatch(result);
      })
      .catch((error) => {
        onError && onError(error);
      });

  return (
    <div>
      <Form
        subTitle={
          <FormattedMessage
            id="eo.type-email"
            defaultMessage="Provide here your business email address"
          />
        }
        onSubmitForm={handleSubmit}
        defaultValues={defaultValues}
        schema={schema}
        btnLabel={<FormattedMessage id="eo.sign-ok" defaultMessage="OK" />}
        isCaptcha={true}
      >
        <FormInput
          name="email"
          label={
            <FormattedMessage id="eo.your-email" defaultMessage="Your e-mail" />
          }
          required={true}
        />
      </Form>
    </div>
  );
};

export default SignInForm;
