import React from 'react';

const ChangeCountryLoader = () => (
    <div style={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 10000,
        backgroundColor: 'black',
        opacity: '0.5'
    }}/>
);

export default ChangeCountryLoader;
