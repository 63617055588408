import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ListItemText } from "@material-ui/core";
import { changeVariant } from "../../actions";
import { useDispatch } from "react-redux";

import {
  Wrapper,
  StyledSelect,
  StyledMenuItem,
  Label,
} from "./VariantsSelect.styles";

const MAX_CODE_CHARACTERS_LENGTH = 10;

const getArticleNumber = (code) => {
  return code?.length <= MAX_CODE_CHARACTERS_LENGTH ? `(${code})` : null;
};

const VariantSelect = ({ variants, selectedIndex }) => {
  const dispatch = useDispatch();

  const handleChangeVariant = (variant) => {
    dispatch(changeVariant(variant));
  };

  useEffect(() => {
    return () => {
      handleChangeVariant(0);
    };
  }, []);

  let variantsList = variants.map((variant, index) => {
    const articleNumber = getArticleNumber(variant.code);
    return (
      <StyledMenuItem key={index} value={index}>
        <ListItemText
          primary={
            <span
              data-ect={index === selectedIndex ? "item-variant" : undefined}
              style={{ fontSize: "14px" }}
            >
              {variant.capacity}{" "}
              {variant.unit.translation_code ? (
                <FormattedMessage
                  id={variant.unit.translation_code}
                  defaultMessage={variant.unit.name}
                />
              ) : (
                variant.unit.name
              )}{" "}
              {variant.package.translation_code && (
                <FormattedMessage
                  id={variant.package.translation_code}
                  defaultMessage={variant.package.name}
                />
              )}
              {articleNumber && (
                <>
                  {" "}
                  <span style={{ fontSize: "12px" }}>{articleNumber}</span>
                </>
              )}
            </span>
          }
        />
      </StyledMenuItem>
    );
  });

  return (
    <Wrapper>
      <Label>
        <FormattedMessage id="eo.volume" defaultMessage="Volume" />:
      </Label>
      <StyledSelect
        value={selectedIndex}
        onChange={(event) => handleChangeVariant(event.target.value)}
        SelectDisplayProps={{ style: { padding: "4px 15px" } }}
        MenuProps={{ style: { padding: "0px" } }}
        disableUnderline
      >
        {variantsList}
      </StyledSelect>
    </Wrapper>
  );
};

VariantSelect.propTypes = {
  variants: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default VariantSelect;
