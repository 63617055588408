import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

const Title = styled.h2`
  color: #666666;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  margin: 5px 0;
`;

const EmptyProductsInfo = ({ productsLoader }) => (
  <div>
    <Title>
      {productsLoader === false && (
        <FormattedMessage
          id="eo.products-no-history"
          defaultMessage="No history of products"
        />
      )}
    </Title>
  </div>
);

EmptyProductsInfo.propTypes = {
  productsLoader: PropTypes.bool.isRequired,
};

export default EmptyProductsInfo;
