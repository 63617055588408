import styled from "styled-components";
import { Icon, IconButton } from "@material-ui/core";

export const Wrapper = styled.div`
  background: #f3f3f3;
  border-top: 2px solid #d1d1d1;
  padding: 10px;
  font-size: 12px;
`;

export const Row = styled.div`
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  & .reorder-button {
    float: none;
    margin-bottom: 10px;
    font-weight: bold;
  }

  & .request-button {
    float: none;
    font-weight: bold;
  }
`;

export const BtnQuantity = styled(IconButton)`
  && {
    padding: 0;
  }
`;

export const BtnIcon = styled(Icon)`
  color: #aada8e;
`;

export const Package = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  float: left;
`;

export const Cost = styled.div`
  float: right;
`;

export const VariantInput = styled.div`
  float: left;
  line-height: 34px;
  margin: 10px 0;
  display: flex;
  gap: 5px;
`;

export const Input = styled.input`
  width: 30px;
  height: 30px;
  padding: 0;
  vertical-align: top;
  text-align: center;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #929495;
`;

export const Price = styled.div`
  float: right;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  margin: 10px 0;
`;

export const Discount = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;
