import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";

import {
  Profile,
  ChangePassword,
  ChangeLanguage,
  ChangeDeliveryAddress,
} from "../../header";
import { Cart, CartEdit } from "../../cart";
import { Contact } from "../../contact";
import SignIn from "../../contact/components/SignIn";
import Request from "../../contact/components/Request";
import { Login, Activate, MoreInfo } from "../../login";
import { Dashboard } from "../../dashboard";
import HelpSignIn from "../../login/components/HelpSignIn";
import { NotSupportedCountry } from "../../login";
import HelpForgotPassword from "../../login/components/HelpForgotPassword";
import {
  ValidateSupportedCountryRoute,
  NotSupportedCountryRoute,
  ProtectedRoute,
  history,
} from "../../../router";

import NotFound from "./NotFound";
import ProfileLegal from "../../header/components/ProfileLegal";

const AppRouter = ({ components }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <ProtectedRoute exact path="/" component={Dashboard} />
        <ProtectedRoute path="/orders" component={components["orders"]} />
        <ValidateSupportedCountryRoute
          path="/shop"
          component={components["shop"]}
        />
        <ValidateSupportedCountryRoute
          path="/product/:productCategoryId/:productId"
          component={components["product"]}
        />
        <ValidateSupportedCountryRoute
          path="/product/:productId"
          component={components["product"]}
        />
        <ValidateSupportedCountryRoute
          path="/contact"
          exact
          component={Contact}
        />
        <ValidateSupportedCountryRoute
          path="/contact/signin"
          component={SignIn}
        />
        <ValidateSupportedCountryRoute
          path="/contact/request/:productName"
          component={Request}
        />
        <ValidateSupportedCountryRoute
          path="/contact/request"
          component={Request}
        />
        <ValidateSupportedCountryRoute path="/cart" component={Cart} />
        <ValidateSupportedCountryRoute path="/cart-edit" component={CartEdit} />
        <ValidateSupportedCountryRoute path="/login" exact component={Login} />
        <ValidateSupportedCountryRoute
          path="/login/help/signin"
          exact
          component={HelpSignIn}
        />
        <ValidateSupportedCountryRoute
          path="/login/help/forgotpassword"
          exact
          component={HelpForgotPassword}
        />
        <ValidateSupportedCountryRoute
          path="/activate/:token/:country/:language"
          component={Activate}
        />
        <ValidateSupportedCountryRoute
          path="/profile/"
          exact
          component={Profile}
        />
        <ValidateSupportedCountryRoute
          path="/profile/password"
          exact
          component={ChangePassword}
        />
        <ValidateSupportedCountryRoute
          path="/profile/language"
          exact
          component={ChangeLanguage}
        />
        <ValidateSupportedCountryRoute
          path="/profile/legal"
          exact
          component={ProfileLegal}
        />
        <ValidateSupportedCountryRoute
          path="/profile/delivery"
          exact
          component={ChangeDeliveryAddress}
        />
        <ValidateSupportedCountryRoute
          path="/more-info/:token/:country/:language"
          component={MoreInfo}
        />
        <ValidateSupportedCountryRoute
          path="/search"
          component={components["search"]}
        />
        <NotSupportedCountryRoute
          path="/country"
          component={NotSupportedCountry}
        />
        <Route component={NotFound} />
      </Switch>
    </ConnectedRouter>
  );
};

export default AppRouter;
