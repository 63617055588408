import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderLogoDesktop from "./HeaderLogoDesktop";
import MenuListDesktop from "../../../menu/components/MenuListDesktop";
import UserBarDesktop from "./UserBarDesktop";
import { fetchContactPerson } from "../../actions";
import { fetchBasket } from "../../../cart/actions";
import { getSelectedHeaderByDevice } from "../../../menu/selectors";
import { SearchInputDesktop } from "../../../search";

import {
  StyledHeader,
  Container,
  Menu,
  LogoContainer,
  MenuContainer,
} from "./HeaderDesktop.styles";
import HeaderTextDesktop from "./HeaderTextDesktop";

const HeaderDesktop = () => {
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.login);
  const { deviceType } = useSelector((state) => state.translations);
  const { location } = useSelector((state) => state.router);

  const [isHeader, setIsHeader] = useState(false);

  const fetchContactPersonData = () => {
    dispatch(fetchContactPerson());
    dispatch(fetchBasket());
  };

  useEffect(() => {
    if (logged) {
      fetchContactPersonData();
    }
  }, [logged]);

  useEffect(() => {
    setIsHeader(getSelectedHeaderByDevice(deviceType));
  }, [deviceType, location]);

  const showSearchInput = location.pathname !== "/country";

  return (
    !!isHeader && (
      <StyledHeader>
        <Container>
          <LogoContainer>
            <HeaderLogoDesktop />
            {showSearchInput && <SearchInputDesktop />}
          </LogoContainer>
          <MenuContainer>
            <Menu>
              <div>
                <HeaderTextDesktop />
                <MenuListDesktop />
              </div>
              <UserBarDesktop />
            </Menu>
          </MenuContainer>
        </Container>
      </StyledHeader>
    )
  );
};

export default HeaderDesktop;
