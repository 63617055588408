import Pagination from "@material-ui/lab/Pagination";
import { push } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ImageWithPlaceholder } from "../../../../components/ImageWithPlaceholder/ImageWithPlaceholder";
import { FiltersDesktop } from "../../../filters";
import { showProduct } from "../../../shop/actions";
import {
  Details,
  Photo,
  ShopContainer,
  ShopItem,
  ShopList,
} from "../../../shop/components/Shop/ShopDesktop";
import SearchMessage from "./SearchMessage";
import useSearch from "./useSearch";
import ShopLoaderDesktop from "../../../shop/components/Shop/ShopLoaderDesktop";

const SearchResultsHeader = styled.div`
  margin-top: 39px;
  font-size: 18px;
`;

const ShopListContainer = styled.div`
  margin-left: -4px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 131px;
`;

const PaginationStyled = styled(Pagination)`
  && {
    .Mui-selected {
      background-color: transparent;
      border-bottom: 2px solid #e42313;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const PAGE_SIZE = 12;

export const SearchResultsDesktop = ({ location, query, page, tags }) => {
  const queryPage = Number(page);
  const dispatch = useDispatch();
  const from = (page - 1) * PAGE_SIZE;
  const to = (page - 1) * PAGE_SIZE + PAGE_SIZE;
  const { products, queryProducts, filteredProducts, loading } = useSearch(
    query,
    from,
    to,
    tags,
    "tags"
  );

  const handlePageChange = (event, page) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page.toString());
    const newUrl = location.pathname + "?" + searchParams.toString();
    dispatch(push(newUrl));
  };

  if (loading) {
    return (
      <ShopContainer>
        <ShopList>
          <ShopItem>
            <ShopLoaderDesktop />
          </ShopItem>
        </ShopList>
      </ShopContainer>
    );
  }

  let productsList = products.map((product, index) => (
    <ShopItem
      key={index}
      onClick={() =>
        dispatch(
          showProduct(
            `/product/${product.categories[0]?.id}/${product.groupCode}`
          )
        )
      }
    >
      <Photo>
        <ImageWithPlaceholder
          src={product?.image?.replace("tiny", "small")}
          alt={product.name}
        />
      </Photo>
      <Details>
        <h2>{product.name}</h2>
        <p>{product.lead}</p>
      </Details>
    </ShopItem>
  ));

  return (
    <ShopContainer>
      <FiltersDesktop
        products={queryProducts}
        filterPer={"tags"}
        filteredProducts={filteredProducts}
      />
      <SearchResultsHeader>
        <FormattedMessage
          id="eo.search-results"
          defaultMessage="Search results: ({amount})"
          values={{ amount: filteredProducts?.length }}
        />
      </SearchResultsHeader>
      {productsList.length > 0 ? (
        <>
          <ShopListContainer>
            <ShopList>{productsList}</ShopList>
            <PaginationContainer>
              <PaginationStyled
                count={Math.ceil(filteredProducts?.length / PAGE_SIZE)}
                shape="rounded"
                size="large"
                onChange={handlePageChange}
                page={queryPage}
              />
            </PaginationContainer>
          </ShopListContainer>
        </>
      ) : (
        <SearchMessage id="eo.search-no-results" defaultMessage="No results" />
      )}
    </ShopContainer>
  );
};
