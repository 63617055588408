import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { push } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { history } from "../../../router";
import { useUniqueFilters } from "../service/filters.service";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 25px 0 0 0;
`;

const StyledLabel = styled(Typography)`
  && {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    height: 24px;
    margin: 0;
    padding: 0;
    margin: 4px 20px 4px 0;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 4px;
  }
`;

const FiltersDesktop = ({ products, filterPer, filteredProducts }) => {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(history.location.search);
  const filters = searchParams.getAll(filterPer);
  const uniqueFilters = useUniqueFilters(
    filteredProducts ?? products,
    filterPer
  );

  const handleChange = (event) => {
    if (event.target.checked) {
      searchParams.append(filterPer, event.target.name);
    } else {
      searchParams.delete(filterPer, event.target.name);
    }
    if (history.location.pathname === "/search") {
      searchParams.set("page", "1");
    }
    const newUrl = history.location.pathname + "?" + searchParams.toString();
    dispatch(push(newUrl));
  };

  return uniqueFilters?.length ? (
    <Container>
      {uniqueFilters.map((filter, index) => {
        const isChecked = filters.includes(String(filter.id));
        return (
          <StyledFormControlLabel
            color="primary"
            key={index}
            control={
              <StyledCheckbox
                color="primary"
                checked={isChecked}
                name={String(filter.id)}
              />
            }
            label={
              <StyledLabel>
                <FormattedMessage
                  id={filter.translationKey}
                  defaultMessage={filter.name}
                />
              </StyledLabel>
            }
            onChange={handleChange}
          />
        );
      })}
    </Container>
  ) : null;
};

export default FiltersDesktop;
