import React from "react";
import { Icon } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VariantPackage = styled.div`
  float: left;
  width: 50%;
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
`;

const VariantUnavailable = styled.div`
  color: #666;
  text-transform: uppercase;
  line-height: 24px;
  margin: 5px 0 0;
  font-weight: 500;
  display: flex;
  gap: 5px;
`;

const ProductVariantNotAvailable = ({ variant }) => (
  <Wrapper>
    <div>
      <VariantPackage>
        {variant.capacity}{" "}
        {variant.unit.translation_code ? (
          <FormattedMessage
            id={variant.unit.translation_code}
            defaultMessage={variant.unit.name}
          />
        ) : (
          variant.unit.name
        )}
      </VariantPackage>
    </div>
    <VariantUnavailable>
      <Icon>do_not_disturb</Icon>
      <FormattedMessage
        id="eo.product-unavailable"
        defaultMessage="Product not available"
      />
    </VariantUnavailable>
  </Wrapper>
);

ProductVariantNotAvailable.propTypes = {
  variant: PropTypes.object.isRequired,
};

export default ProductVariantNotAvailable;
