import styled from "styled-components";
import bgImg from "../../app/images/bg.jpg";
import { withTheme } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import SvgIcon from "@material-ui/core/SvgIcon";

export const Container = styled.div`
  background: #fff url(${bgImg}) top center no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;

  @media screen and (min-width: 768px) {
    display: flex;
    position: static;
  }
`;

export const InnerContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: 768px;
    margin: 0 auto;
    background: none;
    position: relative;
  }

  @media screen and (min-width: 1170px) {
    width: 1170px;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  margin: 10px;

  @media screen and (min-width: 768px) {
    margin: 30px 0;
  }
`;

export const Item = styled.div`
  background: #fff;
  border: 2px solid #d1d1d1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 15px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: #333;
  cursor: pointer;
`;

export const StyledIcon = withTheme(styled(Icon)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 15px;
  fill: ${({ theme }) => theme.palette.primary.main};
`);

export const StyledSvgIcon = withTheme(styled(SvgIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 15px;
  fill: ${({ theme }) => theme.palette.primary.main};
`);

export const HeaderTextContainer = styled.div`
  margin: 10px;
`;
