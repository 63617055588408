import styled from "styled-components";
import { Card, CardContent, CardHeader } from "@material-ui/core";

export const Container = styled.div`
  padding: 0 16px;
`;

export const StyledCard = styled(Card)`
  && {
    box-shadow: none;
    border-bottom: 2px dotted #929495;
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  && {
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    color: #e42313;
    text-transform: uppercase;
    padding-left: 0;
    cursor: pointer;

    .MuiTypography-h5 {
      font-size: 14px;
      font-weight: 700;
      line-height: 26px;
    }

    button {
      color: #e42313;
    }
  }
`;

export const StyledCardContent = styled(CardContent)`
  && {
    padding: 8px 0 5px;
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;
