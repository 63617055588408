import React from "react";
import uuid from "uuid";

const idGradient = uuid.v1();
const idClip = uuid.v1();

const ActivationLoader = (props) => (
  <svg viewBox="0 0 310 160" style={{ margin: "0 9px" }} {...props}>
    <rect
      style={{ fill: `url(#${idGradient})` }}
      clipPath={`url("#${idClip}")`}
      x="0"
      y="0"
      width="300"
      height="160"
    />

    <defs>
      <clipPath id={idClip}>
        <rect x="10" y="40" rx="5" ry="5" width="290" height="40" />
        <rect x="10" y="100" rx="5" ry="5" width="290" height="40" />
      </clipPath>

      <linearGradient id={idGradient}>
        <stop offset="0%" stopColor="#f0f0f0">
          <animate
            attributeName="offset"
            values="-2; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="50%" stopColor="#e0e0e0">
          <animate
            attributeName="offset"
            values="-1.5; 1.5"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="100%" stopColor="#f0f0f0">
          <animate
            attributeName="offset"
            values="-1; 2"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
);

export default ActivationLoader;
