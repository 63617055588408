import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import keys from "lodash/keys";
import isEmpty from "lodash/isEmpty";
import { Icon, Button } from "@material-ui/core";
import { fetchPrice, requestDemoCall } from "../../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import { addToBasket } from "../../../cart/actions";

import {
  Wrapper,
  Package,
  BtnQuantity,
  BtnIcon,
  Cost,
  Row,
  Price,
  Input,
  Discount,
  VariantInput,
} from "./VariantDetails.styles";

const MAX_QUANTITY = 999;

const VariantDetails = ({ variant, productName }) => {
  const dispatch = useDispatch();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [requestSent, setRequestSent] = useState(null);
  const { currency } = useSelector((state) => state.header);
  const { changeCartRequest } = useSelector((state) => state.cart);
  const intl = useIntl();

  const requestDemoCallCallback = (productId) => {
    setRequestInProgress(true);
    requestDemoCall(productId)
      .then(() => {
        setRequestSent(true);
      })
      .catch(() => {
        setRequestSent(false);
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  };

  const priceCallback = (productId, value, quantity) => {
    dispatch(fetchPrice(productId, value, quantity));
  };

  const handleAddToBasket = (data) => {
    dispatch(addToBasket(data));
  };

  const increaseQuantity = (variantId, amount, quantity) => {
    quantity = parseInt(quantity, 10);
    if (quantity < MAX_QUANTITY) {
      const newQuantity = quantity + 1;
      const qty = amount * newQuantity;
      priceCallback(variantId, newQuantity, qty);
    }
  };

  const decreaseQuantity = (variantId, amount, quantity) => {
    quantity = parseInt(quantity, 10);
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      const qty = amount * newQuantity;
      priceCallback(variantId, newQuantity, qty);
    }
  };

  const onQuantityChange = (quantity, amount, variantId) => {
    quantity = parseInt(quantity, 10);
    if (quantity > 0 && quantity <= MAX_QUANTITY) {
      const qty = amount * quantity;
      priceCallback(variantId, parseInt(quantity, 10), qty);
    }
  };

  const renderAddToCartButton = (cartData) => {
    if (changeCartRequest && changeCartRequest.code === cartData.code) {
      return (
        <Button
          className="reorder-button product-AddToCart--request"
          fullWidth={true}
          variant="contained"
          color="primary"
          style={{ boxShadow: "none", maxWidth: "none", width: "100%" }}
          onClick={() => handleAddToBasket(cartData)}
          data-ect="add-to-cart"
        >
          <BtnIcon>add_shopping_cart</BtnIcon>
          &nbsp;
          <FormattedMessage id="eo.added" defaultMessage="Added" />
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        className="reorder-button"
        fullWidth={true}
        style={{ boxShadow: "none", maxWidth: "none", width: "100%" }}
        onClick={() => handleAddToBasket(cartData)}
        data-ect="add-to-cart"
      >
        <FormattedMessage id="eo.add" defaultMessage="Add to cart" />
      </Button>
    );
  };

  const calculateDiscount = (variant) => {
    const discountAmount = parseInt(keys(variant.nextSegment)[0], 10);
    const discountPrice = parseFloat(variant.nextSegment[discountAmount]);

    const more = discountAmount / variant.amount - variant.quantity;
    const discountTotal = discountAmount * discountPrice;
    const currentTotal = variant.price * discountAmount;
    const save = currentTotal - discountTotal;

    if (save > 0) {
      return (
        <FormattedMessage
          id="eo.discount-info"
          defaultMessage="Order {more} more and save {currency} {save}"
          values={{
            more: Math.ceil(more),
            currency: currency,
            save: <FormattedNumber minimumFractionDigits={2} value={save} />,
          }}
        />
      );
    }

    return "";
  };

  return (
    <Wrapper>
      <Row>
        <Package>
          {variant.amount > 1 && (
            <FormattedMessage
              id="eo.pack"
              defaultMessage="Pack {amount} x "
              values={{ amount: variant.amount }}
            />
          )}
          {variant.capacity}{" "}
          {variant.unit.translation_code ? (
            <FormattedMessage
              id={variant.unit.translation_code}
              defaultMessage={variant.unit.name}
            />
          ) : (
            variant.unit.name
          )}
        </Package>
        <Cost>
          <FormattedMessage id="eo.unit-price" defaultMessage="Unit price" />:
          &nbsp;{currency}{" "}
          <FormattedNumber minimumFractionDigits={2} value={variant.price} />
        </Cost>
      </Row>
      <Row>
        <VariantInput>
          <BtnQuantity
            onClick={() =>
              decreaseQuantity(
                variant.variantId,
                variant.amount,
                variant.quantity
              )
            }
          >
            <Icon color="primary">remove_circle_outline</Icon>
          </BtnQuantity>
          <Input
            type="number"
            min="1"
            max="999"
            name="quantity"
            value={variant.quantity}
            onChange={(e) =>
              onQuantityChange(
                e.target.value,
                variant.amount,
                variant.variantId
              )
            }
            data-ect="item-quantity"
          />
          <BtnQuantity
            onClick={() =>
              increaseQuantity(
                variant.variantId,
                variant.amount,
                variant.quantity
              )
            }
          >
            <Icon color="primary">add_circle_outline</Icon>
          </BtnQuantity>
        </VariantInput>
        <Price data-ect="item-price">
          {currency}{" "}
          <FormattedNumber
            minimumFractionDigits={2}
            value={variant.price * variant.amount * variant.quantity}
          />
        </Price>
      </Row>
      <Discount>
        {variant.nextSegment &&
          !isEmpty(variant.nextSegment) &&
          calculateDiscount(variant)}
      </Discount>
      <Row>
        {renderAddToCartButton({
          code: variant.code,
          quantity: variant.quantity,
          price: variant.price,
          amount: variant.amount,
          capacity: (
            variant.capacity +
            " " +
            (variant.unit.translation_code
              ? intl.formatMessage({
                  id: variant.unit.translation_code,
                  defaultMessage: variant.unit.name,
                })
              : variant.unit.name)
          ).trim(),
          package: variant.package,
          name: productName,
          currency: currency,
        })}

        <Button
          fullWidth={true}
          variant="contained"
          style={{ boxShadow: "none", maxWidth: "none" }}
          onClick={() => requestDemoCallCallback(variant.variantId)}
          endIcon={
            requestInProgress ? (
              <CircularProgress size={12} />
            ) : (
              <>
                {requestSent && <CheckIcon size={12} />}
                {requestSent === false && <ErrorIcon size={12} />}
              </>
            )
          }
          disabled={requestInProgress}
        >
          <FormattedMessage
            id={
              requestSent
                ? "eo.presentation-requested"
                : "eo.presentation-request"
            }
            defaultMessage={
              requestSent ? "Presentation requested" : "Request a presentation"
            }
          />
        </Button>
      </Row>
    </Wrapper>
  );
};

VariantDetails.propTypes = {
  variant: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
};

export default VariantDetails;
