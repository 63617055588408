export const FETCH_ORDERS_REQUEST = 'home/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'home/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'home/FETCH_ORDERS_FAILURE';
export const FETCH_FILTERED_ORDERS_SUCCESS = 'home/FETCH_FILTERED_ORDERS_SUCCESS';
export const FETCH_FILTERED_ORDERS_FAILURE = 'home/FETCH_FILTERED_ORDERS_FAILURE';

export const FETCH_ORDERS_AUTHORS_REQUEST = 'home/FETCH_ORDERS_AUTHORS_REQUEST';
export const FETCH_ORDERS_AUTHORS_SUCCESS = 'home/FETCH_ORDERS_AUTHORS_SUCCESS';
export const FETCH_ORDERS_AUTHORS_FAILURE = 'home/FETCH_ORDERS_AUTHORS_FAILURE';

export const FETCH_PRODUCTS_REQUEST = 'home/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'home/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'home/FETCH_PRODUCTS_FAILURE';

export const FETCH_PRICE_REQUEST = 'home/FETCH_PRICE_REQUEST';
export const FETCH_PRICE_SUCCESS = 'home/FETCH_PRICE_SUCCESS';
export const FETCH_PRICE_FAILURE = 'home/FETCH_PRICE_FAILURE';

export const APPLY_FILTERS = 'home/APPLY_FILTERS';
export const CLEAR_FILTERS = 'home/CLEAR_FILTERS';

export const CLOSE_NEW_ORDER_REGISTERED_INFO = 'home/CLOSE_NEW_ORDER_REGISTERED_INFO';
export const SHOW_NEW_ORDER_EXPANDED = 'home/SHOW_NEW_ORDER_EXPANDED';

export const CLEAR_ORDERS_HISTORY = 'home/CLEAR_ORDERS_HISTORY';

export const FETCH_RECOMMENDATIONS_REQUEST = 'home/FETCH_RECOMMENDATIONS_REQUEST';
export const FETCH_RECOMMENDATIONS_SUCCESS = 'home/FETCH_RECOMMENDATIONS_SUCCESS';
export const FETCH_RECOMMENDATIONS_FAILURE = 'home/FETCH_RECOMMENDATIONS_FAILURE';