export const FETCH_CATALOGUE_REQUEST = "shop/FETCH_CATALOGUE_REQUEST";
export const FETCH_CATALOGUE_SUCCESS = "shop/FETCH_CATALOGUE_SUCCESS";
export const FETCH_CATALOGUE_FAIL = "shop/FETCH_CATALOGUE_FAIL";

export const FETCH_PRODUCT_REQUEST = "shop/FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "shop/FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAIL = "shop/FETCH_PRODUCT_FAIL";

export const CHANGE_CATEGORY = "shop/CHANGE_CATEGORY";
export const CHANGE_VARIANT = "shop/CHANGE_VARIANT";

export const FETCH_PRICE_REQUEST = "shop/FETCH_PRICE_REQUEST";
export const FETCH_PRICE_SUCCESS = "shop/FETCH_PRICE_SUCCESS";
export const FETCH_PRICE_FAILURE = "shop/FETCH_PRICE_FAILURE";

export const CLEAR_PRODUCT_DETAILS = "shop/CLEAR_PRODUCT_DETAILS";

export const REQUEST_FREE_TRAINING_REQUEST =
  "shop/REQUEST_FREE_TRAINING_REQUEST";
export const REQUEST_FREE_TRAINING_FAILURE =
  "shop/REQUEST_FREE_TRAINING_FAILURE";
