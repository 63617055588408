import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import Popover from "@material-ui/core/Popover";
import * as t from "../../cart/actionTypes";

import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import { push } from "connected-react-router";

const Pointer = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #f3f3f3;
  margin-left: 8px;
`;

const StyledDetails = styled.div`
  background: #f3f3f3;
  border-radius: 4px;
  padding: 20px 20px 10px 20px;
  font-size: 12px;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.19);
`;

const StyledRow = styled.div`
  padding-bottom: 10px;
`;

const MenuCartPopoverDesktop = ({ anchorEl }) => {
  const dispatch = useDispatch();

  const { items, cartPopoverIsOpen } = useSelector((state) => state.cart);

  const handleRequestClose = () => {
    dispatch({ type: t.CART_POPOVER_CLOSE });
  };

  const handleGoToBasket = () => {
    dispatch(push("/cart"));
    dispatch({ type: t.CART_POPOVER_CLOSE });
  };

  return (
    anchorEl && (
      <Popover
        open={cartPopoverIsOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        onClose={handleRequestClose}
      >
        <Pointer />
        <StyledDetails>
          <StyledRow>
            <FormattedMessage
              id="eo.cart-popover-message"
              defaultMessage={`You have {itemsCount, number} {itemsCount, plural, one {item} other {items}} in your basket`}
              values={{ itemsCount: items.length }}
            />
          </StyledRow>
          <StyledRow>
            <Button
              fullWidth={true}
              variant="contained"
              onClick={handleGoToBasket}
            >
              <FormattedMessage
                id="eo.go-to-cart"
                defaultMessage="Go to cart"
              />
            </Button>
          </StyledRow>
        </StyledDetails>
      </Popover>
    )
  );
};

MenuCartPopoverDesktop.propTypes = {
  anchorEl: PropTypes.object.isRequired,
};

export default MenuCartPopoverDesktop;
