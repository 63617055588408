import React, { useState } from "react";
import ProductVariant from "./ProductVariant";
import MoreVariants from "./MoreVariants";
import LessVariants from "./LessVariants";
import ProductVariantNotAvailable from "./ProductVariantNotAvailable";
import PropTypes from "prop-types";
import styled from "styled-components";

const List = styled.ul`
  list-style: none;
  padding: 0;
  background: #f3f3f3;
`;

const Item = styled.li`
  padding: 12px 12px 5px 12px;

  &:nth-child(1n + 2) {
    border-top: 2px dotted #929495;
    padding-top: 16px;
  }
  &:last-child {
    padding-bottom: 12px;
    margin-bottom: 0;
  }
`;

const ProductVariants = ({
  variants,
  productName,
  addCallback,
  priceCallback,
  currency,
  cartState,
}) => {
  const [moreVariants, setMoreVariants] = useState(false);

  const showMoreVariants = () => {
    setMoreVariants(true);
  };

  const showLessVariants = () => {
    setMoreVariants(false);
  };

  const shouldExpand = (wasOrdered, index) => {
    return wasOrdered !== false && index < 2;
  };

  let moreElementsCounter = 1;

  const variantsList = variants.map((variant, index) => {
    if (shouldExpand(variant.wasOrdered, index) || moreVariants) {
      moreElementsCounter = index === 1 && variant.wasOrdered ? 2 : 1;
      return (
        <Item key={variant.variantId}>
          {variant.isAvailable ? (
            <ProductVariant
              variant={variant}
              productName={productName}
              priceCallback={priceCallback}
              addCallback={addCallback}
              currency={currency}
              cartState={cartState}
            />
          ) : (
            <ProductVariantNotAvailable variant={variant} />
          )}
        </Item>
      );
    }

    return "";
  });

  return (
    <div>
      <List>{variantsList}</List>
      {variants.length > moreElementsCounter ? (
        moreVariants ? (
          <LessVariants onClick={showLessVariants} />
        ) : (
          <MoreVariants onClick={showMoreVariants} />
        )
      ) : null}
    </div>
  );
};

ProductVariants.propTypes = {
  variants: PropTypes.array.isRequired,
  productName: PropTypes.string.isRequired,
  priceCallback: PropTypes.func.isRequired,
  addCallback: PropTypes.func.isRequired,
  currency: PropTypes.string,
  cartState: PropTypes.object,
};

export default ProductVariants;
