export const FETCH_TA_REQUEST = "contact/FETCH_TA_REQUEST";
export const FETCH_TA_SUCCESS = "contact/FETCH_TA_SUCCESS";
export const FETCH_TA_FAILURE = "contact/FETCH_TA_FAILURE";

export const FORM_REQUEST_SUBMIT_SUCCESS =
  "contact/FORM_REQUEST_SUBMIT_SUCCESS";
export const FORM_SIGNIN_SUBMIT_SUCCESS = "contact/FORM_SIGNIN_SUBMIT_SUCCESS";

export const SWITCH_UNLOGGED_CONTACT_TO = "const/SWITCH_UNLOGGED_CONTACT_TO";

export const SWITCH_TO_REQUEST_FORM = "const/SWITCH_TO_REQUEST_FORM";
export const SWITCH_TO_SIGNIN_FORM = "const/SWITCH_TO_SIGNIN_FORM";
