export const ADD_ITEM_TO_BASKET_REQUEST = 'cart/ADD_ITEM_TO_BASKET_REQUEST';
export const ADD_ITEM_IN_BASKET_SUCCESS = 'cart/ADD_ITEM_IN_BASKET_SUCCESS';
export const ADD_ITEM_TO_BASKET_FAILURE = 'cart/ADD_ITEM_TO_BASKET_FAILURE';

export const UPDATE_ITEM_IN_BASKET_REQUEST = 'cart/UPDATE_ITEM_IN_BASKET_REQUEST';
export const UPDATE_ITEM_IN_EDIT_BASKET_REQUEST = 'cart/UPDATE_ITEM_IN_EDIT_BASKET_REQUEST';
export const UPDATE_ITEM_IN_BASKET_SUCCESS = 'cart/UPDATE_ITEM_IN_BASKET_SUCCESS';
export const UPDATE_ITEM_IN_BASKET_FAILURE = 'cart/UPDATE_ITEM_IN_BASKET_FAILURE';

export const DELETE_ITEM_FROM_BASKET_REQUEST = 'cart/DELETE_ITEM_FROM_BASKET_REQUEST';
export const DELETE_ITEM_FROM_BASKET_SUCCESS = 'cart/DELETE_ITEM_FROM_BASKET_SUCCESS';
export const DELETE_ITEM_FROM_BASKET_FAILURE = 'cart/DELETE_ITEM_FROM_BASKET_FAILURE';

export const CLEAR_BASKET_REQUEST = 'cart/CLEAR_BASKET_REQUEST';
export const CLEAR_BASKET_SUCCESS = 'cart/CLEAR_BASKET_SUCCESS';
export const CLEAR_BASKET_FAILURE = 'cart/CLEAR_BASKET_FAILURE';

export const FETCH_BASKET_REQUEST = 'cart/FETCH_BASKET_REQUEST';
export const FETCH_BASKET_SUCCESS = 'cart/FETCH_BASKET_SUCCESS';
export const FETCH_BASKET_FAILURE = 'cart/FETCH_BASKET_FAILURE';

export const FETCH_ORDER_DETAILS_REQUEST = 'cart/FETCH_ORDER_DETAILS_REQUEST';
export const FETCH_ORDER_DETAILS_SUCCESS = 'cart/FETCH_ORDER_DETAILS_SUCCESS';
export const FETCH_ORDER_DETAILS_FAILURE = 'cart/FETCH_ORDER_DETAILS_FAILURE';

export const PLACE_ORDER_REQUEST = 'cart/PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'cart/PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'cart/PLACE_ORDER_FAILURE';
export const PLACE_ORDER_ERRORS_CLOSE = 'cart/PLACE_ORDER_ERRORS_CLOSE';

export const REORDER_REQUEST = 'cart/REORDER_REQUEST';
export const REORDER_SUCCESS = 'cart/REORDER_SUCCESS';
export const REORDER_FAILURE = 'cart/REORDER_FAILURE';

export const PRODUCT_UNAVAILABLE = 'cart/PRODUCT_UNAVAILABLE';
export const PRODUCT_UNAVAILABLE_CLOSE = 'cart/PRODUCT_UNAVAILABLE_CLOSE';

export const CART_POPOVER_CLOSE = 'cart/CART_POPOVER_CLOSE';

export const CLOSE_FREE_TRAININGS_DIALOG = 'cart/CLOSE_FREE_TRAININGS_DIALOG';