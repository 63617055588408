import React, { useState } from "react";
import styled from "styled-components";
import { Tabs, Tab } from "@material-ui/core";
import MyOrders from "./MyOrders/MyOrders";
import MyProducts from "./MyProducts/MyProducts";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
  width: 740px;
  margin: 0 auto;

  @media screen and (min-width: 1170px) {
    width: 1130px;
  }
`;

const StyledTabs = styled(Tabs)`
  && {
    margin: 0 auto;
    text-align: center;
    max-width: 300px;
    margin-top: -38px;
  }
`;

const StyledTab = styled(Tab)`
  && {
    min-height: auto;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    min-width: auto;
    font-size: 12px;
    height: 38px;
    background: ${({ $isActive }) => ($isActive ? "#fff" : "transparent")};
    color: ${({ $isActive }) => ($isActive ? "#000" : "#d1d1d1")};
  }
`;

const HomeDesktop = () => {
  const [value, setValue] = useState("orders");

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <Container>
      <StyledTabs
        onChange={handleChange}
        value={value}
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        <StyledTab
          $isActive={value === "orders"}
          label={
            <FormattedMessage id="eo.myorders" defaultMessage="My Orders" />
          }
          value="orders"
        />
        <StyledTab
          $isActive={value === "products"}
          label={
            <FormattedMessage id="eo.myproducts" defaultMessage="My Products" />
          }
          value="products"
        />
      </StyledTabs>
      <MyOrders value={value} index="orders" />
      <MyProducts value={value} index="products" />
    </Container>
  );
};

export default HomeDesktop;
