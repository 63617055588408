import styled from "styled-components";
import Icon from "@material-ui/core/Icon";
import { withTheme } from "@material-ui/core/styles";
import LogoIcon from "../../../components/Icons/Logo/Logo";

export const Container = styled.div`
  height: 120px;
  margin-bottom: 20px;
`;

export const Logo = styled.div`
  margin-left: 15px;
  margin-right: 20px;
  margin-top: 15px;
  float: left;
  cursor: pointer;

  .App-logo {
    margin-top: 0;
  }
`;

export const User = styled.div`
  float: left;
  margin-top: 35px;
  height: 65px;
  border-left: 1px solid #666;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-left: 15px;
  cursor: pointer;
`;

export const UserName = styled.span`
  width: 85px;
  font-size: 16px;
  color: #333;
  font-weight: 800;
`;

export const UserIcon = styled(Icon)`
  font-size: 30px;
  color: #999;
`;

export const ArrowIcon = withTheme(styled(Icon)`
  color: ${({ theme }) => theme.palette.primary.main};
`);

export const StyledImg = styled(LogoIcon)`
  display: inline-block;
  margin: 20px auto 0 auto;
  width: 100px;
  height: 65px;
`;
