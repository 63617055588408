import React from "react";
import { FormattedMessage } from "react-intl";
import { handleForgotPasswordSubmit } from "../actions";
import FormInput from "../../../components/Form/FormInput";
import Form from "../../../components/Form/Form";
import * as yup from "yup";

const schema = yup
  .object({
    email: yup
      .string()
      .email(
        <FormattedMessage
          id="eo.invalid-email"
          defaultMessage="Invalid email address"
        />
      )
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
  })
  .required();

const ForgotPasswordForm = ({ onSuccess, onError }) => {
  const defaultValues = {
    email: "",
  };
  const onSubmit = (data) =>
    handleForgotPasswordSubmit(data)
      .then(() => {
        onSuccess && onSuccess();
      })
      .catch(() => {
        onError && onError();
      });

  return (
    <div>
      <Form
        subTitle={
          <FormattedMessage
            id="eo.type-email"
            defaultMessage="Provide here your business email address"
          />
        }
        onSubmitForm={onSubmit}
        defaultValues={defaultValues}
        schema={schema}
        btnLabel={<FormattedMessage id="eo.ok" defaultMessage="OK" />}
        isCaptcha={true}
      >
        <FormInput
          name="email"
          label={
            <FormattedMessage id="eo.your-email" defaultMessage="Your e-mail" />
          }
        />
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
