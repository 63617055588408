import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import SvgIcon from "@material-ui/core/SvgIcon";
import { OrdersIcon } from "../../../core/icons/OrdersIcon";
import { SentStatusIcon } from "../../../core/icons/SentStatusIcon";
import { OrderPlacedIcon } from "../../../core/icons/OrderPlacedIcon";

const Container = styled.div`
  color: #aaa;
  display: flex;
  align-items: center;
`;

const Name = styled.span`
  display: inline-block;
  line-height: 24px;
  text-transform: uppercase;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
`;

const StyledIcon = styled(SvgIcon)`
  && {
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
`;

const OrderStatus = ({ status }) => {
  const iconForStatus =
    status === "sent" ? (
      <path {...SentStatusIcon} />
    ) : status === "in-progress" ? (
      <path {...OrdersIcon} />
    ) : (
      <path {...OrderPlacedIcon} />
    );

  return (
    <Container>
      <StyledIcon>{iconForStatus}</StyledIcon>
      <Name>
        <FormattedMessage id={"eo." + status} defaultMessage={status} />
      </Name>
    </Container>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default OrderStatus;
