const whatsAppUrl = (deviceType) => {
  return deviceType === "desktop"
    ? "https://web.whatsapp.com/send?phone="
    : "https://api.whatsapp.com/send?phone=";
};

const clearPhoneNumber = (phone) => {
  return phone && phone.replace(/[^\d]/g, "");
};

export { whatsAppUrl, clearPhoneNumber };
