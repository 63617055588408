import React from "react";
import { useDispatch } from "react-redux";
import { FormattedNumber, FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

const MAX_QUANTITY = 999;

const StyledButton = styled(Button)`
  box-shadow: none;
  margin-top: 0;
  width: 160px;
  max-width: 200px;
`;

const BtnIcon = styled(Icon)`
  color: #aada8e;
`;

const BtnQuantity = styled(IconButton)`
  && {
    margin: 0;
    padding: 5px;
    width: 30px;
    height: 30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VariantPackage = styled.div`
  float: left;
  width: 50%;
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
`;

const HistoryPrice = styled.div`
  float: left;
  width: 50%;
  text-align: right;
  font-size: 14px;
  font-weight: 800;
`;

const VariantPrice = styled.div`
  float: left;
  width: 50%;
  color: #333;
  font-size: 11px;
  margin-right: 5px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 5px;
`;

const VariantInput = styled.div`
  float: left;
  width: 50%;
  margin: 10px 0;
  display: flex;
  align-items: center;
`;

const VariantAction = styled.div`
  float: left;
  width: 50%;
  margin: 10px 0;
  display: flex;
  justify-content: end;
`;

const StyledInput = styled.input`
  width: 30px;
  height: 36px;
  padding: 0;
  vertical-align: top;
  text-align: center;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #929495;
`;

const ProductVariant = ({
  variant,
  productName,
  priceCallback,
  addCallback,
  currency,
  cartState,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const onQuantityChange = (quantity, amount, productId) => {
    quantity = parseInt(quantity, 10);
    if (quantity > 0 && quantity <= MAX_QUANTITY) {
      const qty = amount * quantity;
      dispatch(priceCallback(productId, quantity, qty));
    }
  };

  const increaseQuantity = (variantId, amount, quantity) => {
    quantity = parseInt(quantity, 10);
    if (quantity < MAX_QUANTITY) {
      const newQuantity = quantity + 1;
      const qty = amount * newQuantity;
      dispatch(priceCallback(variantId, newQuantity, qty));
    }
  };

  const decreaseQuantity = (variantId, amount, quantity) => {
    quantity = parseInt(quantity, 10);
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      const qty = amount * newQuantity;
      dispatch(priceCallback(variantId, newQuantity, qty));
    }
  };

  const renderAddToCartButton = (cartData) => {
    if (cartState && cartState.code === cartData.code) {
      return (
        <StyledButton
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => dispatch(addCallback(cartData))}
          data-ect="add-to-cart"
        >
          <BtnIcon>add_shopping_cart</BtnIcon>
          <FormattedMessage id="eo.added" defaultMessage="Added" />
        </StyledButton>
      );
    }

    return (
      <StyledButton
        variant="contained"
        color="primary"
        fullWidth={true}
        onClick={() => addCallback(cartData)}
        data-ect="add-to-cart"
      >
        <FormattedMessage id="eo.add" defaultMessage="Add to cart" />
      </StyledButton>
    );
  };

  return (
    <Wrapper>
      <div>
        <VariantPackage data-ect="item-variant">
          {variant.amount > 1 && (
            <span>
              <FormattedMessage
                id="eo.pack"
                defaultMessage="Pack {amount} x "
                values={{ amount: variant.amount }}
              />
            </span>
          )}
          {variant.capacity}{" "}
          {variant.unit.translation_code ? (
            <FormattedMessage
              id={variant.unit.translation_code}
              defaultMessage={variant.unit.name}
            />
          ) : (
            variant.unit.name
          )}
        </VariantPackage>
        <HistoryPrice data-ect="item-price">
          {currency}{" "}
          <FormattedNumber
            minimumFractionDigits={2}
            value={variant.price * variant.amount * variant.quantity}
          />
        </HistoryPrice>
      </div>
      <div>
        <VariantInput>
          <BtnQuantity
            color="primary"
            style={{ width: "", height: "", padding: "", margin: "" }}
            onClick={() =>
              decreaseQuantity(
                variant.variantId,
                variant.amount,
                variant.quantity
              )
            }
          >
            <Icon>remove_circle_outline</Icon>
          </BtnQuantity>
          <StyledInput
            type="number"
            min="1"
            max="999"
            name="quantity"
            value={variant.quantity}
            onChange={(e) =>
              onQuantityChange(
                e.target.value,
                variant.amount,
                variant.variantId
              )
            }
            data-ect="item-quantity"
          />
          <BtnQuantity
            color="primary"
            style={{ width: "", height: "", padding: "", margin: "" }}
            onClick={() =>
              increaseQuantity(
                variant.variantId,
                variant.amount,
                variant.quantity
              )
            }
          >
            <Icon>add_circle_outline</Icon>
          </BtnQuantity>
        </VariantInput>
        <VariantAction>
          {renderAddToCartButton({
            code: variant.code,
            quantity: variant.quantity,
            price: variant.price,
            amount: variant.amount,
            capacity: (
              variant.capacity +
              " " +
              (variant.unit.translation_code
                ? intl.formatMessage({
                    id: variant.unit.translation_code,
                    defaultMessage: variant.unit.name,
                  })
                : variant.unit.name)
            ).trim(),
            package: variant.package,
            name: productName,
            currency: currency,
          })}
        </VariantAction>
      </div>
      <div>
        {variant.amount > 1 && (
          <VariantPrice>
            {currency}&nbsp;
            <FormattedNumber minimumFractionDigits={2} value={variant.price} />
            &nbsp;
            <FormattedMessage id="eo.per" defaultMessage="per" />
            &nbsp;
            {variant.package.translation_code ? (
              <FormattedMessage
                id={variant.package.translation_code}
                defaultMessage={variant.package.name}
              />
            ) : (
              <>
                {variant.unit.translation_code ? (
                  <FormattedMessage
                    id={variant.unit.translation_code}
                    defaultMessage={variant.unit.name}
                  />
                ) : (
                  variant.unit.name
                )}
              </>
            )}
          </VariantPrice>
        )}
      </div>
    </Wrapper>
  );
};

ProductVariant.propTypes = {
  variant: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
  priceCallback: PropTypes.func.isRequired,
  addCallback: PropTypes.func.isRequired,
  currency: PropTypes.string,
  cartState: PropTypes.object,
};

export default ProductVariant;
