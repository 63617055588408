import styled from "styled-components";
import { IconButton, ListItem } from "@material-ui/core";

export const StyledListItem = styled(ListItem)`
  && {
    border-bottom: 1px solid #e3e3e3;
  }
`;

export const Name = styled.div`
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`;

export const Prices = styled.span`
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  display: flex;
  justify-content: space-between;
`;

export const Actions = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const Remove = styled.span`
  min-width: 30px;
`;

export const Price = styled.span`
  min-width: 80px;
`;

export const QuantityButton = styled(IconButton)`
  && {
    margin-top: -5px;
    padding: 0;
  }
`;

export const Input = styled.input`
  width: 30px;
  height: 36px;
  padding: 0;
  vertical-align: top;
  text-align: center;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #929495;
`;

export const Total = styled.span`
  font-weight: 600;
`;
