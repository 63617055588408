import { MenuItem, Select } from "@material-ui/core";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { countryIcon } from "../selectors";
import { useCountryDispatch } from "../../../hooks/useCountry";

const StyledMenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  color: #666;
`;

const StyledFlagContainer = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CountrySelector = () => {
  const {
    countries,
    changeCountry: { loader },
  } = useSelector((state) => state.login);

  const countryDispatch = useCountryDispatch();

  const [selectedOption, setSelectedOption] = useState("");

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    const foundCountry = countries.find(
      (country) => country.landCode === selectedCountry
    );
    if (foundCountry) {
      countryDispatch({
        type: "set",
        country: foundCountry.landCode,
        language: foundCountry.defaultLanguage,
      });
    }
    setSelectedOption(event.target.value);
  };

  return (
    !loader && (
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        variant="outlined"
        value={selectedOption}
        onChange={handleCountryChange}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        fullWidth
        displayEmpty
      >
        <MenuItem value="" disabled>
          <StyledMenuItemContainer>
            <FormattedMessage
              id={"eo.country-select-country"}
              defaultMessage={"Select country"}
            />
          </StyledMenuItemContainer>
        </MenuItem>
        {countries.map((country) => {
          return (
            <MenuItem key={country.landCode} value={country.landCode}>
              <StyledMenuItemContainer>
                <StyledFlagContainer>
                  <img
                    src={countryIcon(country.landCode)}
                    alt={country.landCode}
                  />
                </StyledFlagContainer>
                <FormattedMessage
                  id={country.translationCode}
                  defaultMessage={country.landCode}
                />
              </StyledMenuItemContainer>
            </MenuItem>
          );
        })}
      </Select>
    )
  );
};

export default CountrySelector;
