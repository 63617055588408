import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Icon,
  Toolbar,
  Typography,
  AppBar,
  IconButton,
} from "@material-ui/core";

const StyledAppBar = styled(AppBar)`
  && {
    height: 42px;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.35);
    background: #fff;
  }
`;

const StyledToolbar = styled(Toolbar)`
  && {
    min-height: 42px;
    color: #e42313;
    line-height: 42px;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
  }
`;

const CartEditHeader = ({ callback }) => (
  <StyledAppBar>
    <StyledToolbar>
      <IconButton onClick={callback}>
        <Icon>chevron_left</Icon>
      </IconButton>
      <Typography onClick={callback}>
        <FormattedMessage id="eo.back-to-cart" defaultMessage="Back to cart" />
      </Typography>
    </StyledToolbar>
  </StyledAppBar>
);

CartEditHeader.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default CartEditHeader;
