import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

export const Container = styled.div`
  width: 728px;
  margin: 0 auto;

  @media screen and (min-width: 1170px) {
    width: 1130px;
  }
`;

export const Breadcrumbs = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 30px 0;
`);

export const BreadcrumbItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media screen and (min-width: 1170px) {
    flex-direction: row;
  }
`;

export const Details = styled.div`
  flex: 2;
  padding-right: 15px;
`;

export const Variants = styled.div`
  flex: 1;
  padding-left: 15px;
`;

export const Header = styled.div`
  margin-bottom: 30px;

  h2 {
    font-size: 28px;
    font-weight: 800;
    color: #333;
    margin: 0;
  }

  p {
    color: #999;
    font-size: 14px;
    margin-top: 10px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 24px;

  ul {
    list-style-position: inside;
  }

  h3 {
    font-size: 14px;
  }
`;

export const Photo = styled(Paper)`
  && {
    width: 269px;
    height: 269px;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 260px;
      max-height: 260px;
    }
  }
`;
