import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, SvgIcon } from "@material-ui/core";
import { push } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import {
  CART_MENU_NAME,
  CART_FULL_MENU_NAME,
  desktopElements,
} from "../constants";
import { getSelectedIndex } from "../selectors";
import isObject from "lodash/isObject";
import MenuCartItemDesktop from "./MenuCartItemDesktop";

const MenuList = styled.div`
  display: flex;
  margin-left: 85px;
  color: #d1d1d1;
  margin-right: 40px;

  @media screen and (max-width: 1169px) and (min-width: 768px) {
    margin-left: ${({ logged }) => (logged ? "40px" : "20px")};
    margin-right: 20px;
  }
`;

const MenuItem = withTheme(styled.div`
  display: ${({ $isHidden }) => ($isHidden ? "none" : "flex")};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  padding: 9px 20px;
  fill: currentColor;
  cursor: pointer;
  flex-direction: column;

  ${({ isSelected, theme }) =>
    isSelected
      ? `color: #fff;
    border-bottom: 5px solid ${theme.palette.primary.main};
    margin-bottom: -4px;`
      : ""}

  @media screen and (min-width: 1170px) {
    flex-direction: row;
    padding: 9px 25px;
    font-size: 14px;
  }
`);

const StyledSvgIcon = styled(SvgIcon)`
  margin-right: 8px;

  @media screen and (max-width: 1169px) and (min-width: 768px) {
    margin-right: 0;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;

  @media screen and (max-width: 1169px) and (min-width: 768px) {
    margin-right: 0;
  }
`;

const MenuListDesktop = () => {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.router);
  const { logged } = useSelector((state) => state.login);

  const selectedIndex = getSelectedIndex(location);

  const onItemClick = (destination) => {
    dispatch(push(destination));
  };

  const itemIcon = (iconName) =>
    isObject(iconName) ? (
      <StyledSvgIcon>
        <path {...iconName} />
      </StyledSvgIcon>
    ) : (
      <StyledIcon>{iconName}</StyledIcon>
    );

  const isCartItem = (name) =>
    [CART_MENU_NAME, CART_FULL_MENU_NAME].includes(name);

  let menuItems = desktopElements.map((item, index) => {
    return isCartItem(item.name) ? (
      <MenuCartItemDesktop
        key={index}
        item={item}
        onClick={() => onItemClick(item.to)}
        isSelected={selectedIndex === index}
      />
    ) : (
      <MenuItem
        key={index}
        onClick={() => onItemClick(item.to)}
        isSelected={selectedIndex === index}
        $isHidden={0 === index}
      >
        {itemIcon(item.icon)}
        <FormattedMessage id={"eo." + item.name} defaultMessage={item.title} />
      </MenuItem>
    );
  });

  return <MenuList logged={logged}>{menuItems}</MenuList>;
};

export default MenuListDesktop;
