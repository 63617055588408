import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { closeFreeTrainingsDialog } from "../../actions";

const StyledDialogActions = styled(DialogActions)`
  && {
    flex-direction: column;

    @media screen and (min-width: 768px) {
      justify-content: center;
      gap: 50px;
      flex-direction: row-reverse;
    }
  }
`;

const FreeTrainingDialog = ({
  isFirstTimeOrdered,
  requestFreeTrainingCallback,
}) => {
  const dispatch = useDispatch();
  const { deviceType } = useSelector((state) => state.translations);

  const handleClose = () => {
    dispatch(closeFreeTrainingsDialog());
  };
  return (
    <Dialog open={isFirstTimeOrdered}>
      <DialogTitle style={{ paddingBottom: "0px" }}>
        <FormattedMessage
          id="eo.first-time-title"
          defaultMessage="Free training"
        />
      </DialogTitle>
      <DialogContent>
        <p>
          <FormattedMessage
            id="eo.first-time-content"
            defaultMessage="Do you know that we provide free product trainings to users to get the best out of our products?"
          />
        </p>
        <p>
          <FormattedMessage
            id="eo.request-training"
            defaultMessage="Do you wish to request a training?"
          />
        </p>
      </DialogContent>
      <StyledDialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={requestFreeTrainingCallback}
          className="reorder-button"
          style={{
            maxWidth: "none",
            boxShadow: "none",
            verticalAlign: "middle",
            marginTop: "0",
            float: "none",
          }}
          fullWidth={deviceType === "mobile"}
        >
          <FormattedMessage id="eo.yes-please" defaultMessage="Yes, please" />
        </Button>

        <Button
          color="primary"
          onClick={handleClose}
          fullWidth={deviceType === "mobile"}
        >
          <FormattedMessage
            id="eo.no-thank-you"
            defaultMessage="No, thank You"
          />
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

FreeTrainingDialog.propTypes = {
  isFirstTimeOrdered: PropTypes.bool,
  requestFreeTrainingCallback: PropTypes.func.isRequired,
};

export default FreeTrainingDialog;
