import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import { FormattedMessage } from "react-intl";
import { filter, isEmpty, sampleSize } from "lodash";

import { fetchRecommendedProducts } from "../../../home/actions";
import {
  fetchProductDetails,
  clearProductDetails,
  requestFreeTraining,
  showProduct,
} from "../../actions";

import Documents from "../Documents/Documents";
import ProductVariants from "../ProductVariants/ProductVariants";
import UnloggedProductVariants from "../ProductVariants/UnloggedProductVariants";
import ProductDetailsHeader from "./ProductDetailsHeader";
import ProductDetailsLoader from "./ProductDetailsLoader";
import FreeTrainingDialog from "../ProductVariants/FreeTrainingDialog";
import RandomRecommendedProductForProductDetails from "./RandomRecommendedProductForProductDetails";
import { ImageWithPlaceholder } from "../../../../components/ImageWithPlaceholder/ImageWithPlaceholder";

import {
  AppWrapper,
  Description,
  DotsWrapper,
  ReadMore,
  ImageWrapper,
  Wrapper,
  Title,
} from "./ProductDetails.styles";

const ProductDetails = (props) => {
  const dispatch = useDispatch();

  const { productId } = props.match.params;
  const { logged } = useSelector((state) => state.login);
  const { recommendations } = useSelector((state) => state.home);
  const { product, selectedVariant } = useSelector((state) => state.shop);
  const { firstTimeOrdered } = useSelector((state) => state.cart);

  const [variantData, setVariantData] = useState();
  const [recommendationsList, setRecommendationsList] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const readMore = () => {
    setShowFullDescription(true);
  };

  const requestFreeTrainingCallback = () => {
    dispatch(requestFreeTraining(variantData));
  };

  const detailsCallback = (destination) => {
    dispatch(showProduct(destination));
  };

  const handleGoBack = () => {
    dispatch(goBack());
  };

  useEffect(() => {
    dispatch(fetchRecommendedProducts());

    return () => dispatch(clearProductDetails());
  }, []);

  useEffect(() => {
    dispatch(fetchProductDetails(productId));
  }, [productId]);

  useEffect(() => {
    let variant = undefined;

    if (product?.variants && product.variants[selectedVariant]) {
      variant = product.variants[selectedVariant];
    }

    setVariantData(variant);
  }, [product, selectedVariant]);

  useEffect(() => {
    const filtered = isEmpty(product)
      ? recommendations
      : filter(recommendations, function (item) {
          return item.groupCode !== product.groupCode;
        });

    setRecommendationsList(filtered.length ? sampleSize(filtered, 2) : []);
  }, [recommendations, product]);

  return (
    <Wrapper>
      <ProductDetailsHeader callback={handleGoBack} />
      {isEmpty(product) ? (
        <ProductDetailsLoader />
      ) : (
        <AppWrapper>
          <div>
            <ImageWrapper elevation={0}>
              {product.image && (
                <ImageWithPlaceholder alt={product.name} src={product.image} />
              )}
            </ImageWrapper>
            <Title>
              <h2>{product.name}</h2>
              <p>{product.lead}</p>
            </Title>
            <div className="clearfix" />
          </div>
          <DotsWrapper>
            <ul>
              {product?.list.map((element, index) => (
                <li key={index} dangerouslySetInnerHTML={{ __html: element }} />
              ))}
            </ul>
          </DotsWrapper>

          {logged && variantData && (
            <ProductVariants
              selectedIndex={selectedVariant}
              variants={product.variants}
              selected={variantData}
              productName={product.name}
            />
          )}
          {!logged && <UnloggedProductVariants productName={product.name} />}
          <br />
          <Description isFull={showFullDescription}>
            <div
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            />
            {!showFullDescription && product.description && (
              <ReadMore onClick={readMore}>
                <FormattedMessage
                  id="eo.read-more"
                  defaultMessage="Read more"
                />
              </ReadMore>
            )}
          </Description>
          {product.attachments && (
            <Documents attachments={product.attachments} />
          )}
          <RandomRecommendedProductForProductDetails
            recommendations={recommendationsList}
            detailsCallback={detailsCallback}
          />
        </AppWrapper>
      )}
      <FreeTrainingDialog
        isFirstTimeOrdered={firstTimeOrdered}
        requestFreeTrainingCallback={requestFreeTrainingCallback}
      />
    </Wrapper>
  );
};

export default ProductDetails;
