import React from 'react';
import uuid from 'uuid';

const idGradient = uuid.v1();
const idClip = uuid.v1();

const MyOrdersLineLoaderDesktop = () => {
    return (
        <svg viewBox="0 0 1126 54" style={{ margin: '0 0 10px 0', padding:'0'}} className="order-card" height="54px">
            <rect style={{fill: `url(#${idGradient})`}} clipPath={`url("#${idClip}")`} x="0" y="0" width="1126" height="54"/>

            <defs>
                <clipPath id={idClip}>
                    <rect x="5" y="18" rx="5" ry="5" width="650" height="20" />
                    <rect x="680" y="18" rx="5" ry="5" width="60" height="20" />
                </clipPath>

                <linearGradient id={idGradient}>
                    <stop offset="0%" stopColor="#f0f0f0">
                        <animate attributeName="offset" values="-2; 1" dur="2s" repeatCount="indefinite"/>
                    </stop>
                    <stop offset="50%" stopColor="#e0e0e0">
                        <animate attributeName="offset" values="-1.5; 1.5" dur="2s" repeatCount="indefinite"/>
                    </stop>
                    <stop offset="100%" stopColor="#f0f0f0">
                        <animate attributeName="offset" values="-1; 2" dur="2s" repeatCount="indefinite"/>
                    </stop>
                </linearGradient>
            </defs>
        </svg>
    )};

export default MyOrdersLineLoaderDesktop;