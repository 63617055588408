import { useState, useEffect } from "react";

import DeviceSelector from "../modules/core/DeviceSelector";

export const useDevice = () => {
  const [device, setDevice] = useState();

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setDevice(DeviceSelector.currentDevice());
    };

    handleWindowSizeChange();

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return {
    device,
  };
};
