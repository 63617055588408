import React from "react";
import PropTypes from "prop-types";
import CartHeader from "./CartHeader";
import Item from "./Item";
import ItemTotal from "./ItemTotal";
import { List } from "@material-ui/core";

const ItemsList = ({ items, orderDetails, currency, handleEditClick }) => {
  return (
    <div>
      <CartHeader handleEditClick={handleEditClick} />
      <List className="cart-items" style={{ padding: "0px" }}>
        {items.map((item, index) => (
          <Item key={index} item={item} currency={currency} />
        ))}
      </List>
      <ItemTotal
        total={orderDetails.totalWithoutVat}
        totalBeforeVat={orderDetails.totalBeforeVat}
        vatAmount={orderDetails.vatAmount}
        vatPercent={orderDetails.vatPercent}
        gross={orderDetails.totalWithVatAndTransport}
        deliveryCost={orderDetails.deliveryCost}
        vatWithTransport={orderDetails.vatWithTransport}
        currency={currency}
      />
    </div>
  );
};

ItemsList.propTypes = {
  items: PropTypes.array.isRequired,
  orderDetails: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  handleEditClick: PropTypes.func.isRequired,
};

export default ItemsList;
