import { Container, Title } from "./UnloggedContact.styles";
import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import SignInForm from "./SignInForm";
import { Paper } from "@material-ui/core";

const SignIn = () => {
  const [requestSent, setRequestSent] = useState(null);

  const handleSubmit = () => {
    setRequestSent(true);
  };

  const handleError = () => {
    setRequestSent(false);
  };

  return (
    <Container>
      <Title>
        <FormattedMessage id="eo.contact-us" defaultMessage="Contact us" />
      </Title>
      {requestSent === null && (
        <SignInForm onSuccess={handleSubmit} onError={handleError} />
      )}
      {requestSent && (
        <Paper elevation={0}>
          <h3>
            <FormattedMessage
              id="eo.step-more"
              defaultMessage="One more step"
            />
          </h3>
          <p>
            <FormattedMessage
              id="eo.check-emailbox"
              defaultMessage="Check your mailbox for more information"
            />
          </p>
        </Paper>
      )}
      {requestSent === false && (
        <Paper elevation={0}>
          <h3>
            <FormattedMessage id="eo.sorry" defaultMessage="We're sorry" />
          </h3>
          <p>
            <FormattedMessage
              id="eo.sorry"
              defaultMessage="There was a technical problem."
            />
          </p>
        </Paper>
      )}
    </Container>
  );
};

export default SignIn;
