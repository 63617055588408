import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { countryIcon } from "../selectors.js";

const StyledList = styled(List)`
  padding: 4px 0;
`;

const StyledListItem = styled(ListItem)`
  font-size: 12px;
  font-weight: 700;
  vertical-align: top;
  line-height: 18px;
  height: 25px;

  img {
    vertical-align: top;
    padding-right: 5px;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 35px;
  }
`;

const OpenCountryList = ({ countries, changeCountryCallback }) => {
  return (
    <StyledList>
      {countries.map(({ landCode, defaultLanguage }, index) => (
        <StyledListItem
          key={index}
          className="login-ChangeCountry-item"
          onClick={() => changeCountryCallback(landCode, defaultLanguage)}
        >
          <StyledListItemIcon>
            <img src={countryIcon(landCode)} alt={landCode} />
          </StyledListItemIcon>
          <ListItemText primary={landCode} />
        </StyledListItem>
      ))}
    </StyledList>
  );
};

OpenCountryList.propTypes = {
  countries: PropTypes.array.isRequired,
  changeCountryCallback: PropTypes.func.isRequired,
};

export default OpenCountryList;
