import { replace } from "connected-react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ShopContainer } from "../../../shop/components/Shop/ShopDesktop";
import SearchMessage from "./SearchMessage";
import { SearchResultsDesktop } from "./SearchResultsDesktop";
import { MIN_SEARCH_LENGTH } from "./SearchInputDesktop";

const SearchDesktop = (props) => {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(props.location.search);
  const query = searchParams.get("query") || "";
  const tags = searchParams.getAll("tags");
  const page = searchParams.get("page");

  useEffect(() => {
    if (!page) {
      searchParams.append("page", "1");
      const newUrl = props.location.pathname + "?" + searchParams.toString();
      dispatch(replace(newUrl));
    }
  }, [page]);

  if (query.length < MIN_SEARCH_LENGTH) {
    return (
      <ShopContainer>
        <SearchMessage
          id="eo.search-too-short-query"
          defaultMessage="Search query should contain at least {minSearchLength} characters."
          values={{ minSearchLength: MIN_SEARCH_LENGTH }}
        />
      </ShopContainer>
    );
  } else {
    return (
      <SearchResultsDesktop
        location={props.location}
        query={query}
        page={page}
        tags={tags}
      />
    );
  }
};

export default SearchDesktop;
