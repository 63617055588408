import * as t from "./actionTypes";
import axios from "axios";
import config from "../../config";
import { getCartItem, getCartState } from "./selectors";
import { getAddresses } from "../header/selectors";
import Auth from "../core/Auth";
import forEach from "lodash/forEach";
import { push } from "connected-react-router";
import {
  SWITCH_UNLOGGED_CONTACT_TO,
  SWITCH_TO_SIGNIN_FORM,
} from "../contact/actionTypes";
import { handleRequestError } from "../core/ErrorHandler";

export const fetchOrderDetailsRequest = (dispatch) => {
  dispatch({ type: t.FETCH_ORDER_DETAILS_REQUEST });

  axios
    .get(
      config.apiUrl + "eo/order/details",
      Auth.getAuthorizationRequestConfig()
    )
    .then((response) =>
      dispatch({
        type: t.FETCH_ORDER_DETAILS_SUCCESS,
        data: response.data,
      })
    )
    .catch((error) =>
      handleRequestError(dispatch, error, t.FETCH_ORDER_DETAILS_FAILURE)
    );
};

const removeRequest = (dispatch, data) => {
  dispatch({ type: t.DELETE_ITEM_FROM_BASKET_REQUEST });

  axios
    .delete(
      config.apiUrl + "eo/cart/" + data.cartItemId,
      Auth.getAuthorizationRequestConfig()
    )
    .then((response) =>
      dispatch({
        type: t.DELETE_ITEM_FROM_BASKET_SUCCESS,
        data: data,
      })
    )
    .catch((error) =>
      handleRequestError(dispatch, error, t.DELETE_ITEM_FROM_BASKET_FAILURE)
    );
};

const checkAvailabilityBasketItems = (
  dispatch,
  content,
  containsRiskyProducts
) => {
  let checkedContent = [];

  forEach(content, function (element) {
    if (element.availableToBuy === false) {
      removeRequest(dispatch, element);
      dispatch({
        type: t.PRODUCT_UNAVAILABLE,
        data: element.name,
      });
    } else {
      checkedContent.push(element);
    }
  });

  dispatch({
    type: t.FETCH_BASKET_SUCCESS,
    data: {
      cartItems: checkedContent,
      containsRiskyProducts: containsRiskyProducts,
    },
  });
};

const fetchRequest = (dispatch, checkAvailability = false) => {
  dispatch({ type: t.FETCH_BASKET_REQUEST });

  axios
    .get(config.apiUrl + "eo/cart", Auth.getAuthorizationRequestConfig())
    .then((response) => {
      if (!checkAvailability) {
        dispatch({
          type: t.FETCH_BASKET_SUCCESS,
          data: response.data,
        });
      } else {
        checkAvailabilityBasketItems(
          dispatch,
          response.data.cartItems || [],
          response.data.containsRiskyProducts
        );
      }

      fetchOrderDetailsRequest(dispatch);
    })

    .catch((error) =>
      handleRequestError(dispatch, error, t.FETCH_BASKET_FAILURE)
    );
};

const addRequest = (dispatch, data) => {
  dispatch({ type: t.ADD_ITEM_TO_BASKET_REQUEST, variant: data });

  const formData = new FormData();
  formData.append("product_code", data.code);
  formData.append("quantity", data.quantity);

  axios
    .post(
      config.apiUrl + "eo/cart",
      formData,
      Auth.getAuthorizationRequestConfig()
    )
    .then((response) => {
      if (response.data.cartItemId) {
        dispatch({
          type: t.ADD_ITEM_IN_BASKET_SUCCESS,
          data: response.data.firstTimeOrdered,
        });
        fetchRequest(dispatch);
      } else {
        dispatch({
          type: t.PRODUCT_UNAVAILABLE,
          data: data.name,
        });
      }
    })
    .catch((error) =>
      handleRequestError(dispatch, error, t.ADD_ITEM_TO_BASKET_FAILURE)
    );
};

const updateRequest = (dispatch, item, newQuantity) => {
  const formData = "quantity=" + newQuantity;

  axios
    .put(
      config.apiUrl + "eo/cart/" + item.cartItemId,
      formData,
      Auth.getAuthorizationPutRequestConfig()
    )
    .then((response) => {
      dispatch({ type: t.UPDATE_ITEM_IN_BASKET_SUCCESS });

      if (response.data.cartItemId) {
        fetchRequest(dispatch);
      }
    })
    .catch((error) =>
      handleRequestError(dispatch, error, t.UPDATE_ITEM_IN_BASKET_FAILURE)
    );
};

const clearBasketRequest = (dispatch) => {
  dispatch({ type: t.CLEAR_BASKET_REQUEST });

  axios
    .delete(config.apiUrl + "eo/cart", Auth.getAuthorizationRequestConfig())
    .then((response) =>
      dispatch({
        type: t.CLEAR_BASKET_SUCCESS,
      })
    )

    .catch((error) =>
      handleRequestError(dispatch, error, t.CLEAR_BASKET_FAILURE)
    );
};

const placeOrderRequest = (dispatch, getState, orderNumber) => {
  dispatch({ type: t.PLACE_ORDER_REQUEST });

  const addresses = getAddresses(getState());

  const formData = new FormData();
  formData.append("deliveryAddressName", addresses.delivery.name);
  formData.append("deliveryAddressCity", addresses.delivery.city);
  formData.append("deliveryAddressAddress", addresses.delivery.address);
  formData.append("deliveryAddressZipcode", addresses.delivery.zipcode);
  if (addresses.delivery.recipient)
    formData.append("deliveryAddressRecipient", addresses.delivery.recipient);

  formData.append("invoiceAddressName", addresses.invoice.name);
  formData.append("invoiceAddressCity", addresses.invoice.city);
  formData.append("invoiceAddressAddress", addresses.invoice.address);
  formData.append("invoiceAddressZipcode", addresses.invoice.zipcode);
  if (addresses.invoice.recipient)
    formData.append("invoiceAddressRecipient", addresses.invoice.recipient);

  if (orderNumber) formData.append("customerOrderNumber", orderNumber);

  axios
    .post(
      config.apiUrl + "eo/order/place",
      formData,
      Auth.getAuthorizationRequestConfig()
    )
    .then((response) => {
      dispatch({
        type: t.PLACE_ORDER_SUCCESS,
        data: response.data, // required attr: newOrderContainsRiskyProducts
      });

      dispatch(push("/orders"));
    })
    .catch((error) =>
      handleRequestError(dispatch, error, t.PLACE_ORDER_FAILURE)
    );
};

export const addToBasket = (data) => {
  return (dispatch, getState) => {
    if (getCartState(getState())) {
      return;
    }

    const item = getCartItem(getState(), data.code);

    if (item) {
      const newQuantity =
        parseInt(item.quantity, 10) + parseInt(data.quantity, 10);

      dispatch({ type: t.UPDATE_ITEM_IN_BASKET_REQUEST, variant: data });
      updateRequest(dispatch, item, newQuantity);
    } else {
      addRequest(dispatch, data);
    }
  };
};

const reorderRequest = (dispatch, order) => {
  dispatch({ type: t.REORDER_REQUEST });

  axios
    .post(
      config.apiUrl + "eo/reorder/" + order.orderId,
      new FormData(),
      Auth.getAuthorizationRequestConfig()
    )
    .then((response) => {
      dispatch({
        type: t.REORDER_SUCCESS,
        data: response.data,
      });

      dispatch(push("/cart"));
    })

    .catch((error) => handleRequestError(dispatch, error, t.REORDER_FAILURE));
};

export const reorder = (order) => {
  return (dispatch) => {
    reorderRequest(dispatch, order);
  };
};

export const editInBasket = (item, newQuantity) => {
  return (dispatch, getState) => {
    if (getCartState(getState())) {
      return;
    }

    dispatch({ type: t.UPDATE_ITEM_IN_EDIT_BASKET_REQUEST, variant: item });
    updateRequest(dispatch, item, newQuantity);
  };
};

export const removeFromBasket = (data) => {
  return (dispatch) => {
    removeRequest(dispatch, data);
  };
};

export const fetchBasket = () => {
  return (dispatch) => {
    fetchRequest(dispatch);
  };
};

export const fetchBasketCheckAvailability = () => {
  return (dispatch) => {
    fetchRequest(dispatch, true);
  };
};

export const clearBasket = () => {
  return (dispatch) => {
    clearBasketRequest(dispatch);
  };
};

export const placeOrder = (orderNumber) => {
  return (dispatch, getState) => {
    placeOrderRequest(dispatch, getState, orderNumber);
  };
};

export const switchToEditMode = () => {
  return (dispatch) => {
    dispatch(push("cart-edit"));
  };
};

export const switchToViewMode = () => {
  return (dispatch) => {
    dispatch(push("cart"));
  };
};

export const closeProductUnavailable = () => {
  return (dispatch) => {
    dispatch({ type: t.PRODUCT_UNAVAILABLE_CLOSE });
  };
};

export const closePlaceOrderErrors = () => {
  return (dispatch) => {
    dispatch({ type: t.PLACE_ORDER_ERRORS_CLOSE });
  };
};

export const redirectToContact = () => {
  return (dispatch) => {
    dispatch({ type: SWITCH_UNLOGGED_CONTACT_TO, data: "form-request" });

    dispatch(push("/contact/request"));
  };
};

export const redirectToSignin = () => {
  return (dispatch) => {
    dispatch({ type: SWITCH_UNLOGGED_CONTACT_TO, data: "form-sign-in" });
    dispatch({ type: SWITCH_TO_SIGNIN_FORM, data: true });

    dispatch(push("/contact"));
  };
};

export const validateAddress = (data) => {
  const formData = new FormData();
  formData.append("address", data.address);
  formData.append("city", data.city);
  formData.append("zipCode", data.zipcode);
  return axios.post(
    `${config.apiUrl}eo/address/validate`,
    formData,
    Auth.getAuthorizationRequestConfig()
  );
};
