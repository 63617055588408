import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import * as yup from "yup";

import FormInput from "../../../../components/Form/FormInput";
import Form from "../../../../components/Form/Form";
import { useCountry } from "../../../../hooks/useCountry";
import { validateAddress } from "../../actions";

const validateFormAddress = async (data) => {
  try {
    const response = await validateAddress(data);
    return response.data.isValid;
  } catch (error) {
    console.error("Error validating zipcode:", error);
    return false;
  }
};

const schema = yup
  .object({
    name: yup
      .string()
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
    recipient: yup.string(),
    address: yup
      .string()
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
    city: yup
      .string()
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
    zipcode: yup
      .string()
      .required(
        <FormattedMessage id="eo.required" defaultMessage="Required" />
      ),
  })
  .required();

const AddressForm = ({ onSubmit, initialValues, validateAddress }) => {
  const intl = useIntl();
  const { translationCode: countryTranslationCode, country } = useCountry();

  const defaultValues = {
    name: "",
    recipient: "",
    address: "",
    city: "",
    zipcode: "",
    ...initialValues,
  };

  const onSubmitForm = async (values) => {
    if (validateAddress) {
      const isValidAddress = await validateFormAddress(values);
      if (!isValidAddress) {
        const translatedCountry = intl.formatMessage({
          id: countryTranslationCode,
          defaultMessage: country,
        });
        const message = intl.formatMessage(
          {
            id: "eo.error-invalid-postcode",
            defaultMessage: "Please provide a valid postcode in {country}",
          },
          {
            country: translatedCountry,
          }
        );
        throw new Error(message);
      }
    }
    await onSubmit(values);
  };

  return (
    <Form
      onSubmitForm={onSubmitForm}
      defaultValues={defaultValues}
      schema={schema}
      btnLabel={
        <FormattedMessage id="eo.use-address" defaultMessage="Use address" />
      }
      btnVariant={"outlined"}
    >
      <FormInput
        name="name"
        label={
          <FormattedMessage id="eo.company-name" defaultMessage="Company" />
        }
      />
      <FormInput
        name="recipient"
        label={
          <FormattedMessage id="eo.recipient" defaultMessage="Recipient" />
        }
      />
      <FormInput
        name="address"
        label={<FormattedMessage id="eo.address" defaultMessage="Address" />}
      />
      <FormInput
        name="city"
        label={<FormattedMessage id="eo.city" defaultMessage="City" />}
      />
      <FormInput
        name="zipcode"
        label={<FormattedMessage id="eo.zipcode" defaultMessage="Post code" />}
      />
    </Form>
  );
};

AddressForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AddressForm;
