import * as t from './actionTypes';
import axios from 'axios';
import config from '../../config';
import Auth from '../core/Auth';
import {push} from 'connected-react-router';
import {handleRequestError} from '../core/ErrorHandler';

export const fetchOrders = (page) => {
    return (dispatch, getState) => {
        dispatch({type: t.FETCH_ORDERS_REQUEST});

        const params = {
            page: page,
            filters: getState()['home']['filters']
        };

        const options = Object.assign(Auth.getAuthorizationRequestConfig(), {params: params});

        axios.get(config.apiUrl + 'eo/history/orders', options)
            .then(response => dispatch({
                type: t.FETCH_ORDERS_SUCCESS,
                data: response.data
            }))
            .catch(error => handleRequestError(dispatch, error, t.FETCH_ORDERS_FAILURE));
    }
};


export const fetchProducts = () => {
    return (dispatch) => {
        dispatch({type: t.FETCH_PRODUCTS_REQUEST});

        axios.get(config.apiUrl + 'eo/history/products', Auth.getAuthorizationRequestConfig())
            .then(response => dispatch({
                type: t.FETCH_PRODUCTS_SUCCESS,
                data: response.data
            }))
            .catch(error => handleRequestError(dispatch, error, t.FETCH_PRODUCTS_FAILURE));
    }
};

export const fetchPrice = (productId, value, quantity) => {
    return (dispatch) => {
        dispatch({type: t.FETCH_PRICE_REQUEST});

        axios.get(`${config.apiUrl }eo/product/price/${productId}/${quantity}`, Auth.getAuthorizationRequestConfig())
            .then(response => dispatch({
                type: t.FETCH_PRICE_SUCCESS,
                data: {
                    productId: productId,
                    price: response.data.price,
                    quantity: value
                }
            }))
            .catch(error => handleRequestError(dispatch, error, t.FETCH_PRICE_FAILURE));
    }
};

export const fetchOrderAuthors = () => {
    return (dispatch) => {
        dispatch({type: t.FETCH_ORDERS_AUTHORS_REQUEST});

        axios.get(`${config.apiUrl }eo/history/authors`, Auth.getAuthorizationRequestConfig())
            .then(response => dispatch({
                type: t.FETCH_ORDERS_AUTHORS_SUCCESS,
                data: response.data
            }))
            .catch(error => handleRequestError(dispatch, error, t.FETCH_ORDERS_AUTHORS_FAILURE));
    }
};

export const fetchRecommendedProducts = () => {
    return (dispatch) => {
        dispatch({type: t.FETCH_RECOMMENDATIONS_REQUEST});

        if (Auth.isLoggedIn()) {
            axios.get(`${config.apiUrl }eo/recommendations`, Auth.getAuthorizationRequestConfig())
                .then(response => dispatch({
                    type: t.FETCH_RECOMMENDATIONS_SUCCESS,
                    data: response.data
                }))
                .catch(error => handleRequestError(dispatch, error, t.FETCH_RECOMMENDATIONS_FAILURE));
        } else {
            dispatch({
                type: t.FETCH_RECOMMENDATIONS_SUCCESS,
                data: {recommendations:[]}
            });
        }
    }
};

export const filterOrders = (filters) => {
    return (dispatch) => {
        dispatch({
            type: t.APPLY_FILTERS,
            data: filters
        });

        const params = {
            page: 1,
            filters: filters
        };

        const options = Object.assign(Auth.getAuthorizationRequestConfig(), {params: params});

        axios.get(config.apiUrl + 'eo/history/orders', options)
            .then(response => dispatch({
                type: t.FETCH_FILTERED_ORDERS_SUCCESS,
                data: response.data
            }))
            .catch(error => handleRequestError(dispatch, error, t.FETCH_FILTERED_ORDERS_FAILURE));
    }
};

export const clearFilterOrders = () => {
    return (dispatch) => {
        dispatch({
            type: t.CLEAR_FILTERS,
        });
    }
};

export const closeNewOrderRegisteredInfo = () => {
    return (dispatch) => {
        dispatch({type: t.CLOSE_NEW_ORDER_REGISTERED_INFO});
    }
};

export const handleNewOrderExpand = (value) => {
    return (dispatch) => {
        dispatch({type: t.SHOW_NEW_ORDER_EXPANDED, data: value});
    }
};

export const handleProductLink = (link) => {
    return (dispatch) => {
        dispatch(push(link));
    }
};

export const clearOrdersHistory = () => {
  return (dispatch) => {
      dispatch({type: t.CLEAR_ORDERS_HISTORY});
  }
};