import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  font-size: 12px;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
`;

const StyledButton = styled(Button)`
  && {
    padding: 0;
  }
`;

const Title = styled.h2`
  margin: 16px;
  color: #999999;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.5em;
  text-transform: uppercase;
`;

const CartHeader = ({ handleEditClick }) => (
  <Header>
    <Title>
      <FormattedMessage id="eo.items" defaultMessage="Items" />
    </Title>
    <ButtonWrapper>
      <StyledButton color="primary" onClick={handleEditClick}>
        <FormattedMessage id="108" defaultMessage="Edit" />
      </StyledButton>
    </ButtonWrapper>
  </Header>
);

CartHeader.propTypes = {
  handleEditClick: PropTypes.func.isRequired,
};

export default CartHeader;
