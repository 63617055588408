import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Icon, BottomNavigationAction } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

import { CART_MENU_NAME, CART_MENU_TITLE } from "../constants";

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  && {
    display: block;
    text-transform: uppercase;
    white-space: nowrap;
    background: ${({ $isSelected }) =>
      $isSelected ? "#E42313" : "transparent"};

    ${({ $isSelected }) =>
      $isSelected
        ? `
       span.MuiBottomNavigationAction-wrapper {
          text-align: center;
          color: #fff;
          background: #E42313;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding-bottom: 10px;
          padding-top: 3px;
        }`
        : ""};
  }
`;

const MenuIcon = styled.div`
  @keyframes example {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  text-align: center;
  height: 24px;
  color: #d1d1d1;
  animation-name: ${({ inProgress }) => (inProgress ? "example" : "none")};
  animation-duration: ${({ inProgress }) => (inProgress ? "2s" : "none")};
`;

const Badge = withTheme(styled.div`
  float: right;
  margin-top: 3px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 800;
  color: ${({ theme, isSelected }) =>
    isSelected ? "#fff" : theme.palette.primary.main};
  background: ${({ isSelected }) => (isSelected ? "#333" : "#fff")};
  border-radius: 50%;
  width: 20px;
  height: 20px;
`);

const MenuCartItem = ({ item, onClick, isSelected, key }) => {
  const { cartStatus, cartItemsCount } = useSelector((state) => ({
    cartStatus: state.cart.changeCartRequest,
    cartItemsCount: state.cart.items.length,
  }));

  const renderLabel = () => {
    return (
      <FormattedMessage
        id={"eo." + CART_MENU_NAME}
        defaultMessage={CART_MENU_TITLE}
      />
    );
  };

  const renderIcon = () => {
    return (
      <MenuIcon inProgress={cartStatus}>
        <Icon style={{ float: "left" }}>{item.icon}</Icon>
        {cartItemsCount > 0 && (
          <Badge isSelected={isSelected}>{cartItemsCount}</Badge>
        )}
      </MenuIcon>
    );
  };

  return (
    <StyledBottomNavigationAction
      label={renderLabel()}
      value={renderLabel()}
      icon={renderIcon()}
      key={key}
      onClick={onClick}
      style={{ color: "#d1d1d1" }}
      showLabel
      $isSelected={isSelected}
    />
  );
};

MenuCartItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default MenuCartItem;
