import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-left: 30px;
  height: 45px;
  align-items: center;
  font-size: 14px;
  color: #d1d1d1;
`;
