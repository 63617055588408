import React from "react";
import { useDispatch } from "react-redux";
import { Paper, Button, Divider } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import { redirectToSignin, redirectToContact } from "../../actions";
import { redirectToLogin } from "../../../header/actions";

const StyledPaper = styled(Paper)`
  && {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 15px;
    background: #f3f3f3;
    text-align: center;
    font-size: 14px;
    border: 2px solid #d1d1d1;
    border-radius: 4px;
    margin-bottom: 16px;

    @media screen and (max-width: 1169px) and (min-width: 768px) {
      width: 450px;
      margin: 0 auto 16px;
    }
  }
`;

const UnloggedProductVariants = ({ productName }) => {
  const dispatch = useDispatch();
  return (
    <StyledPaper elevation={0}>
      <div>
        <FormattedMessage
          id="eo.enable-prices"
          defaultMessage="Want to enable prices and ordering?"
        />
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          style={{ boxShadow: "none", float: "none" }}
          fullWidth={true}
          onClick={() => dispatch(redirectToSignin())}
        >
          <FormattedMessage id="eo.register" defaultMessage="Sign in" />
        </Button>
      </div>
      <div>
        <FormattedMessage
          id="eo.question-already-registered"
          defaultMessage="Already registered?"
        />
        <Button color="primary" onClick={() => dispatch(redirectToLogin())}>
          <FormattedMessage id="eo.log-in" defaultMessage="Log in" />
        </Button>
      </div>
      <Divider
        className="divider"
        style={{ background: "rgb(224, 224, 224)" }}
      />
      <div className="row">
        <Button
          variant="contained"
          fullWidth={true}
          style={{ boxShadow: "none" }}
          onClick={() => dispatch(redirectToContact(productName))}
        >
          <FormattedMessage
            id="eo.presentation-request"
            defaultMessage="Request a presentation"
          />
        </Button>
      </div>
    </StyledPaper>
  );
};

UnloggedProductVariants.propTypes = {
  productName: PropTypes.string.isRequired,
};

export default UnloggedProductVariants;
