import { NAME } from "./constants";
import { filter, isEmpty, sampleSize } from "lodash";

export const getAll = (state) => state[NAME];

export const getRecommendedProducts = (state) => {
  return state[NAME].recommendations;
};

export const getRandomRecommendedProducts = (state, product, count = 1) => {
  const filtered = isEmpty(product)
    ? getRecommendedProducts(state)
    : filter(getRecommendedProducts(state), function (item) {
        return item.groupCode !== product.groupCode;
      });

  return filtered.length ? sampleSize(filtered, count) : [];
};
