import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Icon } from "@material-ui/core";

import {
  DocumentsContainer,
  DocumentsList,
  Document,
} from "./Documents.styles";

const Documents = ({ attachments }) => {
  return (
    <DocumentsContainer>
      <h2>
        <FormattedMessage
          id="eo.msds-title"
          defaultMessage="Data sheets, certifications and references"
        />
      </h2>
      <DocumentsList>
        {attachments.map((element, index) => (
          <a
            key={index}
            href={element.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Document elevation={1}>
              <Icon color="primary">file_download</Icon>
              <br />
              <FormattedMessage
                id={"eo." + element.type}
                defaultMessage={element.type}
              />
              &nbsp;{element.name}
            </Document>
          </a>
        ))}
      </DocumentsList>
    </DocumentsContainer>
  );
};

Documents.propTypes = {
  attachments: PropTypes.array.isRequired,
};

export default Documents;
