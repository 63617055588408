import styled from "styled-components";
import { List, ListItem } from "@material-ui/core";

export const AppWrapper = styled.div`
  padding: 15px 10px;
`;

export const StyledDetails = styled.div`
  padding: 15px 0;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`;

export const StyledTitle = styled.h2`
  color: #666666;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  margin: 5px 0;
`;

export const StyledList = styled(List)`
  && {
    border-top: 2px dotted #929495;
    margin-top: 0;
    padding-top: 0;
  }
`;

export const StyledItem = styled(ListItem)`
  && {
    border-bottom: 2px dotted #929495;
    color: #000000;
    text-decoration: none;
  }
`;

export const StyledLink = styled.span`
  color: #000000;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
`;

export const cartIconStyle = {
  fontSize: "39px",
  color: "#b6b6b6",
};
