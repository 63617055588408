import React from "react";
import styled from "styled-components";
import LogoIcon from "../../../components/Icons/Logo/Logo";

const StyledImg = styled(LogoIcon)`
  width: 166px;
  height: 105px;
`;

const LoginLogo = () => <StyledImg />;

export default LoginLogo;
