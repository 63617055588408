import * as actions from "./actions";
import * as constants from "./constants";
import reducer from "./reducer";
import * as selectors from "./selectors";

export { default as Shop } from "./components/Shop/Shop";
export { default as ProductDetails } from "./components/ProductDetails/ProductDetails";
export { default as ShopDesktop } from "./components/Shop/ShopDesktop";
export { default as ProductDetailsDesktop } from "./components/ProductDetails/ProductDetailsDesktop";

const shop = { actions, constants, reducer, selectors };
export default shop;
