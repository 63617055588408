import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  h1 {
    line-height: 1 !important;
    margin-top: 0;
  }
`;

const Title = ({ user, company, ...props }) => (
  <Wrapper {...props}>
    <h1>{user}</h1>
    <h2>{company}</h2>
  </Wrapper>
);

export default Title;
