import { OrdersIcon } from "../core/icons/OrdersIcon";

export const CART_MENU_NAME = "cart";
export const CART_MENU_TITLE = "Cart empty";
export const CART_FULL_MENU_NAME = "cart-full";
export const CART_FULL_MENU_TITLE = "Checkout";

export const desktopElements = [
  {
    id: 0,
    name: "root",
    title: "root",
    to: "/",
  },
  {
    id: 1,
    name: "home",
    title: "Orders",
    to: "/orders",
    icon: OrdersIcon,
  },
  {
    id: 2,
    name: "shop",
    title: "Shop",
    to: "/shop",
    icon: "store",
  },
  {
    id: 3,
    name: "contact",
    title: "Contact",
    to: "/contact",
    icon: "email",
  },
  {
    id: 4,
    name: "cart",
    title: "Cart empty",
    to: "/cart",
    icon: "shopping_cart",
  },
];

export const mobileElements = [
  ...desktopElements,
  {
    id: 5,
    name: "search",
    title: "Search",
    to: "/search",
    icon: "search",
  },
];

export const routingMappings = [
  {
    id: 2,
    to: "shop",
    header: 1,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 2,
    to: "product",
    header: 0,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 3,
    to: "contact",
    header: 1,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 4,
    to: "cart-edit",
    header: 0,
    menu: 0,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 4,
    to: "cart",
    header: 1,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 5,
    to: "search",
    header: 1,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 1,
    to: "orders",
    header: 1,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 0,
    to: "login",
    header: 0,
    menu: 0,
    footer: 1,
    footerDesktop: 1,
    headerDesktop: 0,
  },
  {
    id: 0,
    to: "activate",
    header: 1,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 0,
    to: "profile",
    header: 1,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 0,
    to: "more-info",
    header: 1,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
  {
    id: 0,
    to: "country",
    header: 0,
    menu: 0,
    footer: 1,
    footerDesktop: 1,
    headerDesktop: 0,
  },
  {
    id: 0,
    to: "",
    header: 0,
    menu: 1,
    footerDesktop: 1,
    headerDesktop: 1,
  },
];
