import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  SvgIcon,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Email, Phone, DirectionsCar } from "@material-ui/icons";
import { fetchTaDetails, requestVisitCall } from "../actions";
import TaDetails from "./TaDetails";
import UnloggedContact from "./UnloggedContact";
import ContactLoader from "./ContactLoader";
import { whatsAppUrl, clearPhoneNumber } from "../../../helpers/contact";
import { WhatsUpIcon } from "../../core/icons/WhatsUpIcon";

const Container = styled.div`
  max-width: 310px;
  margin: 0 auto;
  padding: 15px 10px;
  text-align: center;
`;

const StyledList = styled(List)`
  && {
    border-top: 2px dotted #929495;
    margin-top: 0;
    padding-top: 0;
  }
`;

const StyledListItem = styled(ListItem)`
  border-bottom: 2px dotted #929495;
  color: #000000;
  text-decoration: none;
`;

const ContactLink = styled.a`
  color: #000000;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
`;

const ContactItem = styled.span`
  color: #000000;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
`;

const ContactPhone = styled.span`
  color: #666;
  font-weight: 400;
`;

const Contact = () => {
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.login);
  const { deviceType } = useSelector((state) => state.translations);
  const { taDetails, communicationChannels, contactLoader } = useSelector(
    (state) => ({
      taDetails: state.contact.technicalAdviser,
      communicationChannels: state.contact.communicationChannels,
      contactLoader: state.contact.contactLoader,
    })
  );
  const [showDialog, setShowDialog] = useState(false);
  const [requestSentSuccessfully, setRequestSentSuccessfully] = useState(null);

  useEffect(() => {
    if (logged) {
      dispatch(fetchTaDetails());
    }
  }, []);

  const phoneLink = () =>
    deviceType === "mobile" ? (
      <ContactLink href={"tel:" + clearPhoneNumber(taDetails.phone)}>
        <FormattedMessage id="eo.make-call" defaultMessage="Make a Call" />:{" "}
        <ContactPhone>{taDetails.phone}</ContactPhone>
      </ContactLink>
    ) : (
      <ContactItem>
        <FormattedMessage id="eo.phone-number" defaultMessage="Phone" />:{" "}
        <ContactPhone>{taDetails.phone}</ContactPhone>
      </ContactItem>
    );

  const closeModal = () => {
    setShowDialog(false);
    setRequestSentSuccessfully(null);
  };

  const requestVisit = () => {
    setShowDialog(true);
    requestVisitCall()
      .then(() => {
        setRequestSentSuccessfully(true);
      })
      .catch(() => {
        setRequestSentSuccessfully(false);
      });
  };

  return (
    <Container>
      {logged ? (
        contactLoader ? (
          <ContactLoader />
        ) : (
          <div>
            <TaDetails taDetails={taDetails} />
            <StyledList>
              <StyledListItem onClick={requestVisit}>
                <ListItemIcon>
                  <DirectionsCar color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <ContactItem>
                      <FormattedMessage
                        id="eo.visit-request"
                        defaultMessage="Request a visit"
                      />
                    </ContactItem>
                  }
                />
              </StyledListItem>
              {communicationChannels?.email && (
                <StyledListItem>
                  <ListItemIcon>
                    <Email color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <ContactLink href={"mailto:" + taDetails.email}>
                        <FormattedMessage
                          id="eo.send-email"
                          defaultMessage="Send an e-mail"
                        />
                      </ContactLink>
                    }
                  />
                </StyledListItem>
              )}
              {communicationChannels?.phone && (
                <StyledListItem>
                  <ListItemIcon>
                    <Phone color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={phoneLink()} />
                </StyledListItem>
              )}
              {communicationChannels?.whatsapp && (
                <StyledListItem>
                  <ListItemIcon>
                    <SvgIcon className="intRed-icon" style={{ fill: "" }}>
                      <path {...WhatsUpIcon} />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <ContactLink
                        href={
                          whatsAppUrl(deviceType) +
                          clearPhoneNumber(taDetails.phone)
                        }
                      >
                        <FormattedMessage
                          id="eo.whatsapp-contact"
                          defaultMessage="Contact via WhatsApp"
                        />
                      </ContactLink>
                    }
                  />
                </StyledListItem>
              )}
            </StyledList>

            <Dialog open={showDialog} onClose={closeModal}>
              <DialogContent>
                <DialogContentText>
                  {requestSentSuccessfully === null ? (
                    <FormattedMessage
                      id="eo.request-sending"
                      defaultMessage="Sending..."
                    />
                  ) : requestSentSuccessfully ? (
                    <FormattedMessage
                      id="eo.request-sent"
                      defaultMessage="Request sent"
                    />
                  ) : (
                    <FormattedMessage
                      id="eo.request-failed"
                      defaultMessage="There was a problem with sending your request"
                    />
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={closeModal}>
                  <FormattedMessage id="eo.close" defaultMessage="Close" />
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )
      ) : (
        <UnloggedContact />
      )}
    </Container>
  );
};

export default Contact;
