import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { List } from "@material-ui/core";

import {
  Container,
  StyledListItem,
  StyledListItemText,
  Title,
  ItemCounter,
} from "./WhatNext.styles";

const WhatNext = ({ selected, containsRiskyProducts }) => {
  const selectAllWithIndexLessThan = selected || 0;
  const { countryOptions } = useSelector((state) => state.header);
  if (!countryOptions) {
    return null;
  }
  const newItems = containsRiskyProducts
    ? {
        cart_bullet_1: countryOptions.cart_bullet_1_risky,
        cart_bullet_2: countryOptions.cart_bullet_2_risky,
        cart_bullet_3: countryOptions.cart_bullet_3_risky,
        cart_bullet_4: countryOptions.cart_bullet_4_risky,
        cart_bullet_5: countryOptions.cart_bullet_5_risky,
      }
    : {
        cart_bullet_1: countryOptions.cart_bullet_1,
        cart_bullet_2: countryOptions.cart_bullet_2,
        cart_bullet_3: countryOptions.cart_bullet_3,
        cart_bullet_4: countryOptions.cart_bullet_4,
      };

  const listItems = Object.entries(newItems).map(
    ([bulletKey, bulletValue], index) => {
      const isSelected = index < selectAllWithIndexLessThan;
      return bulletValue?.length ? (
        <StyledListItem key={index}>
          <ItemCounter $isSelected={isSelected}>{index + 1}</ItemCounter>
          <StyledListItemText $isSelected={isSelected}>
            <div
              dangerouslySetInnerHTML={{
                __html: bulletValue,
              }}
            />
          </StyledListItemText>
        </StyledListItem>
      ) : null;
    }
  );

  return (
    <Container>
      <Title>
        <FormattedMessage id="eo.what-next" defaultMessage="What next?" />
      </Title>
      <List>{listItems}</List>
    </Container>
  );
};

WhatNext.propTypes = {
  selected: PropTypes.number,
  containsRiskyProducts: PropTypes.bool,
};

export default WhatNext;
