import React, { useEffect } from "react";
import { List } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CartEditHeader from "./CartEditHeader";
import CartEditHeaderDesktop from "./CartEditHeaderDesktop";
import EditItem from "./EditItem";
import {
  editInBasket,
  fetchBasket,
  switchToViewMode,
  removeFromBasket,
} from "../../actions";

const Container = styled.div`
  @media screen and (min-width: 1170px) {
    width: 1130px;
    margin: 0 auto;
  }
`;

const StyledList = styled(List)`
  && {
    background: #f3f3f3;
    margin-top: -20px;
    padding: 0;

    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
  }
`;

const CartEdit = () => {
  const dispatch = useDispatch();
  const { deviceType } = useSelector((state) => state.translations);
  const { items } = useSelector((state) => state.cart);
  const { currency } = useSelector((state) => state.header);

  useEffect(() => {
    dispatch(fetchBasket());
  }, []);

  useEffect(() => {
    if (items.length === 0) {
      dispatch(switchToViewMode());
    }
  }, [items]);

  const increaseQuantity = (item) => {
    const newQuantity = parseInt(item.quantity, 10) + 1;
    dispatch(editInBasket(item, newQuantity));
  };

  const decreaseQuantity = (item) => {
    if (item.quantity > 1) {
      const newQuantity = parseInt(item.quantity, 10) - 1;
      dispatch(editInBasket(item, newQuantity));
    }
  };

  const editQuantity = (item, newQuantity) => {
    if (newQuantity > 0) {
      dispatch(editInBasket(item, newQuantity));
    }
  };

  const handleRemoveFromBasket = (data) => {
    dispatch(removeFromBasket(data));
  };

  return (
    <Container>
      {deviceType === "mobile" ? (
        <CartEditHeader callback={() => dispatch(switchToViewMode())} />
      ) : (
        <CartEditHeaderDesktop callback={() => dispatch(switchToViewMode())} />
      )}
      <StyledList>
        {items.map((item, index) => (
          <EditItem
            key={index}
            item={item}
            currency={currency}
            removeCallback={handleRemoveFromBasket}
            increaseQuantityCallback={increaseQuantity}
            decreaseQuantityCallback={decreaseQuantity}
            editQuantityCallback={editQuantity}
          />
        ))}
      </StyledList>
    </Container>
  );
};

export default CartEdit;
