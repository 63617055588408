import React, { useState } from "react";
import styled from "styled-components";
import { Tabs, Tab } from "@material-ui/core";
import MyOrders from "./MyOrders/MyOrders";
import MyProducts from "./MyProducts/MyProducts";
import { FormattedMessage } from "react-intl";

const StyledContainer = styled.div`
  display: flex;
`;

const StyledTabs = styled(Tabs)`
  && {
    background-color: #fff;
    position: fixed;
    z-index: 1098;
    color: #000;
    width: 100%;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.35);
  }
`;

const StyledTab = styled(Tab)`
  && {
    width: 50%;
    border: 1px solid #d1d1d1;
    border-bottom: 2px;
    border-top: 2px;
    font-size: 12px;
    color: #000;
    font-weight: 800;
    height: 49px;
  }
`;

const Home = () => {
  const [value, setValue] = useState("orders");

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <StyledContainer>
      <StyledTabs
        onChange={handleChange}
        value={value}
        indicatorColor="primary"
      >
        <StyledTab
          className="history-tab"
          label={
            <FormattedMessage id="eo.myorders" defaultMessage="My Orders" />
          }
          value="orders"
        />
        <StyledTab
          className="history-tab"
          label={
            <FormattedMessage id="eo.myproducts" defaultMessage="My Products" />
          }
          value="products"
        />
      </StyledTabs>
      <MyOrders value={value} index="orders" />
      <MyProducts value={value} index="products" />
    </StyledContainer>
  );
};
export default Home;
