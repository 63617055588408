import styled from "styled-components";

export const ShopList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 30px 0;
`;

export const ShopItem = styled.div`
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "calc(50% - 60px)")};
  border: 2px solid #d1d1d1;
  border-radius: 4px;
  margin: 10px 8px;
  padding: 20px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  cursor: pointer;

  @media screen and (min-width: 1170px) {
    width: ${({ isFullWidth }) =>
      isFullWidth ? "100%" : "calc(33.33% - 60px)"};
  }

  h2 {
    font-size: 14px;
    color: #333;
    font-weight: 800;
    margin: 0;
  }

  p {
    font-size: 12px;
    color: #999;
    font-weight: 400;
  }
`;

export const Photo = styled.div`
  width: 100px;
  height: 100px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100px;
    max-height: 100px;
  }
`;

export const Details = styled.div`
  width: 215px;
`;
