export const AUTH_SUCCESS = "login/AUTH_SUCCESS";
export const AUTH_FAILURE = "login/AUTH_FAILURE";
export const AUTH_LOGOUT = "login/AUTH_LOGOUT";

export const COUNTRY_SWITCHED = "login/COUNTRY_SWITCHED";
export const FETCH_COUNTRIES_REQUEST = "login/FETCH_COUNTRIES_REQUEST";
export const FETCH_COUNTRIES_SUCCESS = "login/FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_FAILURE = "login/FETCH_COUNTRIES_FAILURE";

export const FORM_NEW_PASSWORD_SUBMIT_REQUEST =
  "login/FORM_NEW_PASSWORD_SUBMIT_REQUEST";
export const FORM_NEW_PASSWORD_SUBMIT_SUCCESS =
  "login/FORM_NEW_PASSWORD_SUBMIT_SUCCESS";

export const CLOSE_SUCCESS_PASSWORD_SET = "login/CLOSE_SUCCESS_PASSWORD_SET";

export const SYNC_AUTH_USER_LOGOUT = "login/SYNC_AUTH_USER_LOGOUT";
export const SYNC_AUTH_USER_LOGIN = "login/SYNC_AUTH_USER_LOGIN";
