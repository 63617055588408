import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchCatalogue, changeCategory, showProduct } from "../../actions";
import Categories from "../Categories";
import ShopLoader from "./ShopLoader";
import {
  Icon,
  IconButton,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { placeholderImg } from "../../../../components/ImageWithPlaceholder/ImageWithPlaceholder";
import { FiltersMobile } from "../../../filters";
import { useFilters } from "../../../filters/index";
import { goBack, push, replace } from "connected-react-router";
import { Subcategory } from "../Subcategories/Subcategory";
import { Typography } from "../../../../../node_modules/@material-ui/core/index";

export const StyledListItem = styled(ListItem)`
  && {
    border-bottom: 2px solid #e7e7e7;
  }
`;

const MessageContainer = styled.div`
  padding: 30px;
  text-align: center;
  color: #666;
`;

const CategoriesContainer = styled.div`
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
`;

const StyledBackContainer = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  color: #e42313;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
`;

const Shop = (props) => {
  const dispatch = useDispatch();
  const { catalogue, currentCategory } = useSelector((state) => state.shop);

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);

  const searchParams = new URLSearchParams(props.location.search);
  const tags = searchParams.getAll("tags");
  const subcategoryParam = searchParams.get("subcategory");

  const handleChangeCategory = (categoryId) => {
    dispatch(changeCategory(categoryId));
    dispatch(replace(props.location.pathname));
  };

  useEffect(() => {
    dispatch(fetchCatalogue());
    return () => window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const cats = catalogue
      .filter(
        ({ subCategories, products }) =>
          subCategories?.some(
            (subCategory) => subCategory.products?.length > 0
          ) || products?.length > 0
      )
      .map((category) => ({
        categoryId: category.categoryId,
        name: category.name,
        token: category.token,
      }));

    setCategories(cats);
  }, [catalogue]);

  useEffect(() => {
    const cat = catalogue.find((category) => {
      return category.categoryId === currentCategory;
    });

    if (subcategoryParam) {
      const products = cat?.subCategories?.find(
        (subcategory) => subcategory.categoryId === Number(subcategoryParam)
      )?.products;

      setProducts(products?.length ? products : []);
    } else {
      setProducts(cat !== undefined ? cat.products : []);
    }
  }, [catalogue, currentCategory, subcategoryParam]);

  useEffect(() => {
    const cat = catalogue.find((category) => {
      return category.categoryId === currentCategory;
    });

    setSubcategories(cat?.subCategories ? cat.subCategories : []);
  }, [catalogue, currentCategory]);

  const handleChangeSubcategory = (subcategoryId) => {
    searchParams.set("subcategory", subcategoryId);
    const newUrl = props.location.pathname + "?" + searchParams.toString();
    dispatch(push(newUrl));
  };

  const handleGoBack = () => {
    dispatch(goBack());
  };

  const subcategoriesList = subcategories
    ?.filter((subcategory) => subcategory.products.length)
    .map((subcategory, index) => {
      return (
        <Subcategory
          key={index}
          subcategory={subcategory}
          handleChangeSubcategory={handleChangeSubcategory}
        />
      );
    });

  const filteredProducts = useFilters(products, tags, "tags");

  let productsList = filteredProducts?.map((product, index) => (
    <StyledListItem
      onClick={() => dispatch(showProduct("/product/" + product.groupCode))}
      key={index}
    >
      <ListItemAvatar>
        {product.image ? (
          <Avatar
            style={{ borderRadius: "0" }}
            imgProps={{ style: { objectFit: "scale-down" } }}
            src={product.image}
          >
            <Avatar src={placeholderImg} />
          </Avatar>
        ) : (
          <Avatar src={placeholderImg} />
        )}
      </ListItemAvatar>
      <ListItemText primary={product.name} secondary={product.lead} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          onClick={() => dispatch(showProduct("/product/" + product.groupCode))}
        >
          <Icon>chevron_right</Icon>
        </IconButton>
      </ListItemSecondaryAction>
    </StyledListItem>
  ));

  return (
    <div>
      <CategoriesContainer>
        {categories.length > 0 && (
          <Categories
            currentCategory={currentCategory}
            categories={categories}
            changeCallback={handleChangeCategory}
          />
        )}
        <FiltersMobile
          products={products}
          filterPer={"tags"}
          filteredProducts={filteredProducts}
        />
      </CategoriesContainer>

      <List>
        {!subcategoryParam && subcategoriesList}
        {subcategoryParam && (
          <StyledBackContainer>
            <IconButton onClick={handleGoBack} color="primary">
              <Icon>chevron_left</Icon>
            </IconButton>
            <Typography onClick={handleGoBack}>
              <FormattedMessage id="eo.back" defaultMessage="Back" />
            </Typography>
          </StyledBackContainer>
        )}
        {products?.length > 0 ? (
          productsList
        ) : (
          <>
            {!subcategoriesList && categories.length > 0 ? (
              <MessageContainer>
                <FormattedMessage
                  id="eo.no-products"
                  defaultMessage="No products available"
                />
              </MessageContainer>
            ) : (
              !subcategoriesList && (
                <ListItem>
                  <ListItemText primary={<ShopLoader />} />
                </ListItem>
              )
            )}
          </>
        )}
      </List>
    </div>
  );
};

export default Shop;
