import _ from "lodash";
import { useMemo } from "react";

export const useUniqueFilters = (products, filterPer) => {
  const uniqueFilters = useMemo(() => {
    const uniqueFilters = _.uniqBy(
      _.flatten(products?.map((product) => product[filterPer]).filter(Boolean)),
      "id"
    );
    return _.sortBy(uniqueFilters, "sortOrder");
  }, [products]);

  return uniqueFilters;
};

export const useFilters = (products, filters, type) => {
  const filteredProducts = useMemo(() => {
    if (filters.length) {
      return products.filter((product) => {
        const productType = product[type];
        return filters.every((filterId) =>
          productType?.some((filter) => filter.id.toString() === filterId)
        );
      });
    } else {
      return products;
    }
  }, [products, filters, type]);

  return filteredProducts;
};
