import styled from "styled-components";
import { ListItem } from "@material-ui/core";

export const Container = styled.div`
  padding: 10px 10px;
`;

export const Title = styled.h2`
  margin: 4px 16px;
  color: #999999;
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  text-transform: uppercase;
`;

export const StyledListItem = styled(ListItem)`
  padding: 2px 16px;
  display: flex;
  gap: 16px;
`;

export const StyledListItemText = styled.div`
  color: ${({ $isSelected }) => ($isSelected ? "#999" : "#333")};
  display: flex;
  line-height: 30px;
  align-items: center;
  font-size: 14px;
`;

export const ItemCounter = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;

  background-color: ${({ $isSelected }) => ($isSelected ? "#d4d4d4" : "#999")};
  border-radius: 50%;

  min-width: 20px;
  width: 20px;
  height: 20px;
`;
