import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  background: #f5f5f5;
  padding: 0 15px 5px;
`;

const Item = styled.div`
  display: flex;
  justify-content: end;
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  text-align: right;

  &:last-child {
    color: #333333;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5em;
    text-align: right;
  }
`;

const Name = styled.div`
  &:not-first-child {
    text-transform: uppercase;
  }
`;

const Amount = styled.div`
  margin-left: 30px;
  width: 40%;
  max-width: 150px;
  text-align: right;
`;

const ItemTotal = ({
  total,
  totalBeforeVat,
  vatAmount,
  vatPercent,
  gross,
  vatWithTransport,
  deliveryCost,
  currency,
}) => (
  <Container>
    <Item>
      <Name>
        <FormattedMessage id="eo.net-price" defaultMessage="Net price" />
      </Name>
      <Amount data-ect="value">
        {currency} <FormattedNumber minimumFractionDigits={2} value={total} />
      </Amount>
    </Item>

    <Item>
      <Name>
        <FormattedMessage
          id="eo.delivery-costs"
          defaultMessage="Delivery costs"
        />
      </Name>
      <Amount data-ect="shipping">
        {currency}{" "}
        <FormattedNumber minimumFractionDigits={2} value={deliveryCost} />
      </Amount>
    </Item>
    {totalBeforeVat && (
      <Item>
        <Name>
          <FormattedMessage
            id="eo.total-before-vat"
            defaultMessage="Total before VAT"
          />
        </Name>
        <Amount>
          {currency}{" "}
          <FormattedNumber minimumFractionDigits={2} value={totalBeforeVat} />
        </Amount>
      </Item>
    )}
    <Item>
      <Name>
        <FormattedMessage id="eo.subvat" defaultMessage="VAT" /> &nbsp;
        <FormattedNumber value={vatPercent} />%
      </Name>
      <Amount data-ect="tax">
        {currency}{" "}
        <FormattedNumber value={vatAmount} minimumFractionDigits={2} />
      </Amount>
    </Item>
    <Item>
      <Name>
        <FormattedMessage id="eo.gross" defaultMessage="DUE(GROSS)" />
      </Name>
      <Amount>
        {currency} <FormattedNumber minimumFractionDigits={2} value={gross} />
      </Amount>
    </Item>
  </Container>
);

ItemTotal.propTypes = {
  total: PropTypes.number.isRequired,
  totalBeforeVat: PropTypes.number.isRequired,
  vatAmount: PropTypes.number.isRequired,
  vatPercent: PropTypes.number.isRequired,
  gross: PropTypes.number.isRequired,
  deliveryCost: PropTypes.number.isRequired,
  vatWithTransport: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
};

export default ItemTotal;
