import React, { useEffect, useState } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import styled from "styled-components";
import reloadIcon from "./refresh.svg";
import { FormattedMessage } from "react-intl";
import FormInput from "./FormInput";

const Wrapper = styled.div`
  & > div {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    background: #f2f2f2;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
  }

  a {
    color: transparent;
    background-image: url(${reloadIcon});
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;
    font-size: 0;
    display: block;
  }
`;

const Captcha = ({ onChange, isSubmitting }) => {
  const [isValid, setIsValid] = useState(true);
  const [value, setValue] = useState();

  useEffect(() => {
    loadCaptchaEnginge(6, "#f2f2f2");
  }, []);

  useEffect(() => {
    if (isSubmitting) setIsValid(validateCaptcha(value, false));
  }, [isSubmitting]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(validateCaptcha(e.target.value, false));
    if (e.target.value.length > 5) {
      setIsValid(validateCaptcha(e.target.value, false));
    } else {
      setIsValid(true);
    }
  };

  return (
    <div>
      <Wrapper>
        <LoadCanvasTemplate />
      </Wrapper>
      <div>
        <FormInput
          name="captcha"
          onChange={handleChange}
          label={
            <FormattedMessage id="eo.captcha" defaultMessage="Enter captcha" />
          }
          register={() => {}}
          errors={
            !isValid && {
              message: value ? (
                <FormattedMessage
                  id="eo.invalid_value"
                  defaultMessage="Invalid value"
                />
              ) : (
                <FormattedMessage id="eo.required" defaultMessage="Required" />
              ),
            }
          }
          required={true}
        />
      </div>
    </div>
  );
};

export default Captcha;
