import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { push } from "connected-react-router";

import { OrdersIcon } from "../../core/icons/OrdersIcon";

import Header from "./Header";
import {
  Container,
  InnerContainer,
  Items,
  Item,
  StyledIcon,
  StyledSvgIcon,
  HeaderTextContainer,
} from "./Dashboard.styles";
import HeaderTextMobile from "../../header/components/Header/HeaderTextMobile";
import CustomMessage from "../../../components/CustomMessage/CustomMessage";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { userName } = useSelector((state) => state.header);
  const { deviceType } = useSelector((state) => state.translations);
  const banner = useSelector((state) => state.header?.countryOptions?.banner);

  const handleClick = (dest) => {
    dispatch(push(dest));
  };

  return (
    <Container>
      <InnerContainer>
        {deviceType === "mobile" && (
          <>
            <Header
              userName={userName}
              profileCallback={() => handleClick("profile")}
            />
            <HeaderTextContainer>
              <HeaderTextMobile />
            </HeaderTextContainer>
          </>
        )}
        <Items>
          {banner && <CustomMessage htmlMessage={banner} />}
          <Item onClick={() => handleClick("orders")}>
            <StyledSvgIcon>
              <path {...OrdersIcon} />
            </StyledSvgIcon>
            <FormattedMessage
              id="eo.check-orders"
              defaultMessage="Check your orders"
            />
          </Item>
          <Item onClick={() => handleClick("shop")}>
            <StyledIcon>store</StyledIcon>
            <FormattedMessage
              id="eo.explore-store"
              defaultMessage="Explore our store"
            />
          </Item>
          <Item onClick={() => handleClick("contact")}>
            <StyledIcon>email</StyledIcon>
            <FormattedMessage
              id="eo.contact-ta"
              defaultMessage="Contact your technical advisor"
            />
          </Item>
        </Items>
      </InnerContainer>
    </Container>
  );
};

export default Dashboard;
