export const handleRequestError = (dispatch, error, actionType) => {
    if (error.response) {
        return dispatch({
            type: actionType,
            error: error.response.data.message || error.response.statusText
        })

    } else if (error.request) {
        return dispatch({
            type: actionType,
            error: error.request.responseText
        })
    } else {
        return dispatch({
            type: actionType,
            error: error.message
        })
    }
};
