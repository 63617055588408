import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
  font-size: 14px;
  background-color: #d9d9d9;
  color: #666;
  padding: 0 5px;
  margin-bottom: 5px;
`;
