import { combineReducers } from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { connectRouter } from "connected-react-router";
import home from "./modules/home";
import login from "./modules/login";
import header from "./modules/header";
import contact from "./modules/contact";
import app from "./modules/app";
import cart from "./modules/cart";
import shop from "./modules/shop";
import { reducer as reduxFormReducer } from "redux-form";
import { middleware as routerMiddleware, history } from "./router";
import { intlReducer } from "react-intl-redux";
import { authStateSyncMiddleware } from "./authStateSync";

const rootReducer = combineReducers({
  [app.constants.NAME]: app.reducer,
  [home.constants.NAME]: home.reducer,
  [login.constants.NAME]: login.reducer,
  [header.constants.NAME]: header.reducer,
  contact: contact.reducer,
  [cart.constants.NAME]: cart.reducer,
  [shop.constants.NAME]: shop.reducer,
  router: connectRouter(history),
  form: reduxFormReducer.plugin({
    LoginForm: (state, action) => {
      switch (action.type) {
        case "login/AUTH_FAILURE":
          return {
            ...state,
            authError: state.error,
            values: {
              ...state.values,
              login: undefined,
              password: undefined,
            },
            fields: {
              ...state.fields,
              login: undefined,
              password: undefined,
            },
          };
        default:
          return state;
      }
    },
  }),
  intl: intlReducer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(authStateSyncMiddleware),
    applyMiddleware(thunk),
    applyMiddleware(routerMiddleware)
  )
);

export default store;
