import styled from "styled-components";
import { Select, MenuItem } from "@material-ui/core";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 10px;
`;

export const Label = styled.div`
  line-height: 35px;
  float: left;
  font-size: 14px;
`;

export const StyledSelect = styled(Select)`
  width: 75%;
  float: right;
  height: 35px;
  font-size: 14px;
  line-height: 35px;
  padding: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f5f5f5;
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    font-weight: bold;
    padding: 0 15px;
    font-size: 15px;
  }
`;
