import * as t from './actionTypes';
import {initialState} from './constants';

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case t.RESOLUTION_CHANGE:
            return Object.assign({}, state, {
               deviceType: action.data
            });
        default:
            return state;
    }
};

export default appReducer;