import React from "react";
import { FormattedMessage } from "react-intl";
import { ProfileContainer, ProfileTitle } from "./Profile.styles";
import { useCountry } from "../../../hooks/useCountry";
import styled from "styled-components";
import { ProfileBack } from "./ProfileBack";

const StyledProfileContainer = styled(ProfileContainer)`
  padding: 0 40px;
`;

const StyledAnchor = styled.a`
  color: #666;
  font-size: 18px;
`;

const StyledLinksContainer = styled.div`
  display: flex;
  gap: 30px;
  padding-top: 20px;
  flex-direction: column;
`;

const StyledBackContainer = styled.div`
  margin-left: -10px;
`;

const ProfileLegal = () => {
  const { termsUrl, privacyUrl, legalDisclosure, cookies } = useCountry();

  const items = [
    {
      url: termsUrl,
      id: "eo.terms-and-conditions",
      defaultMessage: "Terms and conditions",
    },
    {
      url: privacyUrl,
      id: "eo.privacy-statement",
      defaultMessage: "Privacy statement",
    },
    {
      url: legalDisclosure,
      id: "eo.legal-disclosure",
      defaultMessage: "Legal disclosure",
    },
    {
      url: cookies,
      id: "eo.cookies",
      defaultMessage: "Cookies",
    },
  ];

  const links = items
    .filter((item) => item.url)
    .map((item) => (
      <StyledAnchor key={item.id} href={item.url} target="_blank">
        <FormattedMessage id={item.id} defaultMessage={item.defaultMessage} />
      </StyledAnchor>
    ));

  return (
    <StyledProfileContainer>
      <StyledBackContainer>
        <ProfileBack />
      </StyledBackContainer>
      <ProfileTitle>
        <FormattedMessage id="eo.legal" defaultMessage="Legal" />
      </ProfileTitle>
      <StyledLinksContainer>{links}</StyledLinksContainer>
    </StyledProfileContainer>
  );
};

export default ProfileLegal;
