import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router";
import { push } from "connected-react-router";
import { Icon } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import LoginLogo from "./LoginLogo";
import LoginForm from "./LoginForm";
import OpenChangeCountry from "./OpenChangeCountry";
import UnloggedTiles from "./UnloggedTiles";
import Auth from "../../core/Auth";
import { LoginContainer, LoginInnerContainer } from "./Login.styles";

const StyledLoginContainer = styled(LoginContainer)`
  min-height: 550px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media screen and (min-width: 1170px) {
    height: auto;
    position: relative;
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin: 30px auto 0 auto;
    padding-bottom: 30px;
    width: 550px;
  }
`;

const StyledForgotPassword = styled.div`
  margin-top: 30px;
  color: #333;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 14px;
`;

const StyledHelp = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-top: auto;
  display: flex;
  align-self: flex-end;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 40px;
  line-height: 40px;
  width: 100%;
  background: #fff;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  z-index: 99;
  cursor: pointer;

  @media screen and (min-width: 1170px) {
    position: relative;
    bottom: 0;
    margin-top: 20px;
  }
`);

const CountryPickerContainer = styled.div`
  position: absolute;
  right: 0;
  padding-top: 5px;
`;

const Login = () => {
  const dispatch = useDispatch();

  const handleHelpClick = () => {
    dispatch(push("/login/help/signin"));
  };
  const handleForgotPasswordClick = () => {
    dispatch(push("/login/help/forgotpassword"));
  };

  return Auth.isLoggedIn() ? (
    <Redirect to="/" />
  ) : (
    <StyledLoginContainer>
      <LoginInnerContainer>
        <CountryPickerContainer>
          <OpenChangeCountry />
        </CountryPickerContainer>

        <ContentWrapper>
          <div>
            <LoginLogo />
          </div>
          <div>
            <LoginForm />
          </div>
          <StyledForgotPassword onClick={handleForgotPasswordClick}>
            <FormattedMessage
              id="eo.forgot-password"
              defaultMessage="Forgot password?"
            />
          </StyledForgotPassword>
          <StyledHelp onClick={handleHelpClick}>
            <Icon style={{ color: "#999999" }}>info</Icon>
            <span>
              <FormattedMessage
                id="eo.create-account"
                defaultMessage="Create account"
              />
            </span>
          </StyledHelp>
        </ContentWrapper>
        <UnloggedTiles />
      </LoginInnerContainer>
    </StyledLoginContainer>
  );
};

export default Login;
