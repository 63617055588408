import "core-js/stable";
import "regenerator-runtime/runtime";
import { createRoot } from "react-dom/client";
import React from "react";
import { Provider } from "react-redux";
import axios from "axios";
import TagManager from "react-gtm-module";

import CountryProvider from "./contexts/countryContext";

import store from "./store";
import { App } from "./modules/app";
import Auth from "./modules/core/Auth";
import Rollbar from "rollbar";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import { CookiefirstProvider } from "./providers/CookiefirstProvider";

// optional for old safari browsers
if (!global.Intl) {
  global.Intl = require("intl");
}

const GTM_ID = process.env.REACT_APP_GTM_ID;

const tagManagerArgs = {
  gtmId: GTM_ID,
};

if (GTM_ID) TagManager.initialize(tagManagerArgs);

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENV,
  },
};

const rollbar = new Rollbar(rollbarConfig);

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// injectTapEventPlugin();

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      error.config.url.indexOf("eo/login/token") === -1
    ) {
      Auth.logOut();
      window.location.href = "/login";
      return;
    }
    const { status } = error.response ? error.response : { status: "unknown" };
    if (rollbar && status !== 401 && window.location.hostname !== "localhost") {
      rollbar.error(error?.response?.config?.url || error, error?.response);
    }
    return Promise.reject(error);
  }
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <RollbarProvider instance={rollbar}>
      <ErrorBoundary>
        <CountryProvider>
          <CookiefirstProvider>
            <App />
          </CookiefirstProvider>
        </CountryProvider>
      </ErrorBoundary>
    </RollbarProvider>
  </Provider>
);
