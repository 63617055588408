import React from "react";
import { FormattedMessage } from "react-intl";
import { Paper, Button } from "@material-ui/core";
import styled from "styled-components";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

const Wrapper = styled(Paper)`
  padding: 10px 15px;
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ActivateTokenInvalid = () => {
  const dispatch = useDispatch();

  const handleClick = (destination) => {
    dispatch(push(destination));
  };

  return (
    <Wrapper elevation={0}>
      <FormattedMessage
        id="eo.activation-token-invalid"
        defaultMessage="Your activation token expired. Please fill form one more time."
      />
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleClick("/login")}
        >
          <FormattedMessage
            id="eo.register-again"
            defaultMessage="Request again"
          />
        </Button>
      </div>
    </Wrapper>
  );
};

export default ActivateTokenInvalid;
