import Auth from './modules/core/Auth';
import * as t from './modules/login/actionTypes';

export const authStateSyncMiddleware = ({ dispatch, getState }) => {
    return next => action => {

        if (
            '@@router/LOCATION_CHANGE' === action.type &&
            Auth.isLoggedIn() !== getState().login.logged
        ) {
            Auth.isLoggedIn()
                ? dispatch({type: t.SYNC_AUTH_USER_LOGIN})
                : dispatch({type: t.SYNC_AUTH_USER_LOGOUT})
        }

        return next(action);
    }
};